import React from 'react';

import Button from '../../Button';
import Modal from '../../Modal';
import ModalActions from '../../ModalActions';
import ModalContent from '../../ModalContent';
import { ModalProps } from '../../Modal/Modal';

interface ExpandeImageModalProps extends ModalProps {
  src: string;
  alt: string;
  className?: string;
}

const ExpandedImageModal: React.FC<ExpandeImageModalProps> = ({
  src,
  alt,
  className,
  onDismiss,
}) => {
  const fullSizeImageSrc = src.replace('/low', '');

  return (
    <Modal>
      <ModalContent>
        <div className="flex w-full h-full justify-center items-center object-contain">
          <img
            src={fullSizeImageSrc}
            alt={alt}
            className={className}
            style={{ maxHeight: '70vh' }}
          />
        </div>
      </ModalContent>

      <ModalActions>
        <Button text="Cancel" onClick={onDismiss} />
      </ModalActions>
    </Modal>
  );
};

export default ExpandedImageModal;
