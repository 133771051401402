import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const PinkPill = styled.div`
  background-color: ${({ theme }) => theme.color.pink[400]};
  color: ${({ theme }) => theme.color.white};
  border-radius: 15px;
  border: 1px solid ${({ theme }) => theme.color.black};
`;

const PurplePill = styled.div`
  background-color: ${({ theme }) => theme.color.purple[400]};
  color: ${({ theme }) => theme.color.white};
  border-radius: 15px;
  border: 1px solid ${({ theme }) => theme.color.black};
`;

const BlackPill = styled.div`
  background-color: ${({ theme }) => theme.color.black};
  color: ${({ theme }) => theme.color.white};
  border-radius: 15px;
  border: 1px solid ${({ theme }) => theme.color.black};
`;

const WhitePill = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.black};
  border-radius: 15px;
  border: 1px solid ${({ theme }) => theme.color.black};
`;

const NavigationPills: React.FC = () => {
  const history = useHistory();

  return (
    <div className="container mx-auto flex flex-row flex-wrap md:flex-no-wrap justify-center md:justify-end w-full px-8 md:px-16 lg:px-32 -mt-4">
      <div
        className="flex flex-row justify-center cursor-pointer hover:opacity-75 w-full lg:w-auto mr-0 mb-3 md:mb-0 md:mr-3"
        onClick={() => history.push('/claim')}
      >
        <WhitePill className="py-1 pl-6 pr-10 text-sm align-center whitespace-no-wrap w-full">
          Claim NFT
        </WhitePill>
      </div>

      <div
        className="flex flex-row justify-center cursor-pointer hover:opacity-75 w-full lg:w-auto mr-0 mb-3 md:mb-0 md:mr-3"
        onClick={() => history.push('/varess/powered-by-anons')}
      >
        <BlackPill className="py-1 pl-6 pr-10 text-sm whitespace-no-wrap w-full">
          season 1
        </BlackPill>

        <PinkPill className="py-1 px-6 -ml-6 cursive text-sm whitespace-no-wrap w-full">
          powered by anons
        </PinkPill>
      </div>

      <div
        className="flex flex-row justify-center cursor-pointer hover:opacity-75 w-full lg:w-auto mr-0 mb-3 md:mb-0 md:mr-3"
        onClick={() => history.push('/varess/noombase-the-game')}
      >
        <BlackPill className="py-1 pl-6 pr-10 text-sm whitespace-no-wrap w-full">
          season 2.9.9
        </BlackPill>

        <WhitePill className="py-1 px-6 -ml-6 cursive text-sm whitespace-no-wrap w-full">
          noombase - the game
        </WhitePill>
      </div>

      <div
        className="flex flex-row justify-center cursor-pointer hover:opacity-75 w-full lg:w-auto"
        onClick={() => history.push('/varess/fear-of-the-unknown')}
      >
        <BlackPill className="py-1 pl-6 pr-10 text-sm whitespace-no-wrap w-full">
          set 1
        </BlackPill>

        <PurplePill className="py-1 px-6 -ml-6 cursive text-sm whitespace-no-wrap w-full">
          fear of the unknown
        </PurplePill>
      </div>
    </div>
  );
};

export default NavigationPills;
