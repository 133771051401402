import React from 'react';
import styled from 'styled-components';

const BackgroundImage = styled.div`
  height: calc(100vh + 20px);
  width: 100vw;
  position: fixed;
  top: 0px;
  z-index: -1;

  background: url(/images/bg_mobile.jpg) center;
  background-size: auto 100%;

  @media (min-width: 1024px) {
    background: url(/images/1280fixed.jpg) center;
    background-size: auto 100%;
  }

  @media (min-width: 1280px) {
    background: url(/images/1920fixed.jpg) center;
    background-size: auto 100%;
  }
`;

const BackgroundCover = () => {
  return <BackgroundImage />;
};

export default BackgroundCover;
