import React, { createContext, useCallback, useEffect, useState } from 'react';
import { useWallet } from 'use-wallet';
import { useInterval } from 'hooks/useInterval';
import { Rope } from 'rope';
import {
  getBonusHopeEarned,
  getCardRedeemerContract,
  getHopeBalance,
  getHopeV2Balance,
  getHopeBoosterMultiplier,
  getHopesPerDay,
  getJumpRopeBlocksLeft,
  getJumpRopeHasCollected,
  getJumpRopeOwnershipPercent,
  getJumpRopeTotalHope,
  getJumpRopeTotalRope,
  getMaxStakeNFT,
  getPendingHope,
  getRopeBalance,
  getRopeEarned,
  getRopePrice,
  getRopeStakedNFT,
  // getRopeYieldAvailable,
  // getRopeYieldAvailableInPool,
  getSeasonalPoolAllowance,
  getSeasonalPoolTokenBalance,
  // getSpindleTotalValueLocked,
  getTotalHopeEarned,
  getTotalHopePerSecond,
  getTotalPendingHope,
  // getTotalRopeYieldEarned,
  getTotalValueLocked,
  // getUniLpApy,
  // getUniPrice,
  getYieldPoolAllowance,
  getYieldPoolStaked,
  getYieldPoolTokenBalance,
} from 'rope/utils';

export const NFT_POOL_ID_LIST = [0, 1, 4, 5];
export const YIELD_POOL_ID_LIST = [0, 1];

export interface RopeContext {
  rope?: typeof Rope;
  account?: any;
  activeAccount?: any;
  checkedAccount: boolean;
  checkedConstants: boolean;
  poolId: number;
  poolType: 'nft' | 'yield' | null;
  ropePrice: string;
  totalValueLocked: string;
  // totalValueLockedSpindle: string;
  totalHopeSupply: string;
  // totalRopeYieldEarned: string;
  unclaimedHopeInPool: string;
  unclaimedHopeInAllPools: string;
  maxStakeInPool: string;
  hopesPerDayInPool: string;
  hopeBalance: string;
  hopeV2Balance: string;
  redeemedCardId: number;
  redeemedPackId: number;
  ethBalance: string;
  ropeBalance: string;
  ropeEarned: string;
  bonusHopeEarned: string;
  ropeYieldAvailable: string;
  ropeYieldInPool: string;
  hopeBoosterMultiplier: string;
  allowance: string;
  balance: string;
  staked: string;
  ethDaiLpApy: string;
  ethUsdcLpApy: string;
  ethUsdtLpApy: string;
  ethWbtcLpApy: string;
  jumpRopeOwnershipPercent: string;
  jumpRopeTotalHope: string;
  jumpRopeTotalRope: string;
  jumpRopeBlocksLeft: number;
  jumpRopeHasCollected: boolean;
  setPool?: (poolId: number, poolType: 'nft' | 'yield' | null) => void;
  setRedeemedCardId?: (packId: number, cardId: number) => void;
}

export const Context = createContext<RopeContext>({
  rope: undefined,
  account: undefined,
  activeAccount: undefined,
  checkedAccount: false,
  checkedConstants: false,
  poolId: 0,
  poolType: null,
  ropePrice: 'TOO_HIGH',
  totalValueLocked: '0',
  // totalValueLockedSpindle: '0',
  totalHopeSupply: '0',
  // totalRopeYieldEarned: '0',
  unclaimedHopeInPool: '0',
  unclaimedHopeInAllPools: '0',
  maxStakeInPool: '0',
  hopesPerDayInPool: '0',
  hopeBalance: '0',
  hopeV2Balance: '0',
  redeemedCardId: -1,
  redeemedPackId: -1,
  ethBalance: '0',
  ropeBalance: '0',
  ropeEarned: '0',
  bonusHopeEarned: '0',
  ropeYieldAvailable: '0',
  ropeYieldInPool: '0',
  hopeBoosterMultiplier: '0',
  balance: '0',
  staked: '0',
  allowance: '0',
  ethDaiLpApy: '0',
  ethUsdcLpApy: '0',
  ethUsdtLpApy: '0',
  ethWbtcLpApy: '0',
  jumpRopeOwnershipPercent: '0',
  jumpRopeTotalHope: '0',
  jumpRopeTotalRope: '0',
  jumpRopeBlocksLeft: -1,
  jumpRopeHasCollected: false,
  setPool: () => {},
});

declare global {
  interface Window {
    ropesauce: any;
  }
}

const RopeProvider: React.FC = ({ children }) => {
  const { ethereum }: { ethereum: any } = useWallet();
  const [rope, setRope] = useState<any>();
  const [state, setState] = useState<RopeContext>({
    activeAccount: undefined,
    checkedAccount: false,
    checkedConstants: false,
    poolId: 0,
    poolType: null,
    ropePrice: 'TOO_HIGH',
    totalValueLocked: '0',
    // totalValueLockedSpindle: '0',
    totalHopeSupply: '0',
    // totalRopeYieldEarned: '0',
    unclaimedHopeInPool: '0',
    unclaimedHopeInAllPools: '0',
    maxStakeInPool: '0',
    hopesPerDayInPool: '0',
    hopeBalance: '0',
    hopeV2Balance: '0',
    redeemedCardId: -1,
    redeemedPackId: -1,
    ethBalance: '0',
    ropeBalance: '0',
    ropeEarned: '0',
    bonusHopeEarned: '0',
    ropeYieldAvailable: '0',
    ropeYieldInPool: '0',
    hopeBoosterMultiplier: '0',
    balance: '0',
    staked: '0',
    allowance: '0',
    ethDaiLpApy: '0',
    ethUsdcLpApy: '0',
    ethUsdtLpApy: '0',
    ethWbtcLpApy: '0',
    jumpRopeOwnershipPercent: '0',
    jumpRopeTotalHope: '0',
    jumpRopeTotalRope: '0',
    jumpRopeBlocksLeft: -1,
    jumpRopeHasCollected: false,
  });

  const {
    activeAccount,
    checkedAccount,
    checkedConstants,
    poolId,
    poolType,
    ropePrice,
    totalValueLocked,
    // totalValueLockedSpindle,
    totalHopeSupply,
    // totalRopeYieldEarned,
    unclaimedHopeInPool,
    unclaimedHopeInAllPools,
    maxStakeInPool,
    hopesPerDayInPool,
    hopeBalance,
    hopeV2Balance,
    ropeBalance,
    ropeEarned,
    bonusHopeEarned,
    redeemedCardId,
    redeemedPackId,
    ropeYieldAvailable,
    ropeYieldInPool,
    hopeBoosterMultiplier,
    balance,
    staked,
    allowance,
    ethDaiLpApy,
    ethUsdcLpApy,
    ethUsdtLpApy,
    ethWbtcLpApy,
    jumpRopeOwnershipPercent,
    jumpRopeTotalHope,
    jumpRopeTotalRope,
    jumpRopeBlocksLeft,
    jumpRopeHasCollected,
  } = state;

  const { account, balance: ethBalance, connect } = useWallet();

  // Refresh every 500ms
  const refreshRate = 500;

  // @ts-ignore
  window.rope = rope;
  // @ts-ignore
  window.eth = ethereum;

  useEffect(() => {
    connect('injected');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (ethereum) {
      const ropeLib = new Rope(ethereum, Number(ethereum.chainId), false, {
        accounts: [],
        defaultAccount: ethereum.selectedAddress,
        defaultConfirmations: 1,
        autoGasMultiplier: 1.5,
        testing: false,
        defaultGas: '6000000',
        defaultGasPrice: '70',
        ethereumNodeTimeout: 10000,
      });

      setRope(ropeLib);

      window.ropesauce = ropeLib;
    }
  }, [ethereum]);

  const incrementHopeAndCheckAccount = useCallback(async () => {
    if (rope) {
      // Make the real time update than normal, to avoid decrementing count on new block
      const hopePerSecond =
        (parseFloat(staked) / (24 * 3600)) * Number(hopesPerDayInPool) * 0.8;

      setState((s: any) => ({
        ...s,
        unclaimedHopeInPool: String(
          Number(s.unclaimedHopeInPool) + hopePerSecond * (refreshRate / 1000)
        ),
      }));
    }
  }, [rope, hopesPerDayInPool, staked]);

  useInterval(incrementHopeAndCheckAccount, refreshRate);

  useEffect(() => {
    let subscribed = true;

    if (!rope) return;

    const cardRedeemer = getCardRedeemerContract(rope);
    const redeemEvent = cardRedeemer.events.Redeemed().on('data', (r: any) => {
      const _redeemedCardId = r.returnValues._cardId;
      const _redeemedPackId = r.returnValues._packId;
      const _user = r.returnValues._user;

      if (!subscribed || _user !== account) return;

      setRedeemedCardId(_redeemedPackId, _redeemedCardId);
    });

    return () => {
      subscribed = false;
      redeemEvent.unsubscribe();
    };
  }, [rope, account]);

  const setRedeemedCardId = (packId: number, cardId: number) => {
    setState((s) => ({
      ...s,
      redeemedCardId: Number(cardId),
      redeemedPackId: Number(packId),
    }));
  };

  useEffect(() => {
    let subscribed = true;

    if (rope) {
      (async () => {
        const accounts = await rope.web3.eth.getAccounts();

        if (!subscribed) return;

        setState((s: any) => ({
          ...s,
          activeAccount: accounts[0],
          checkedAccount: checkedAccount && activeAccount === accounts[0],
        }));
      })();
    }

    return () => {
      subscribed = false;
    };
  }, [setState, rope, activeAccount, checkedAccount]);

  useEffect(() => {
    let subscribed = true;
    const subscriptions: any[] = [];

    const onNewBlock = () => {
      if (!subscribed) return;

      setState((s) => ({
        ...s,
        checkedAccount: false,
      }));
    };

    if (rope && subscribed) {
      subscriptions.push(
        rope.web3.eth
          .subscribe('newBlockHeaders', onNewBlock)
          .on('message', onNewBlock)
      );
    }

    if (rope && subscribed && !checkedConstants) {
      (async () => {
        let hopesPerDayInPool = 0;
        let maxStakeInPool = '0';
        // let ethDaiLpApy = '0';
        // let ethUsdcLpApy = '0';
        // let ethUsdtLpApy = '0';
        // let ethWbtcLpApy = '0';

        if (poolType === 'nft') {
          hopesPerDayInPool = await getHopesPerDay(rope, poolId);
          maxStakeInPool = await getMaxStakeNFT(rope, poolId);
        } else if (poolType === 'yield') {
          // const uniPrice = await getUniPrice();
          // ethDaiLpApy = String(await getUniLpApy(0, uniPrice));
          // ethUsdcLpApy = String(await getUniLpApy(1, uniPrice));
          // ethUsdtLpApy = String(await getUniLpApy(2, uniPrice));
          // ethWbtcLpApy = String(await getUniLpApy(3, uniPrice));
        }

        setState((s) => ({
          ...s,
          // ethDaiLpApy,
          // ethUsdcLpApy,
          // ethUsdtLpApy,
          // ethWbtcLpApy,
          checkedConstants: true,
          maxStakeInPool: String(maxStakeInPool),
          hopesPerDayInPool: String(hopesPerDayInPool),
        }));
      })();
    }

    if (rope && account && !checkedAccount && subscribed) {
      (async () => {
        const ropePrice: number = await getRopePrice();

        let hopeSupply: string,
          hopeBalance: string,
          hopeV2Balance: string,
          totalValueLocked: string,
          // totalValueLockedSpindle: string,
          totalHopePerSecond: string,
          // totalRopeYieldEarned: string,
          unclaimedHopeInPool: string,
          unclaimedHopeInAllPools: string,
          ropeBalance: string,
          ropeEarned: string,
          bonusHopeEarned: string,
          ropeYieldAvailable: string,
          ropeYieldInPool: string,
          hopeBoosterMultiplier: string,
          jumpRopeOwnershipPercent: string,
          jumpRopeTotalHope: string,
          jumpRopeTotalRope: string,
          jumpRopeBlocksLeft: number,
          jumpRopeHasCollected: boolean,
          staked: string = '0',
          allowance: string = '0',
          balance: string = '0';

        if (!subscribed) return;

        if (poolType === 'nft') {
          await Promise.all([
            (staked = await getRopeStakedNFT(rope, account, poolId)),
            (allowance = await getSeasonalPoolAllowance(rope, account, poolId)),
            (balance = await getSeasonalPoolTokenBalance(
              rope,
              account,
              poolId
            )),
            (unclaimedHopeInPool = await getPendingHope(rope, account, poolId)),
          ]);
        } else if (poolType === 'yield') {
          await Promise.all([
            (staked = await getYieldPoolStaked(rope, account, poolId)),
            (allowance = await getYieldPoolAllowance(rope, account, poolId)),
            (balance = await getYieldPoolTokenBalance(rope, account, poolId)),
            // (ropeYieldInPool = await getRopeYieldAvailableInPool(
            //   rope,
            //   account,
            //   poolId
            // )),
          ]);
        }

        if (!subscribed) return;

        setState((s) => ({
          ...s,
          ropePrice: ropePrice.toFixed(2),
          allowance: allowance,
          staked: staked,
          balance: balance,
          unclaimedHopeInPool: unclaimedHopeInPool,
          ropeYieldInPool: ropeYieldInPool,
        }));

        await Promise.all([
          (hopeBalance = await getHopeBalance(rope, account)),
          (hopeV2Balance = await getHopeV2Balance(rope, account)),
          (ropeBalance = await getRopeBalance(rope, account)),
          (unclaimedHopeInAllPools = await getTotalPendingHope(rope, account)),
        ]);

        if (!subscribed) return;

        setState((s) => ({
          ...s,
          ropeBalance,
          hopeBalance,
          hopeV2Balance,
          unclaimedHopeInAllPools,
        }));

        await Promise.all([
          (hopeSupply = await getTotalHopeEarned(rope)),
          (totalValueLocked = String(await getTotalValueLocked(rope))),
          // (totalValueLockedSpindle = String(
          //   await getSpindleTotalValueLocked(rope)
          // )),
          (totalHopePerSecond = String(
            await getTotalHopePerSecond(rope, account, NFT_POOL_ID_LIST)
          )),
          // (totalRopeYieldEarned = await getTotalRopeYieldEarned(rope)),
          (ropeEarned = await getRopeEarned(rope, account)),
          (bonusHopeEarned = await getBonusHopeEarned(rope, account)),
          // (ropeYieldAvailable = await getRopeYieldAvailable(
          //   rope,
          //   account,
          //   YIELD_POOL_ID_LIST
          // )),
          (hopeBoosterMultiplier = await getHopeBoosterMultiplier(
            rope,
            account
          )),
          (jumpRopeOwnershipPercent = await getJumpRopeOwnershipPercent(
            rope,
            account
          )),
          (jumpRopeTotalHope = await getJumpRopeTotalHope(rope)),
          (jumpRopeTotalRope = await getJumpRopeTotalRope(rope)),
          (jumpRopeBlocksLeft = await getJumpRopeBlocksLeft(rope)),
          (jumpRopeHasCollected = await getJumpRopeHasCollected(rope, account)),
        ]);

        if (!subscribed) return;

        setState((s) => ({
          ...s,
          checkedAccount: true,
          totalValueLocked,
          // totalValueLockedSpindle,
          totalHopeSupply: hopeSupply,
          // totalRopeYieldEarned: totalRopeYieldEarned,
          hopePerSecond: totalHopePerSecond,
          ropeEarned: ropeEarned,
          bonusHopeEarned: bonusHopeEarned,
          ropeYieldAvailable: ropeYieldAvailable,
          hopeBoosterMultiplier: (
            1 +
            Number(hopeBoosterMultiplier) / 1e5
          ).toString(),
          jumpRopeOwnershipPercent: String(
            Number(jumpRopeOwnershipPercent) * 100
          ),
          jumpRopeTotalHope: jumpRopeTotalHope,
          jumpRopeTotalRope: jumpRopeTotalRope,
          jumpRopeBlocksLeft: jumpRopeBlocksLeft,
          jumpRopeHasCollected: jumpRopeHasCollected,
        }));
      })();
    }

    return () => {
      subscribed = false;
      subscriptions.forEach((subscription: any) => subscription.unsubscribe());
    };
  }, [
    rope,
    ropePrice,
    account,
    poolId,
    poolType,
    checkedAccount,
    checkedConstants,
  ]);

  const setPool = (poolId: number, poolType: 'nft' | 'yield' | null = null) => {
    setState((s) => ({
      ...s,
      poolId,
      poolType,
      staked: '0',
      allowance: '0',
      balance: '0',
      unclaimedHopeInPool: '0',
      ropeYieldInPool: '0',
      checkedConstants: false,
      checkedAccount: false,
    }));
  };

  return (
    <Context.Provider
      value={{
        rope,
        account,
        checkedAccount,
        checkedConstants,
        poolId,
        poolType,
        ropePrice,
        totalValueLocked,
        // totalValueLockedSpindle,
        totalHopeSupply,
        // totalRopeYieldEarned,
        unclaimedHopeInPool,
        unclaimedHopeInAllPools,
        maxStakeInPool,
        hopesPerDayInPool,
        hopeBalance,
        hopeV2Balance,
        ethBalance,
        ropeBalance,
        ropeEarned,
        bonusHopeEarned,
        redeemedCardId,
        redeemedPackId,
        ropeYieldAvailable,
        ropeYieldInPool,
        hopeBoosterMultiplier,
        allowance,
        balance,
        staked,
        ethDaiLpApy,
        ethUsdcLpApy,
        ethUsdtLpApy,
        ethWbtcLpApy,
        jumpRopeOwnershipPercent,
        jumpRopeTotalHope,
        jumpRopeTotalRope,
        jumpRopeBlocksLeft,
        jumpRopeHasCollected,
        setPool,
        setRedeemedCardId,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default RopeProvider;
