import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { UseWalletProvider } from 'use-wallet';

import 'other.css';
import theme from 'theme';

import NftCardProvider from 'contexts/NftCardProvider';
import RopeProvider from 'contexts/RopeProvider';
import ModalsProvider from 'contexts/Modals';

import FarmSelection from 'pages/FarmSelection';
import VaressSeasonalPools from 'pages/VaressSeasonalPools';
import SpindleFarms from 'pages/SpindleFarms';
import SpindlePool from 'pages/SpindlePool';
import SeasonOnePools from 'pages/SeasonOnePools';
import SeasonTwoPools from 'pages/SeasonTwoPools';
import ClaimNFTs from 'pages/ClaimNFTs';
import CharitySale from 'pages/CharitySale';
import JumpRope from 'pages/JumpRope';
import DeckPage from 'pages/DeckPage';
import KawaiiLovecraftSet from 'pages/KawaiiLovecraftSet';
import LovecraftRaffle from 'pages/LovecraftRaffle';
import { DontBuyPage } from 'pages/DontBuyPage';

const App = () => {
  return (
    <Providers>
      <Router>
        <ModalsProvider>
          <Switch>
            <Route exact path="/charity">
              <CharitySale />
            </Route>

            <Route exact path="/spindles">
              <SpindleFarms />
            </Route>

            <Route path="/spindles/uni-v2">
              <SpindlePool />
            </Route>

            <Route exact path="/varess">
              <VaressSeasonalPools />
            </Route>

            <Route path="/varess/powered-by-anons">
              <SeasonOnePools />
            </Route>

            <Route path="/varess/noombase-the-game">
              <SeasonTwoPools />
            </Route>

            <Route path="/varess/fear-of-the-unknown">
              <KawaiiLovecraftSet />
            </Route>

            <Route path="/varess/raffles/fear-of-the-unknown">
              <LovecraftRaffle />
            </Route>

            <Route path="/jump-rope">
              <JumpRope />
            </Route>

            <Route path="/claim">
              <ClaimNFTs />
            </Route>

            <Route path="/deck">
              <DeckPage />
            </Route>

            <Route path="/dontbuy">
              <DontBuyPage />
            </Route>

            <Route path="/">
              <FarmSelection />
            </Route>
          </Switch>
        </ModalsProvider>
      </Router>
    </Providers>
  );
};

const Providers: React.FC = ({ children }) => {
  const _window: any = window;
  const chainId = _window?.ethereum?.chainId ?? 1;

  return (
    <ThemeProvider theme={theme}>
      <UseWalletProvider
        chainId={Number(chainId)}
        connectors={{
          fortmatic: { apiKey: 'pk_live_D450110846B7A2A4' },
          walletconnect: { rpcUrl: 'https://mainnet.eth.aragon.network/' },
        }}
      >
        <RopeProvider>
          <NftCardProvider>{children}</NftCardProvider>
        </RopeProvider>
      </UseWalletProvider>
    </ThemeProvider>
  );
};

export default App;
