import React, { useState } from 'react';
import web3 from 'web3';

import { withdrawRopeYieldPool } from 'rope/utils';
import useRope from 'hooks/useRope';

import Button from 'components/Button';
import Modal from 'components/Modal';
import ModalContent from 'components/ModalContent';
import Spacer from 'components/Spacer';

const UnstakeModal: React.FC<any> = ({ pairTitle, pairImage, onDismiss }) => {
  const { rope, account, staked, poolId } = useRope();
  const [unstakeAmount, setUnstakeAmount] = useState('0');

  const onChangeUnstakeAmount = (event: any) => {
    const amount = String(event.target.value).replace(/[^\d.-]/g, '');

    setUnstakeAmount(amount);
  };

  const unstake = async (amount: string) =>
    withdrawRopeYieldPool(
      rope,
      account,
      web3.utils.toWei(
        (Number(amount) >= Number(staked) ? staked : amount).toString(),
        'ether'
      ),
      poolId
    );

  return (
    <Modal>
      <ModalContent>
        <div className="flex flex-col w-full justify-center items-center">
          <div className="flex flex-row items-center mb-4">
            <img
              width="25"
              height="25"
              src="/images/eth-logo.svg"
              alt="rope_asset"
            />

            {pairImage}
          </div>

          <div className="text-md font-semibold">
            Please enter an amount of {pairTitle} to unstake.
          </div>

          <div className="text-md italic font-thin text-center">
            *If the unstake button does not work, try removing a decimal or two
            from the amount.
          </div>

          <Spacer />

          <div className="relative overflow-x-scroll">
            <span
              className="absolute top-0 left-0 mt-2 ml-2 text-sm font-semibold cursor-pointer text-black"
              onClick={() =>
                onChangeUnstakeAmount({ target: { value: staked } })
              }
            >
              max
            </span>

            <input
              type="number"
              value={unstakeAmount}
              className="text-right border border-t-0 border-r-0 border-l-0 border-b-1 bg-transparent border-blalck border-solid xl:mr-2 pl-10 pr-2 py-1 font-semibold"
              onChange={onChangeUnstakeAmount}
            />
          </div>
        </div>
      </ModalContent>

      <div className="flex flex-row w-full">
        <Button variant="secondary" text="Cancel" onClick={onDismiss} />

        <Button text="Unstake" onClick={() => unstake(unstakeAmount)} />
      </div>
    </Modal>
  );
};

export default UnstakeModal;
