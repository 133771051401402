import React, { useState } from 'react';
import web3 from 'web3';

import { stakeRopeYieldPool } from 'rope/utils';
import useRope from 'hooks/useRope';

import Button from 'components/Button';
import Modal from 'components/Modal';
import ModalContent from 'components/ModalContent';
import Spacer from 'components/Spacer';

const StakeModal: React.FC<any> = ({ pairTitle, pairImage, onDismiss }) => {
  const { rope, account, balance, poolId } = useRope();
  const [stakeMoreAmount, setStakeMoreAmount] = useState('0');

  const onChangeStakeMoreAmount = (event: any) =>
    setStakeMoreAmount(String(event.target.value).replace(/[^\d.-]/g, ''));

  const stakeMore = async (amount: string) =>
    stakeRopeYieldPool(
      rope,
      account,
      web3.utils.toWei(
        (Number(amount) >= Number(balance) ? balance : amount).toString(),
        'ether'
      ),
      poolId
    ).then(onDismiss);

  return (
    <Modal>
      <ModalContent>
        <div className="flex flex-col w-full justify-center items-center px-12">
          <div className="flex flex-row items-center mb-4">
            <img
              width="25"
              height="25"
              src="/images/eth-logo.svg"
              alt="rope_asset"
            />

            {pairImage}
          </div>

          <div className="text-md font-semibold">
            Please enter an amount of {pairTitle} to stake.
          </div>

          <div className="text-md italic font-thin text-center">
            *If the stake button does not work, try removing a decimal or two
            from the amount.
          </div>

          <Spacer />

          <div className="relative overflow-x-scroll">
            <span
              className="absolute top-0 left-0 mt-2 ml-2 text-sm font-semibold cursor-pointer text-black"
              onClick={() =>
                onChangeStakeMoreAmount({ target: { value: balance } })
              }
            >
              max
            </span>

            <input
              type="number"
              value={stakeMoreAmount}
              className="text-right border border-t-0 border-r-0 border-l-0 border-b-1 bg-transparent border-black border-solid xl:mr-2 pl-10 pr-2 py-1 font-semibold"
              onChange={onChangeStakeMoreAmount}
            />
          </div>
        </div>
      </ModalContent>

      <div className="flex flex-row w-full">
        <Button variant="secondary" text="Cancel" onClick={onDismiss} />

        <Button text="Stake" onClick={() => stakeMore(stakeMoreAmount)} />
      </div>
    </Modal>
  );
};

export default StakeModal;
