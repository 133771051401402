import React, { createContext, useEffect, useState } from 'react';

import useRope from 'hooks/useRope';

import {
  getCardData,
  getCardSetIdForCard,
  getCardSetData,
  getTotalCardsStaked,
  getTotalPendingHopeNFTStaking,
  getCardStakingApproval,
  getPackInfo,
  getPacksLeft,
  getPackProbabilities,
  getCardIdListOfPack,
  getCardsLeftInPack,
} from 'rope/utils';
import {getCardSetSize} from "../../cardSet";

export interface NftCardContext {
  activeAccount?: any;
  checkedAccount: boolean;
  checkedCardPacks: { [packId: number]: boolean };
  checkedCards: { [cardId: number]: boolean };
  checkedCardSets: { [cardSetId: number]: boolean };
  isStakingApproved: boolean;
  totalNftsStaked: string;
  unclaimedHope: string;
  cards: { [cardId: number]: any };
  cardSets: { [cardSetId: number]: any };
  cardPacks: { [packId: number]: any };
  fetchCard: (cardId: number) => any;
  fetchCardSet: (cardSetId: number) => any;
  fetchCardPack: (cardPackId: number) => any;
}

export const Context = createContext<NftCardContext>({
  activeAccount: undefined,
  checkedAccount: false,
  checkedCardPacks: {},
  checkedCards: {},
  checkedCardSets: {},
  isStakingApproved: false,
  totalNftsStaked: '0',
  unclaimedHope: '0',
  cards: {},
  cardSets: {},
  cardPacks: {},
  fetchCard: () => {},
  fetchCardSet: () => {},
  fetchCardPack: () => {},
});

const NftCardProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<NftCardContext>({
    activeAccount: undefined,
    checkedAccount: false,
    checkedCardPacks: {},
    checkedCards: {},
    checkedCardSets: {},
    isStakingApproved: false,
    totalNftsStaked: '0',
    unclaimedHope: '0',
    cards: {},
    cardSets: {},
    cardPacks: {},
    fetchCard: () => {},
    fetchCardSet: () => {},
    fetchCardPack: () => {},
  });

  const {
    activeAccount,
    checkedAccount,
    checkedCardPacks,
    checkedCards,
    checkedCardSets,
    isStakingApproved,
    totalNftsStaked,
    unclaimedHope,
    cards,
    cardSets,
    cardPacks,
  } = state;

  const { rope, account } = useRope();

  const getSetSize = (cardSetId: number) => {
    return getCardSetSize(cardSetId);
  };

  const fetchCardSet = async (cardSetId: number) => {
    if (!rope || checkedCardSets[cardSetId]) {
      return;
    }

    setState(({ checkedCardSets, ...state }) => ({
      ...state,
      checkedCardSets: {
        ...checkedCardSets,
        [cardSetId]: true,
      },
    }));

    const cardSet = await getCardSetData(rope, account, cardSetId);

    const setSize = getSetSize(cardSetId);

    setState(({ cardSets, ...state }) => ({
      ...state,
      cardSets: {
        ...cardSets,
        [cardSetId]: { ...cardSet, setSize },
      },
    }));
  };

  const fetchCard = async (cardId: number) => {
    if (!rope || checkedCards[cardId]) {
      return;
    }

    setState(({ checkedCards, ...state }) => ({
      ...state,
      checkedCards: {
        ...checkedCards,
        [cardId]: true,
      },
    }));

    const card = await getCardData(rope, account, cardId);
    const cardSetId = await getCardSetIdForCard(rope, cardId);

    setState(({ cards, ...state }) => ({
      ...state,
      cards: {
        ...cards,
        [cardId]: { ...card, cardSetId },
      },
    }));

    fetchCardSet(cardSetId);
  };

  const fetchCardPack = async (cardPackId: number) => {
    if (!rope || checkedCardPacks[cardPackId]) return;

    setState((s) => ({
      ...s,
      checkedCardPacks: { ...s.checkedCardPacks, [cardPackId]: true },
    }));

    const pack = await getPackInfo(rope, cardPackId);
    const packsLeft = await getPacksLeft(rope, cardPackId);
    const remainingCardSupply = await getCardsLeftInPack(rope, cardPackId);
    const probabilities = await getPackProbabilities(rope, cardPackId);
    const cardIdList = await getCardIdListOfPack(rope, cardPackId);

    setState((s) => ({
      ...s,
      cardPacks: {
        ...s.cardPacks,
        [cardPackId]: {
          ...pack,
          packsLeft,
          probabilities,
          remainingCardSupply: remainingCardSupply.map((el: any) => Number(el)),
          cardIdList: cardIdList.map((el: any) => Number(el)),
        },
      },
    }));
  };

  useEffect(() => {
    let subscribed = true;

    if (rope) {
      (async () => {
        const accounts = await (rope as any).web3.eth.getAccounts();

        if (!subscribed) return;

        setState((s: any) => ({
          ...s,
          activeAccount: accounts[0],
          checkedAccount: checkedAccount && activeAccount === accounts[0],
          checkedCards: activeAccount === accounts[0] ? checkedCards : {},
        }));
      })();
    }

    return () => {
      subscribed = false;
    };
  }, [rope, activeAccount, checkedAccount, checkedCards]);

  useEffect(() => {
    let subscribed = true;
    const subscriptions: any[] = [];

    const onNewBlock = () => {
      if (!subscribed) return;

      setState((s) => ({
        ...s,
        checkedAccount: false,
        checkedCards: {},
        checkedCardPacks: {},
      }));
    };

    if (rope && subscribed) {
      subscriptions.push(
        (rope as any).web3.eth
          .subscribe('newBlockHeaders', onNewBlock)
          .on('message', onNewBlock)
      );
    }

    if (rope && account && !checkedAccount && subscribed) {
      (async () => {
        const totalNftsStaked = await getTotalCardsStaked(rope, account);
        const unclaimedHope = await getTotalPendingHopeNFTStaking(
          rope,
          account
        );
        const isStakingApproved = await getCardStakingApproval(rope, account);

        setState((s) => ({
          ...s,
          checkedAccount: true,
          isStakingApproved,
          totalNftsStaked,
          unclaimedHope,
        }));
      })();
    }

    return () => {
      subscribed = false;
      subscriptions.forEach((subscription: any) => subscription.unsubscribe());
    };
  }, [rope, account, checkedAccount, checkedCardPacks]);

  return (
    <Context.Provider
      value={{
        checkedAccount,
        checkedCards,
        checkedCardSets,
        checkedCardPacks,
        isStakingApproved,
        totalNftsStaked,
        unclaimedHope,
        cards,
        cardSets,
        cardPacks,
        fetchCard,
        fetchCardSet,
        fetchCardPack,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default NftCardProvider;
