import React from 'react';
import { Link } from 'react-router-dom';

const AnnouncementBanner = () => {
  return (
    <Link
      to="/varess/fear-of-the-unknown"
      className="flex flex-row justify-center items-center w-full h-12 sm:h-8 p-4 text-center bg-black text-white text-sm mb-4 sm:mb-2"
    >
      Set 1 - Fear of The Unknown is now live!
      <span className="mx-2 underline font-semibold">View Set 1</span>
      <img
        src="/images/fire_low.gif"
        alt="fire"
        height={16}
        width={16}
        className="ml-1"
      />
    </Link>
  );
};

export default AnnouncementBanner;
