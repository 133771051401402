import React, { useEffect, useCallback } from 'react';
import styled from 'styled-components';
import web3 from 'web3';

import useModal from 'hooks/useModal';
import useRope from 'hooks/useRope';

import { redeemPack } from 'rope/utils';

import Button from 'components/Button';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import RevealModal from './components/RevealModal';
import WhatsInsideModal from './components/WhatsInsideModal';

import { pink, purple, blue, black } from 'theme/colors';
import useNftCards from '../../hooks/useNftCards';

const Badge = (color: string) => styled.div`
  border-radius: 10px;
  padding: 3px 1rem 5px;
  min-width: 90px;
  text-align: center;
  color: ${({ theme }) => theme.color.white};
  background-color: ${color};
`;

const PurpleBadge = Badge(purple[400]);
const BlueBadge = Badge(blue[400]);
const PinkBadge = Badge(pink[400]);
const BlackBadge = Badge(black);

const PinkText = styled.span`
  color: ${({ theme }) => theme.color.pink[400]};
`;

const NotEnoughHopeButton = styled.div`
  border-radius: 10px;
  text-align: center;
  border: ${({ theme }) => theme.color.grey[200]} 2px dashed;
  color: ${({ theme }) => theme.color.red[400]};
`;

const WhatsInsideButton = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.black};
  border-top: 1px solid ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.white};

  &:hover {
    opacity: 0.8;
  }
`;

interface NftCardPackProps {
  id: number;
  onSelect?: () => void;
}

const NftCardPack: React.FC<NftCardPackProps> = ({ id, onSelect }) => {
  const {
    rope,
    account,
    hopeBalance,
    redeemedCardId,
    redeemedPackId,
    setRedeemedCardId,
  } = useRope();
  const { cardPacks, fetchCardPack } = useNftCards();

  const rarity: any = id === 1 ? 'Uncommon' : 'Rare';
  const packImage = id === 1 ? '/images/ShitPack.png' : '/images/PastaPack.gif';
  const packName = id === 1 ? 'Shit Pack' : 'Pasta Pack';

  const hopeRequired = cardPacks[id]
    ? web3.utils.fromWei(cardPacks[id].price)
    : Infinity;
  let totalSupply = cardPacks[id]
    ? Number(cardPacks[id].packsLeft) + Number(cardPacks[id].redeemed)
    : 0;

  if (id === 1) {
    totalSupply += 254;
  } else if (id === 2) {
    totalSupply += 132;
  }

  const remainingSupply = cardPacks[id] ? cardPacks[id].packsLeft : 0;
  const probabilities = cardPacks[id] ? cardPacks[id].probabilities : [];
  const cardsInPack = cardPacks[id] ? cardPacks[id].cardIdList : [];
  const remainingCardSupply = cardPacks[id]
    ? cardPacks[id].remainingCardSupply
    : [];

  const [onShowWhatsInsideModal] = useModal(
    <WhatsInsideModal
      packName={packName}
      probabilities={probabilities}
      cardsInPack={cardsInPack}
      remainingSupply={remainingCardSupply}
    />
  );

  const [onShowRevealModal, onDismissRevealModal] = useModal(
    <RevealModal
      cardId={redeemedCardId}
      packName={packName}
      packImage={packImage}
      probability={
        probabilities[
          cardsInPack.findIndex((_id: number) => _id - redeemedCardId === 0)
        ]
      }
      remainingSupply={
        remainingCardSupply[
          cardsInPack.findIndex((_id: number) => _id - redeemedCardId === 0)
        ]
      }
    />
  );

  const redeemCardPack = useCallback(() => {
    onShowRevealModal();
    redeemPack(rope, account, id);
  }, [rope, account, id, onShowRevealModal]);

  useEffect(() => {
    if (redeemedCardId !== -1 && redeemedPackId === id) {
      onDismissRevealModal();

      requestAnimationFrame(() => {
        onShowRevealModal();
        setRedeemedCardId?.(-1, -1);
      });
    }
  }, [
    redeemedCardId,
    redeemedPackId,
    setRedeemedCardId,
    id,
    onShowRevealModal,
    onDismissRevealModal,
  ]);

  useEffect(() => {
    let subscribed = true;

    if (rope && subscribed && !cardPacks[id]) {
      fetchCardPack(id);
    }

    return () => {
      subscribed = false;
    };
  }, [rope, fetchCardPack, cardPacks, id]);

  let rarityBadge: any;

  switch (rarity) {
    case 'off___rare':
      rarityBadge = (
        <PinkBadge>
          <div className="text-sm font-medium">charity</div>
        </PinkBadge>
      );
      break;
    case 'Rare':
      rarityBadge = (
        <BlueBadge>
          <div className="text-sm font-medium">rare</div>
        </BlueBadge>
      );
      break;
    case 'Uncommon':
      rarityBadge = (
        <PurpleBadge>
          <div className="text-sm font-medium">uncommon</div>
        </PurpleBadge>
      );
      break;
    default:
      rarityBadge = (
        <BlackBadge>
          <div className="text-sm font-medium">loading...</div>
        </BlackBadge>
      );
      break;
  }

  return (
    <Card className="-mt-24">
      <CardContent className="relative" onClick={onSelect}>
        <div className="flex flex-col w-full h-full items-center">
          <Card
            transparent
            className="object-contain"
            onClick={onShowWhatsInsideModal}
          >
            <img src={packImage} alt={`nft-${id}`} />
          </Card>

          <div className="text-base cursive mt-3 whitespace-no-wrap">
            <PinkText>{remainingSupply || 0}</PinkText> / {totalSupply} Left
          </div>

          <div className="text-xl font-semibold mt-3 text-center">
            {packName}
          </div>

          <div className="flex flex-grow-1 flex-row w-full font-semibold justify-between items-center mt-5 px-1">
            <div className="text-sm font-semibold">Season 2.9.9</div>

            {rarityBadge}
          </div>

          {hopeRequired === Infinity ? (
            <div className="w-2/3">
              <NotEnoughHopeButton className="my-5 py-2 px-6 text-sm font-semibold">
                Loading...
              </NotEnoughHopeButton>
            </div>
          ) : (
            <div className="w-2/3">
              {Number(hopeRequired) === -1 ||
              Number(hopeBalance) < Number(hopeRequired) ? (
                <NotEnoughHopeButton className="my-5 py-2 px-6 text-sm font-semibold">
                  You need {hopeRequired} HOPE
                </NotEnoughHopeButton>
              ) : (
                <Button
                  rounded
                  className="my-5"
                  text={`Redeem ${hopeRequired || '∞'} HOPE`}
                  size="sm"
                  variant="pink"
                  onClick={redeemCardPack}
                />
              )}
            </div>
          )}
        </div>
      </CardContent>

      <WhatsInsideButton
        className="flex w-full py-2 px-6 justify-center items-center text-sm text-center"
        onClick={onShowWhatsInsideModal}
      >
        What's Inside <i className="fas fa-info-circle ml-2 text-black" />
      </WhatsInsideButton>
    </Card>
  );
};

export default NftCardPack;
