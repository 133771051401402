import React, { useState } from 'react';
import styled from 'styled-components';

import useRaffle from 'hooks/useRaffle';
import useRope from 'hooks/useRope';

import Button from 'components/Button';
import Modal from 'components/Modal';
import ModalContent from 'components/ModalContent';
import Spacer from 'components/Spacer';

const PinkText = styled.div`
  font-weight: 500;
  color: ${({ theme }) => theme.color.pink[200]};
`;

const TICKET_PRICE = 1000;

const PurchaseTicketModal: React.FC<any> = ({ raffleId, onDismiss }) => {
  const { hopeBalance, hopeV2Balance } = useRope();
  const { purchaseTickets } = useRaffle({ raffleId });
  const [hopeAmount, setHopeAmount] = useState('0');

  const maxTicketBalance =
    Math.max(Number(hopeV2Balance), Number(hopeBalance)) / TICKET_PRICE;

  const onChangeAmount = (event: any) =>
    setHopeAmount(String(event.target.value).replace(/[^\d]/g, ''));

  const purchaseMore = async (amount: string, useNonTradeableHope: boolean) =>
    purchaseTickets(
      (Number(amount) >= Number(maxTicketBalance)
        ? maxTicketBalance
        : amount
      ).toString(),
      useNonTradeableHope
    ).then(onDismiss);

  return (
    <Modal>
      <ModalContent>
        <div className="flex flex-col w-full justify-center items-center px-12">
          <div className="flex flex-row items-center mb-4">
            <img
              width="40"
              height="40"
              src="/images/HopeTokenv3.gif"
              alt="rope_asset"
            />
          </div>

          <div className="text-md font-semibold">
            Please enter a number of tickets to purchase.
          </div>

          <PinkText className="text-md font-semibold">
            Each ticket costs 1,000 HOPE.
          </PinkText>

          <Spacer />

          <div className="relative overflow-x-scroll">
            <span
              className="absolute top-0 left-0 mt-2 ml-2 text-sm font-semibold cursor-pointer text-black"
              onClick={() =>
                onChangeAmount({ target: { value: maxTicketBalance } })
              }
            >
              max
            </span>

            <input
              type="number"
              value={hopeAmount}
              className="text-right border border-t-0 border-r-0 border-l-0 border-b-1 bg-transparent border-black border-solid xl:mr-2 pl-10 pr-2 py-1 font-semibold"
              onChange={onChangeAmount}
            />
          </div>
        </div>
      </ModalContent>

      <div className="flex flex-row w-full">
        <Button variant="secondary" text="Cancel" onClick={onDismiss} />

        <Button
          text="Purchase w/ HOPE"
          onClick={() => purchaseMore(hopeAmount, true)}
        />

        <Button
          variant="pink"
          text="Purchase w/ HOPE v2"
          onClick={() => purchaseMore(hopeAmount, false)}
        />
      </div>
    </Modal>
  );
};

export default PurchaseTicketModal;
