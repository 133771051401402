import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { get } from 'lodash';
import styled from 'styled-components';

import { approveStakeYieldPool, transferAllRopeYield } from 'rope/utils';
import useModal from 'hooks/useModal';
import useRope from 'hooks/useRope';

import Header from 'components/Header';
import Footer from 'components/Footer';
import BackgroundCover from 'components/BackgroundCover';
import Button from 'components/Button';
import Card from 'components/Card';
import PoolCard from 'components/PoolCard';
import CardContent from 'components/CardContent';
import SpindleStatsHeader from 'components/SpindleStatsHeader';
import NavigationPills from 'components/NavigationPills';
import HopeBoosterMultiplier from 'components/HopeBoosterMultiplier';
import StakeModal from './components/StakeModal';
import UnstakeModal from './components/UnstakeModal';

const PinkTag = styled.div`
  padding: 2px 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 5px;
  font-weight: 200;
  display: flex;
  flex: 0;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.pink[400]};
  color: ${({ theme }) => theme.color.white};
`;

const SpindlePool: React.FC = () => {
  const {
    rope,
    account,
    allowance,
    balance,
    staked,
    poolType,
    poolId: _poolId,
    setPool,
  } = useRope();
  const location = useLocation();

  const transferYield = async () => transferAllRopeYield(rope, account);

  const approveToken = async () => approveStakeYieldPool(rope, account, poolId);

  const pairString = get(location.pathname.split('/'), '3', 'eth-dai');

  const ropeYieldInPool = 0;
  let poolId = 0;
  let pairApy = '0';
  let pairTitle = 'ETH / DAI';
  let pairImage = (
    <img
      width="40"
      height="40"
      src="/images/dai-logo.svg"
      alt="rope_asset"
      className="ml-5"
    />
  );

  switch (pairString) {
    case 'eth-usdc':
      poolId = 1;
      pairApy = '0';
      pairTitle = 'ETH / USDC';
      pairImage = (
        <img
          width="50"
          height="50"
          src="/images/usdc-logo.svg"
          alt="rope_asset"
          className="ml-4"
        />
      );
      break;

    case 'eth-usdt':
      poolId = 2;
      pairApy = '0';
      pairTitle = 'ETH / USDT';
      pairImage = (
        <img
          width="40"
          height="40"
          src="/images/usdt-logo.svg"
          alt="rope_asset"
          className="ml-5"
        />
      );
      break;

    case 'eth-wbtc':
      poolId = 3;
      pairApy = '0';
      pairTitle = 'ETH / WBTC';
      pairImage = (
        <img
          width="80"
          height="80"
          src="/images/wbtc-logo.svg"
          alt="rope_asset"
          className="ml-1"
        />
      );
      break;

    default:
      break;
  }

  const [onShowStakeModal] = useModal(
    <StakeModal pairTitle={pairTitle} pairImage={pairImage} />
  );

  const [onShowUnstakeModal] = useModal(
    <UnstakeModal pairTitle={pairTitle} pairImage={pairImage} />
  );

  useEffect(() => {
    if (_poolId !== poolId || poolType !== 'yield') {
      setPool?.(poolId, 'yield');
    }
  }, [_poolId, poolId, poolType, setPool]);

  return (
    <div className="flex flex-col w-screen min-h-screen items-center">
      <BackgroundCover />

      <Header />

      <NavigationPills />

      <SpindleStatsHeader />

      <div className="container mx-auto w-full px-4 sm:px-8 md:px-16 lg:px-32 mb-16">
        <Card flatVariant className="pt-2 px-4 sm:px-8">
          <CardContent>
            <div className="flex flex-col md:flex-row justify-between">
              <div className="flex flex-col">
                <div className="text-sm cursive mb-4">
                  {pairTitle} Spindle - "get your pamp on"
                </div>

                <div className="text-lg font-semibold mb-4">
                  Deposit {pairTitle} - Earn $ROPE + HOPE
                </div>
              </div>

              <div className="flex flex-col items-end">
                <HopeBoosterMultiplier />

                <Link to="/spindles" className="-mt-4 text-sm font-semibold">
                  <i className="fas fa-arrow-left mr-2" />{' '}
                  <span className="underline">change pool</span>
                </Link>
              </div>
            </div>

            <div className="flex flex-col flex-wrap md:flex-row justify-between w-full pt-4">
              <PoolCard variant="black">
                <img
                  width="40"
                  height="40"
                  src="/images/ROPE_icon.png"
                  alt="rope_asset"
                  className="mt-6 mb-6"
                />

                <div className="text-xl font-semibold">
                  {Number(ropeYieldInPool).toLocaleString('en-us', {
                    minimumFractionDigits: 8,
                    maximumFractionDigits: 8,
                  }) !== '0.00000000' && '~'}
                  {Number(ropeYieldInPool).toLocaleString('en-us', {
                    minimumFractionDigits: 8,
                    maximumFractionDigits: 8,
                  })}
                </div>

                <div className="text-base font-thin -mt-2 mb-8">
                  $ROPE Available
                </div>

                <Button
                  disabled={
                    Number(ropeYieldInPool).toLocaleString('en-us', {
                      minimumFractionDigits: 8,
                      maximumFractionDigits: 8,
                    }) === '0.00000000'
                  }
                  text="Transfer All Yield → $ROPE"
                  onClick={transferYield}
                />
              </PoolCard>

              <PoolCard variant="black">
                <div
                  className="flex flex-row items-center mb-1"
                  style={{ height: 80 }}
                >
                  <img
                    width="25"
                    height="25"
                    src="/images/eth-logo.svg"
                    alt="rope_asset"
                  />

                  {pairImage}
                </div>

                <div className="text-xl font-semibold">
                  {Number(staked).toLocaleString('en-us', {
                    minimumFractionDigits: 8,
                    maximumFractionDigits: 8,
                  })}{' '}
                  /{' '}
                  {Number(balance).toLocaleString('en-us', {
                    minimumFractionDigits: 8,
                    maximumFractionDigits: 8,
                  })}
                </div>

                <div className="text-base font-thin mb-4">
                  {pairTitle} Staked
                </div>

                <PinkTag>
                  <b className="mr-1">{Number(pairApy).toFixed(2)} %</b> APY
                </PinkTag>

                {Number(allowance) > 0 ? (
                  <div className="flex flex-row w-full">
                    {Number(staked) > 0 && (
                      <Button
                        variant="black"
                        text="Unstake"
                        onClick={onShowUnstakeModal}
                      />
                    )}

                    <Button
                      disabled={true}
                      text="Stake"
                      onClick={onShowStakeModal}
                    />
                  </div>
                ) : (
                  <Button
                    disabled={true}
                    text={`Approve ${pairTitle}`}
                    onClick={approveToken}
                  />
                )}
              </PoolCard>
            </div>
          </CardContent>

          <Footer />
        </Card>
      </div>
    </div>
  );
};

export default SpindlePool;
