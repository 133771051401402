import React from 'react';

import Button from 'components/Button';
import Modal from 'components/Modal';
import ModalContent from 'components/ModalContent';

const ConfirmClaimModal: React.FC<any> = ({ onDismiss, onConfirm }) => {
  return (
    <Modal>
      <ModalContent>
        <div className="flex flex-col w-full justify-center items-center px-12">
          <div className="font-semibold text-lg text-center mb-8">
            Claim $ROPE
          </div>

          <div className="text-base text-center mb-5">
            Are you sure you would like to claim your $ROPE yield and reset your
            HopeBooster multiplier?
          </div>

          <div className="text-base text-center mb-5">
            This action cannot be undone.
          </div>
        </div>
      </ModalContent>

      <div className="flex flex-row ">
        <Button variant="secondary" text="Cancel" onClick={onDismiss} />
        <Button
          text="Confirm"
          onClick={async () => {
            await onConfirm();

            onDismiss();
          }}
        />
      </div>
    </Modal>
  );
};

export default ConfirmClaimModal;
