import React from 'react';
import styled from 'styled-components';

interface NftPackHeartBeatProps {
  children: any;
  className?: string;
  style?: object;
}

const NftPackHeartBeat: React.FC<NftPackHeartBeatProps> = ({
  children,
  className,
  style,
}) => {
  return (
    <StyledNftPackHeartBeat style={style} className={className}>
      {children}
    </StyledNftPackHeartBeat>
  );
};

const StyledNftPackHeartBeat = styled.div<NftPackHeartBeatProps>`
  background: transparent;
  display: flex;
  flex: 1;
  flex-direction: column;
  cursor: pointer;

  animation: pulse 1.5s infinite;
  animation-timing-function: ease-in-out;
  animation-delay: 7s;
`;

export default NftPackHeartBeat;
