import React from 'react';
import ReactCountdown from 'react-countdown';

import { claimHopeInJumpRope } from 'rope/utils';
import useModal from 'hooks/useModal';
import useRope from 'hooks/useRope';

import Header from 'components/Header';
import Footer from 'components/Footer';
import BackgroundCover from 'components/BackgroundCover';
import Button from 'components/Button';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import ApyStatsCard from 'components/ApyStatsCard';
import VaressStatsHeader from 'components/VaressStatsHeader';
import NavigationPills from 'components/NavigationPills';
import DepositModal from './components/DepositModal';

const JumpRope: React.FC = () => {
  const {
    rope,
    account,
    jumpRopeOwnershipPercent,
    jumpRopeTotalHope,
    jumpRopeTotalRope,
    jumpRopeBlocksLeft,
    jumpRopeHasCollected,
  } = useRope();

  const [onShowDepositModal] = useModal(<DepositModal />);

  const claimHope = async () => claimHopeInJumpRope(rope, account);

  const hopeToRopeRate =
    Number(jumpRopeTotalHope) / (Number(jumpRopeTotalRope) || 1);

  const secondsRemaining = 13.5 * jumpRopeBlocksLeft;

  return (
    <div className="flex flex-col w-screen min-h-screen items-center">
      <BackgroundCover />

      <Header />

      <NavigationPills />

      <VaressStatsHeader />

      <ApyStatsCard />

      <div className="container mx-auto w-full px-4 sm:px-8 md:px-16 lg:px-32 pb-32">
        <Card flatVariant className="py-2 px-4 md:px-8">
          <CardContent>
            <div className="flex flex-row justify-between w-full">
              <div className="flex flex-col w-3/4">
                <div className="text-sm cursive mb-4">
                  HOPE Liquidity Generation Event
                </div>

                <div className="text-lg font-semibold mb-4 whitespace-no-wrap">
                  Jump Rope v2 - HOPE LGE
                </div>
              </div>

              <div className="w-1/4">
                <Button
                  size="sm"
                  disabled={
                    jumpRopeOwnershipPercent === '0' ||
                    jumpRopeHasCollected ||
                    jumpRopeBlocksLeft > 0
                  }
                  text={jumpRopeHasCollected ? 'Claimed' : 'Claim HOPE'}
                  onClick={claimHope}
                />
              </div>
            </div>

            <div className="flex flex-col items-center w-full">
              <div className="text-base font-medium italic mt-4 mb-8 px-8 text-center">
                Half of the HOPE in the treasury is being auctioned in the
                following LGE, to facilitate HOPE's evolution to becoming a
                tradeable asset. The other half of the HOPE will be paired with
                the ETH from the auction to seed the initial SushiSwap HOPE/ETH
                LP. <br />
                All LP tokens will be burned, to permanently lock the liquidity
                on SushiSwap.
              </div>

              <Card className="w-full md:w-2/3 xl:w-1/2">
                <CardContent className="items-center mb-4">
                  <div className="flex flex-col justify-between items-center w-full">
                    <img
                      width="50"
                      height="100"
                      src="/images/HopeTokenv3.gif"
                      alt="rope_asset"
                      className="mb-8"
                    />

                    <div className="flex flex-col w-full">
                      <div className="flex flex-row justify-between w-full text-sm pb-2 pl-8 pr-4 whitespace-no-wrap">
                        <span className="font-semibold pr-8">
                          Your ETH contribution:
                        </span>
                        <span className="font-thin text-base">
                          {(
                            (Number(jumpRopeOwnershipPercent) / 100) *
                            Number(jumpRopeTotalRope)
                          ).toLocaleString('en-US', {
                            maximumFractionDigits: 4,
                          })}
                        </span>
                      </div>

                      <div className="flex flex-row justify-between w-full text-sm pb-2 pl-8 pr-4 whitespace-no-wrap">
                        <span className="font-semibold pr-8">
                          Your expected HOPE:
                        </span>
                        <span className="font-thin text-base">
                          {(
                            (Number(jumpRopeOwnershipPercent) / 100) *
                            Number(jumpRopeTotalHope)
                          ).toLocaleString('en-US', {
                            maximumFractionDigits: 4,
                          })}
                          {Number(jumpRopeOwnershipPercent) > 0 && (
                            <span className="ml-1">
                              (
                              {Number(jumpRopeOwnershipPercent).toLocaleString(
                                'en-US',
                                {
                                  maximumFractionDigits: 2,
                                }
                              )}{' '}
                              %)
                            </span>
                          )}
                        </span>
                      </div>

                      <div className="flex flex-row justify-between w-full text-sm pb-2 pl-8 pr-4 whitespace-no-wrap">
                        <span className="font-semibold pr-8">
                          HOPE being auctioned:
                        </span>
                        <span className="font-thin text-base">
                          {Number(jumpRopeTotalHope).toLocaleString('en-US', {
                            maximumFractionDigits: 4,
                          })}
                        </span>
                      </div>

                      <div className="flex flex-row justify-between w-full text-sm font-semibold pb-2 pl-8 pr-4 whitespace-no-wrap">
                        <span className="font-semibold pr-8">
                          Total ETH deposited:
                        </span>
                        <span className="font-thin text-base">
                          {Number(jumpRopeTotalRope).toLocaleString('en-US', {
                            maximumFractionDigits: 4,
                          })}
                        </span>
                      </div>

                      <div className="flex flex-row justify-between w-full text-sm font-semibold pb-2 pl-8 pr-4 whitespace-no-wrap">
                        <span className="font-semibold pr-8">
                          Current rate:
                        </span>
                        <span className="font-thin text-base">
                          {Number(hopeToRopeRate).toLocaleString('en-US', {
                            maximumFractionDigits: 4,
                          })}{' '}
                          HOPE / ETH
                        </span>
                      </div>

                      <div className="flex flex-row justify-between w-full text-sm font-semibold pl-8 pr-4 whitespace-no-wrap">
                        <span className="font-semibold pr-8">
                          Time remaining:
                        </span>
                        <span className="font-thin text-base">
                          <ReactCountdown
                            date={
                              new Date(
                                new Date().getTime() + 1000 * secondsRemaining
                              )
                            }
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </CardContent>

                <Button
                  disabled={jumpRopeBlocksLeft < 0}
                  text="Contribute ETH"
                  variant="purple"
                  onClick={onShowDepositModal}
                />
              </Card>
            </div>
          </CardContent>

          <Footer />
        </Card>
      </div>
    </div>
  );
};

export default JumpRope;
