import React, { useState } from 'react';

import { depositEthInJumpRope } from 'rope/utils';
import useRope from 'hooks/useRope';

import Button from 'components/Button';
import Modal from 'components/Modal';
import ModalContent from 'components/ModalContent';
import Spacer from 'components/Spacer';

const DepositModal: React.FC<any> = ({ onDismiss }) => {
  const { rope, account, ethBalance } = useRope();
  const [depositAmount, setDepositAmount] = useState('0');

  const onChangeDepositAmount = (event: any) =>
    setDepositAmount(String(event.target.value).replace(/[^\d.-]/g, ''));

  const depositMore = async (amount: string) =>
    depositEthInJumpRope(
      rope,
      account,
      (Number(amount) >= Number(ethBalance) ? ethBalance : amount).toString()
    ).then(onDismiss);

  return (
    <Modal>
      <ModalContent>
        <div className="flex flex-col w-full justify-center items-center px-12">
          <div className="text-md font-semibold text-center mb-4">
            Deposited ETH cannot be withdrawn after being deposited. Be sure you
            really want to contribute your ETH to the HOPE auction before
            depositing.
          </div>

          <div className="text-md italic font-thin text-center">
            *If the deposit button does not work, try removing a decimal or two
            from the amount.
          </div>

          <Spacer />

          <div className="relative">
            <span
              className="absolute top-0 left-0 mt-2 ml-2 text-sm font-semibold cursor-pointer text-black"
              onClick={() =>
                onChangeDepositAmount({ target: { value: ethBalance } })
              }
            >
              max
            </span>

            <input
              type="number"
              value={depositAmount}
              className="w-full text-right border border-t-0 border-r-0 border-l-0 border-b-1 bg-transparent border-black border-solid xl:mr-2 px-2 py-1 font-semibold"
              onChange={onChangeDepositAmount}
            />
          </div>
        </div>
      </ModalContent>

      <div className="flex flex-row w-full">
        <Button variant="secondary" text="Cancel" onClick={onDismiss} />

        <Button text="Deposit" onClick={() => depositMore(depositAmount)} />
      </div>
    </Modal>
  );
};

export default DepositModal;
