import { useState, useEffect } from 'react';

import { getRaffleTicketsPurchased } from 'rope/utils';

import useRope from './useRope';

const useRaffleTicketsPurchased = () => {
  const { rope } = useRope();

  const [uncommonTicketsPurchased, setUncommonTicketsPurchased] = useState('0');
  const [rareTicketsPurchased, setRareTicketsPurchased] = useState('0');
  const [epicTicketsPurchased, setSuperRareTicketsPurchased] = useState('0');

  useEffect(() => {
    if (rope) {
      (async () => {
        const uncommonTicketsPurchased = await getRaffleTicketsPurchased(rope, 0);
        const rareTicketsPurchased = await getRaffleTicketsPurchased(rope, 1);
        const epicTicketsPurchased = await getRaffleTicketsPurchased(rope, 2);

        setUncommonTicketsPurchased(uncommonTicketsPurchased);
        setRareTicketsPurchased(rareTicketsPurchased);
        setSuperRareTicketsPurchased(epicTicketsPurchased);
      })();
    }
  }, [rope]);

  return { uncommonTicketsPurchased, rareTicketsPurchased, epicTicketsPurchased };
}

export default useRaffleTicketsPurchased;

