import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import useRaffleTicketsPurchased from 'hooks/useRaffleTicketsPurchased';

import Button from 'components/Button';
import Header from 'components/Header';
import Footer from 'components/Footer';
import BackgroundCover from 'components/BackgroundCover';
import NftCard from 'components/NftCard';
import StakePoolHeader from 'components/StakePoolHeader';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import ApyStatsCard from 'components/ApyStatsCard';
import VaressStatsHeader from 'components/VaressStatsHeader';
import PoolCard from 'components/PoolCard';
import NavigationPills from 'components/NavigationPills';

import { cards } from 'cardSet';

const PurpleSpan = styled.span`
  color: ${({ theme }) => theme.color.purple[400]};
`;

const BlueSpan = styled.span`
  color: ${({ theme }) => theme.color.blue[400]};
`;

const PinkSpan = styled.span`
  color: ${({ theme }) => theme.color.pink[400]};
`;

const KawaiiLovecraftSet: React.FC = () => {
  const history = useHistory();
  const {
    uncommonTicketsPurchased,
    rareTicketsPurchased,
    epicTicketsPurchased,
  } = useRaffleTicketsPurchased();

  return (
    <div className="flex flex-col w-screen min-h-screen items-center">
      <BackgroundCover />

      <Header />

      <NavigationPills />

      <VaressStatsHeader />

      <ApyStatsCard />

      <div className="container mx-auto w-full px-4 sm:px-8 md:px-16 lg:px-32 pb-32">
        <Card flatVariant className="py-2 px-4 md:px-8">
          <CardContent>
            <div className="flex flex-col sm:flex-row flex-wrap w-full">
              <div className="flex flex-col w-full xl:w-1/3">
                <div className="text-sm cursive mb-4">
                  <Link to="/varess">Varess Pool - NFT Farm</Link>
                </div>

                <div className="text-lg font-semibold mb-4 whitespace-no-wrap">
                  Set 1 - "fear of the unknown"
                </div>
              </div>

              <StakePoolHeader backLink="/varess" backText="change set" />
            </div>

            <div className="flex flex-row w-full">
              <PoolCard
                variant="black"
                className="mr-4"
                onClick={() =>
                  history.push('/varess/raffles/fear-of-the-unknown/uncommon')
                }
              >
                <div className="text-xl font-semibold pb-6">
                  Uncommon Raffle
                </div>

                <div className="w-1/5 mb-6 border border-l-0 border-r-0 border-t-0 border-b-1 border-black"></div>

                <PurpleSpan>
                  <b>{uncommonTicketsPurchased} / 8</b> tickets sold
                </PurpleSpan>

                <div className="cursive text-black py-8 px-4 text-center">
                  Participate in the raffle for an <br />
                  <PurpleSpan>Uncommon Set 1 NFT</PurpleSpan>
                </div>

                <Button
                  to="/spindles"
                  text="Don't select raffle."
                  variant="black"
                />
              </PoolCard>

              <PoolCard
                variant="black"
                className="mr-4"
                onClick={() =>
                  history.push('/varess/raffles/fear-of-the-unknown/rare')
                }
              >
                <div className="text-xl font-semibold pb-6">Rare Raffle</div>

                <div className="w-1/5 mb-6 border border-l-0 border-r-0 border-t-0 border-b-1 border-black"></div>

                <BlueSpan>
                  <b>{rareTicketsPurchased} / 40</b> tickets sold
                </BlueSpan>

                <div className="cursive text-black py-8 px-4 text-center">
                  Participate in the raffle for an <br />
                  <BlueSpan>Rare Set 1 NFT</BlueSpan>
                </div>

                <Button
                  to="/spindles"
                  text="Don't select raffle."
                  variant="black"
                />
              </PoolCard>

              <PoolCard
                variant="pink"
                onClick={() =>
                  history.push('/varess/raffles/fear-of-the-unknown/epic')
                }
              >
                <div className="text-xl font-semibold pb-6">Epic Raffle</div>

                <div className="w-1/5 mb-6 border border-l-0 border-r-0 border-t-0 border-b-1 border-black"></div>

                <PinkSpan>
                  <b>{epicTicketsPurchased} / 200</b> tickets sold
                </PinkSpan>

                <div className="cursive text-black py-8 px-4 text-center">
                  Participate in the raffle for an <br />
                  <PinkSpan>Epic Set 1 NFT</PinkSpan>
                </div>

                <Button
                  to="/spindles"
                  text="Don't select raffle."
                  variant="pink"
                />
              </PoolCard>
            </div>

            <div className="text-lg font-semibold mb-4 whitespace-no-wrap">
              Uncommon Set
            </div>

            <div className="flex flex-wrap w-full justify-around lg:-ml-4">
              {[...(cards as any).keys()]
                .filter((id: number) => id > 73 && id < 89 && id % 3 === 1)
                .map((id: number) => (
                  <div
                    key={id}
                    className={`mt-24 mb-10 w-full md:w-2/5 lg:w-3/10 lg:-mr-8`}
                  >
                    <NftCard useV2 allowPurchaseMultiple id={id} />
                  </div>
                ))}
            </div>

            <div className="text-lg font-semibold mb-4 whitespace-no-wrap">
              Rare Set
            </div>

            <div className="flex flex-wrap w-full justify-around lg:-ml-4">
              {[...(cards as any).keys()]
                .filter((id: number) => id > 73 && id < 89 && id % 3 === 0)
                .map((id: number) => (
                  <div
                    key={id}
                    className={`mt-24 mb-10 w-full md:w-2/5 lg:w-3/10 lg:-mr-8`}
                  >
                    <NftCard useV2 allowPurchaseMultiple id={id} />
                  </div>
                ))}
            </div>

            <div className="text-lg font-semibold mb-4 whitespace-no-wrap">
              Epic Set
            </div>

            <div className="flex flex-wrap w-full justify-around lg:-ml-4">
              {[...(cards as any).keys()]
                .filter((id: number) => id > 73 && id < 89 && id % 3 === 2)
                .map((id: number) => (
                  <div
                    key={id}
                    className={`mt-24 mb-10 w-full md:w-2/5 lg:w-3/10 lg:-mr-8`}
                  >
                    <NftCard useV2 id={id} />
                  </div>
                ))}
            </div>
          </CardContent>

          <Footer />
        </Card>
      </div>
    </div>
  );
};

export default KawaiiLovecraftSet;
