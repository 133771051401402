export const cards = new Map<number, any>();

// Let's keep this separate from the other variable storing a copy of the JSON metadata
export const cardsExtraData = new Map<number, any>();

// cards.set(1, {
//     "pool": {
//       "name": "root",
//       "points": 1
//     },
//     "external_url": "https://rope.lol/cards/api/1",
//     "image": "https://rope.lol/images/cards/Varess_Generation_One/1.jpg",
//     "name": "root_artifact",
//     "description": "Ancient Rope was first made from the fibers of water reeds, thus strong roots was a necessity. This artifact is a play to our humble beginnings as Rope Makers.",
//     "attributes": [
//       {
//         "trait_type": "Set",
//         "value": "Powered by Anons"
//       },
//       {
//         "trait_type": "Rarity",
//         "value": "Common"
//       },
//       {
//         "trait_type": "Artist",
//         "value": "Rope Makers United"
//       },
//       {
//         "trait_type": "Type",
//         "value": "Varess"
//       },
//       {
//         "trait_type": "Tag",
//         "value": "@dontbuyrope"
//       },
//       {
//         "trait_type": "Exhibition",
//         "value": "Don't Buy Rope"
//       },
//       {
//         "display_type": "date",
//         "trait_type": "birthday",
//         "value": 1600782718
//       },
//       {
//         "trait_type": "Max Supply",
//         "value": "10000"
//       }
//     ]
//   }
// )

cards.set(2, {
    "pool": {
      "name": "root",
      "points": 1
    },
    "external_url": "https://rope.lol/cards/api/2",
    "image": "https://rope.lol/images/cards/Varess_Generation_One/2.jpg",
    "name": "IceBergy",
    "description": "He gave us all our $ROPE so we didn’t have to buy any.",
    "attributes": [
      {
        "trait_type": "Set",
        "value": "Powered by Anons"
      },
      {
        "trait_type": "Rarity",
        "value": "Uncommon"
      },
      {
        "trait_type": "Artist",
        "value": "@Jay_Eff_Vee"
      },
      {
        "trait_type": "Type",
        "value": "Varess"
      },
      {
        "trait_type": "Tag",
        "value": "@icebergy_"
      },
      {
        "trait_type": "Exhibition",
        "value": "Don't Buy Rope"
      },
      {
        "display_type": "date",
        "trait_type": "birthday",
        "value": 1600782718
      },
      {
        "trait_type": "Max Supply",
        "value": "500"
      }
    ]
  }
)

cards.set(3, {
    "pool": {
      "name": "root",
      "points": 1
    },
    "external_url": "https://rope.lol/cards/api/3",
    "image": "https://rope.lol/images/cards/Varess_Generation_One/3.gif",
    "name": "IceBergy",
    "description": "He gave us all our $ROPE so we didn’t have to buy any.",
    "attributes": [
      {
        "trait_type": "Set",
        "value": "Powered by Anons"
      },
      {
        "trait_type": "Rarity",
        "value": "Rare"
      },
      {
        "trait_type": "Artist",
        "value": "@Jay_Eff_Vee"
      },
      {
        "trait_type": "Type",
        "value": "Varess"
      },
      {
        "trait_type": "Tag",
        "value": "@icebergy_"
      },
      {
        "trait_type": "Exhibition",
        "value": "Don't Buy Rope"
      },
      {
        "display_type": "date",
        "trait_type": "birthday",
        "value": 1600782718
      },
      {
        "trait_type": "Max Supply",
        "value": "100"
      }
    ]
  }
)

cards.set(4, {
    "pool": {
      "name": "root",
      "points": 1
    },
    "external_url": "https://rope.lol/cards/api/4",
    "image": "https://rope.lol/images/cards/Varess_Generation_One/4.jpg",
    "name": "Zeus Capital",
    "description": "Sworn enemy of the Link Marines and losing the war. We hope they don’t get liquidated and don't buy $ROPE.",
    "attributes": [
      {
        "trait_type": "Set",
        "value": "Powered by Anons"
      },
      {
        "trait_type": "Rarity",
        "value": "Uncommon"
      },
      {
        "trait_type": "Artist",
        "value": "@Jay_Eff_Vee"
      },
      {
        "trait_type": "Type",
        "value": "Varess"
      },
      {
        "trait_type": "Tag",
        "value": "@ZeusCapitalLLP"
      },
      {
        "trait_type": "Exhibition",
        "value": "Don't Buy Rope"
      },
      {
        "display_type": "date",
        "trait_type": "birthday",
        "value": 1600782718
      },
      {
        "trait_type": "Max Supply",
        "value": "500"
      }
    ]
  }
)

cards.set(5, {
    "pool": {
      "name": "root",
      "points": 1
    },
    "external_url": "https://rope.lol/cards/api/5",
    "image": "https://rope.lol/images/cards/Varess_Generation_One/5.gif",
    "name": "Zeus Capital",
    "description": "Sworn enemy of the Link Marines and losing the war. We hope they don’t get liquidated and don't buy $ROPE.",
    "attributes": [
      {
        "trait_type": "Set",
        "value": "Powered by Anons"
      },
      {
        "trait_type": "Rarity",
        "value": "Rare"
      },
      {
        "trait_type": "Artist",
        "value": "@Jay_Eff_Vee"
      },
      {
        "trait_type": "Type",
        "value": "Varess"
      },
      {
        "trait_type": "Tag",
        "value": "@ZeusCapitalLLP"
      },
      {
        "trait_type": "Exhibition",
        "value": "Don't Buy Rope"
      },
      {
        "display_type": "date",
        "trait_type": "birthday",
        "value": 1600782718
      },
      {
        "trait_type": "Max Supply",
        "value": "100"
      }
    ]
  }
)

cards.set(6, {
    "pool": {
      "name": "root",
      "points": 1
    },
    "external_url": "https://rope.lol/cards/api/6",
    "image": "https://rope.lol/images/cards/Varess_Generation_One/6.jpg",
    "name": "Chef Nomi",
    "description": "He's a good guy, I am sure he didn't buy $ROPE.",
    "attributes": [
      {
        "trait_type": "Set",
        "value": "Powered by Anons"
      },
      {
        "trait_type": "Rarity",
        "value": "Uncommon"
      },
      {
        "trait_type": "Artist",
        "value": "@Jay_Eff_Vee"
      },
      {
        "trait_type": "Type",
        "value": "Varess"
      },
      {
        "trait_type": "Tag",
        "value": "@NomiChef"
      },
      {
        "trait_type": "Exhibition",
        "value": "Don't Buy Rope"
      },
      {
        "display_type": "date",
        "trait_type": "birthday",
        "value": 1600782718
      },
      {
        "trait_type": "Max Supply",
        "value": "500"
      }
    ]
  }
)

cards.set(7, {
    "pool": {
      "name": "root",
      "points": 1
    },
    "external_url": "https://rope.lol/cards/api/7",
    "image": "https://rope.lol/images/cards/Varess_Generation_One/7.gif",
    "name": "Chef Nomi",
    "description": "He's a good guy, I am sure he didn't buy $ROPE.",
    "attributes": [
      {
        "trait_type": "Set",
        "value": "Powered by Anons"
      },
      {
        "trait_type": "Rarity",
        "value": "Rare"
      },
      {
        "trait_type": "Artist",
        "value": "@Jay_Eff_Vee"
      },
      {
        "trait_type": "Type",
        "value": "Varess"
      },
      {
        "trait_type": "Tag",
        "value": "@NomiChef"
      },
      {
        "trait_type": "Exhibition",
        "value": "Don't Buy Rope"
      },
      {
        "display_type": "date",
        "trait_type": "birthday",
        "value": 1600782718
      },
      {
        "trait_type": "Max Supply",
        "value": "100"
      }
    ]
  }
)

cards.set(8, {
    "pool": {
      "name": "root",
      "points": 1
    },
    "external_url": "https://rope.lol/cards/api/8",
    "image": "https://rope.lol/images/cards/Varess_Generation_One/8.jpg",
    "name": "CryptoGainz",
    "description": "He's profitable, he swears, but his bots keep spending his money. We hope he will buy a boat and not a $ROPE.",
    "attributes": [
      {
        "trait_type": "Set",
        "value": "Powered by Anons"
      },
      {
        "trait_type": "Rarity",
        "value": "Uncommon"
      },
      {
        "trait_type": "Artist",
        "value": "@Jay_Eff_Vee"
      },
      {
        "trait_type": "Type",
        "value": "Varess"
      },
      {
        "trait_type": "Tag",
        "value": "@CryptoGainz1"
      },
      {
        "trait_type": "Exhibition",
        "value": "Don't Buy Rope"
      },
      {
        "display_type": "date",
        "trait_type": "birthday",
        "value": 1600782718
      },
      {
        "trait_type": "Max Supply",
        "value": "500"
      }
    ]
  }
)

cards.set(9, {
    "pool": {
      "name": "root",
      "points": 1
    },
    "external_url": "https://rope.lol/cards/api/9",
    "image": "https://rope.lol/images/cards/Varess_Generation_One/9.gif",
    "name": "CryptoGainz",
    "description": "He's profitable, he swears, but his bots keep spending his money. We hope he will buy a boat and not a $ROPE.",
    "attributes": [
      {
        "trait_type": "Set",
        "value": "Powered by Anons"
      },
      {
        "trait_type": "Rarity",
        "value": "Rare"
      },
      {
        "trait_type": "Artist",
        "value": "@Jay_Eff_Vee"
      },
      {
        "trait_type": "Type",
        "value": "Varess"
      },
      {
        "trait_type": "Tag",
        "value": "@CryptoGainz1"
      },
      {
        "trait_type": "Exhibition",
        "value": "Don't Buy Rope"
      },
      {
        "display_type": "date",
        "trait_type": "birthday",
        "value": 1600782718
      },
      {
        "trait_type": "Max Supply",
        "value": "100"
      }
    ]
  }
)

cards.set(10, {
    "pool": {
      "name": "root",
      "points": 1
    },
    "external_url": "https://rope.lol/cards/api/10",
    "image": "https://rope.lol/images/cards/Varess_Generation_One/10.jpg",
    "name": "BlueKirby.eth",
    "description": "Oh my...few understand...not to buy $ROPE.",
    "attributes": [
      {
        "trait_type": "Set",
        "value": "Powered by Anons"
      },
      {
        "trait_type": "Rarity",
        "value": "Uncommon"
      },
      {
        "trait_type": "Artist",
        "value": "@Jay_Eff_Vee"
      },
      {
        "trait_type": "Type",
        "value": "Varess"
      },
      {
        "trait_type": "Tag",
        "value": "@bluekirbyfi"
      },
      {
        "trait_type": "Exhibition",
        "value": "Don't Buy Rope"
      },
      {
        "display_type": "date",
        "trait_type": "birthday",
        "value": 1600782718
      },
      {
        "trait_type": "Max Supply",
        "value": "500"
      }
    ]
  }
)

cards.set(11, {
    "pool": {
      "name": "root",
      "points": 1
    },
    "external_url": "https://rope.lol/cards/api/11",
    "image": "https://rope.lol/images/cards/Varess_Generation_One/11.gif",
    "name": "BlueKirby.eth",
    "description": "Oh my...few understand...not to buy $ROPE.",
    "attributes": [
      {
        "trait_type": "Set",
        "value": "Powered by Anons"
      },
      {
        "trait_type": "Rarity",
        "value": "Rare"
      },
      {
        "trait_type": "Artist",
        "value": "@Jay_Eff_Vee"
      },
      {
        "trait_type": "Type",
        "value": "Varess"
      },
      {
        "trait_type": "Tag",
        "value": "@bluekirbyfi"
      },
      {
        "trait_type": "Exhibition",
        "value": "Don't Buy Rope"
      },
      {
        "display_type": "date",
        "trait_type": "birthday",
        "value": 1600782718
      },
      {
        "trait_type": "Max Supply",
        "value": "100"
      }
    ]
  }
)

cards.set(12, {
    "pool": {
      "name": "root",
      "points": 1
    },
    "external_url": "https://rope.lol/cards/api/12",
    "image": "https://rope.lol/images/cards/Varess_Generation_One/12.jpg",
    "name": "Monsoonie",
    "description": "The up and coming anon. If the dev ain’t dead, he isn’t buying.  We told him not to buy $ROPE but he keeps trying.",
    "attributes": [
      {
        "trait_type": "Set",
        "value": "Powered by Anons"
      },
      {
        "trait_type": "Rarity",
        "value": "Uncommon"
      },
      {
        "trait_type": "Artist",
        "value": "@Jay_Eff_Vee"
      },
      {
        "trait_type": "Type",
        "value": "Varess"
      },
      {
        "trait_type": "Tag",
        "value": "@mewn21"
      },
      {
        "trait_type": "Exhibition",
        "value": "Don't Buy Rope"
      },
      {
        "display_type": "date",
        "trait_type": "birthday",
        "value": 1600782718
      },
      {
        "trait_type": "Max Supply",
        "value": "500"
      }
    ]
  }
)

cards.set(13, {
    "pool": {
      "name": "root",
      "points": 1
    },
    "external_url": "https://rope.lol/cards/api/13",
    "image": "https://rope.lol/images/cards/Varess_Generation_One/13.gif",
    "name": "Monsoonie",
    "description": "The up and coming anon. If the dev ain’t dead, he isn’t buying.  We told him not to buy $ROPE but he keeps trying.",
    "attributes": [
      {
        "trait_type": "Set",
        "value": "Powered by Anons"
      },
      {
        "trait_type": "Rarity",
        "value": "Rare"
      },
      {
        "trait_type": "Artist",
        "value": "@Jay_Eff_Vee"
      },
      {
        "trait_type": "Type",
        "value": "Varess"
      },
      {
        "trait_type": "Tag",
        "value": "@mewn21"
      },
      {
        "trait_type": "Exhibition",
        "value": "Don't Buy Rope"
      },
      {
        "display_type": "date",
        "trait_type": "birthday",
        "value": 1600782718
      },
      {
        "trait_type": "Max Supply",
        "value": "100"
      }
    ]
  }
)

cards.set(14, {
    "pool": {
      "name": "root",
      "points": 1
    },
    "external_url": "https://rope.lol/cards/api/14",
    "image": "https://rope.lol/images/cards/Varess_Generation_One/14.jpg",
    "name": "Ghoul",
    "description": "BASED King Ghoul, hard at work for the anons.  He chose to go to the moon, and he didn't buy $ROPE; he's already dead.",
    "attributes": [
      {
        "trait_type": "Set",
        "value": "Powered by Anons"
      },
      {
        "trait_type": "Rarity",
        "value": "Uncommon"
      },
      {
        "trait_type": "Artist",
        "value": "@Jay_Eff_Vee"
      },
      {
        "trait_type": "Type",
        "value": "Varess"
      },
      {
        "trait_type": "Tag",
        "value": "@xGhoul0x"
      },
      {
        "trait_type": "Exhibition",
        "value": "Don't Buy Rope"
      },
      {
        "display_type": "date",
        "trait_type": "birthday",
        "value": 1600782718
      },
      {
        "trait_type": "Max Supply",
        "value": "500"
      }
    ]
  }
)

cards.set(15, {
    "pool": {
      "name": "root",
      "points": 1
    },
    "external_url": "https://rope.lol/cards/api/15",
    "image": "https://rope.lol/images/cards/Varess_Generation_One/15.gif",
    "name": "Ghoul",
    "description": "BASED King Ghoul, hard at work for the anons.  He chose to go to the moon, and he didn't buy $ROPE; he's already dead.",
    "attributes": [
      {
        "trait_type": "Set",
        "value": "Powered by Anons"
      },
      {
        "trait_type": "Rarity",
        "value": "Rare"
      },
      {
        "trait_type": "Artist",
        "value": "@Jay_Eff_Vee"
      },
      {
        "trait_type": "Type",
        "value": "Varess"
      },
      {
        "trait_type": "Tag",
        "value": "@xGhoul0x"
      },
      {
        "trait_type": "Exhibition",
        "value": "Don't Buy Rope"
      },
      {
        "display_type": "date",
        "trait_type": "birthday",
        "value": 1600782718
      },
      {
        "trait_type": "Max Supply",
        "value": "100"
      }
    ]
  }
)

cards.set(16, {
    "pool": {
      "name": "root",
      "points": 1
    },
    "external_url": "https://rope.lol/cards/api/16",
    "image": "https://rope.lol/images/cards/Varess_Generation_One/16.jpg",
    "name": "ChainLinkGod.eth",
    "description": "You don’t need $ROPE when you have LINK. The Chainlinkgod moves markets, liquidation for Zeus Capital is...inevitable.",
    "attributes": [
      {
        "trait_type": "Set",
        "value": "Powered by Anons"
      },
      {
        "trait_type": "Rarity",
        "value": "Uncommon"
      },
      {
        "trait_type": "Artist",
        "value": "@Jay_Eff_Vee"
      },
      {
        "trait_type": "Type",
        "value": "Varess"
      },
      {
        "trait_type": "Tag",
        "value": "@ChainLinkGod"
      },
      {
        "trait_type": "Exhibition",
        "value": "Don't Buy Rope"
      },
      {
        "display_type": "date",
        "trait_type": "birthday",
        "value": 1600782718
      },
      {
        "trait_type": "Max Supply",
        "value": "500"
      }
    ]
  }
)

cards.set(17, {
    "pool": {
      "name": "root",
      "points": 1
    },
    "external_url": "https://rope.lol/cards/api/17",
    "image": "https://rope.lol/images/cards/Varess_Generation_One/17.gif",
    "name": "ChainLinkGod.eth",
    "description": "You don’t need $ROPE when you have LINK. The Chainlinkgod moves markets, liquidation for Zeus Capital is...inevitable.",
    "attributes": [
      {
        "trait_type": "Set",
        "value": "Powered by Anons"
      },
      {
        "trait_type": "Rarity",
        "value": "Rare"
      },
      {
        "trait_type": "Artist",
        "value": "@Jay_Eff_Vee"
      },
      {
        "trait_type": "Type",
        "value": "Varess"
      },
      {
        "trait_type": "Tag",
        "value": "@ChainLinkGod"
      },
      {
        "trait_type": "Exhibition",
        "value": "Don't Buy Rope"
      },
      {
        "display_type": "date",
        "trait_type": "birthday",
        "value": 1600782718
      },
      {
        "trait_type": "Max Supply",
        "value": "100"
      }
    ]
  }
)

cards.set(18, {
    "pool": {
      "name": "root",
      "points": 1
    },
    "external_url": "https://rope.lol/cards/api/18",
    "image": "https://rope.lol/images/cards/Varess_Generation_One/18.jpg",
    "name": "Degen Spartan",
    "description": "Get in pool2 loser, we’re going long. DegenSparan will give you the one night stand.  Don’t buy $ROPE just join him again.",
    "attributes": [
      {
        "trait_type": "Set",
        "value": "Powered by Anons"
      },
      {
        "trait_type": "Rarity",
        "value": "Uncommon"
      },
      {
        "trait_type": "Artist",
        "value": "@Jay_Eff_Vee"
      },
      {
        "trait_type": "Type",
        "value": "Varess"
      },
      {
        "trait_type": "Tag",
        "value": "@DegenSpartan"
      },
      {
        "trait_type": "Exhibition",
        "value": "Don't Buy Rope"
      },
      {
        "display_type": "date",
        "trait_type": "birthday",
        "value": 1600782718
      },
      {
        "trait_type": "Max Supply",
        "value": "500"
      }
    ]
  }
)

cards.set(19, {
    "pool": {
      "name": "root",
      "points": 1
    },
    "external_url": "https://rope.lol/cards/api/19",
    "image": "https://rope.lol/images/cards/Varess_Generation_One/19.gif",
    "name": "Degen Spartan",
    "description": "Get in pool2 loser, we’re going long. DegenSparan will give you the one night stand.  Don’t buy $ROPE just join him again.",
    "attributes": [
      {
        "trait_type": "Set",
        "value": "Powered by Anons"
      },
      {
        "trait_type": "Rarity",
        "value": "Rare"
      },
      {
        "trait_type": "Artist",
        "value": "@Jay_Eff_Vee"
      },
      {
        "trait_type": "Type",
        "value": "Varess"
      },
      {
        "trait_type": "Tag",
        "value": "@DegenSpartan"
      },
      {
        "trait_type": "Exhibition",
        "value": "Don't Buy Rope"
      },
      {
        "display_type": "date",
        "trait_type": "birthday",
        "value": 1600782718
      },
      {
        "trait_type": "Max Supply",
        "value": "100"
      }
    ]
  }
)

cards.set(20, {
    "pool": {
      "name": "root",
      "points": 1
    },
    "external_url": "https://rope.lol/cards/api/20",
    "image": "https://rope.lol/images/cards/Varess_Generation_One/20.jpg",
    "name": "Satoshi",
    "description": "OG anon. We hope he didn’t buy $ROPE and is still around.",
    "attributes": [
      {
        "trait_type": "Set",
        "value": "Powered by Anons"
      },
      {
        "trait_type": "Rarity",
        "value": "Uncommon"
      },
      {
        "trait_type": "Artist",
        "value": "@Jay_Eff_Vee"
      },
      {
        "trait_type": "Type",
        "value": "Varess"
      },
      {
        "trait_type": "Tag",
        "value": "@halfin"
      },
      {
        "trait_type": "Exhibition",
        "value": "Don't Buy Rope"
      },
      {
        "display_type": "date",
        "trait_type": "birthday",
        "value": 1600782718
      },
      {
        "trait_type": "Max Supply",
        "value": "500"
      }
    ]
  }
)

cards.set(21, {
    "pool": {
      "name": "root",
      "points": 1
    },
    "external_url": "https://rope.lol/cards/api/21",
    "image": "https://rope.lol/images/cards/Varess_Generation_One/21.gif",
    "name": "Satoshi",
    "description": "OG anon. We hope he didn’t buy $ROPE and is still around.",
    "attributes": [
      {
        "trait_type": "Set",
        "value": "Powered by Anons"
      },
      {
        "trait_type": "Rarity",
        "value": "Rare"
      },
      {
        "trait_type": "Artist",
        "value": "@Jay_Eff_Vee"
      },
      {
        "trait_type": "Type",
        "value": "Varess"
      },
      {
        "trait_type": "Tag",
        "value": "@halfin"
      },
      {
        "trait_type": "Exhibition",
        "value": "Don't Buy Rope"
      },
      {
        "display_type": "date",
        "trait_type": "birthday",
        "value": 1600782718
      },
      {
        "trait_type": "Max Supply",
        "value": "100"
      }
    ]
  },
)

cards.set(22, {
  "pool": {
  "name": "root",
      "points": 1
  },
  "external_url": "https://rope.lol/cards/api/22",
  "image": "https://rope.lol/images/cards/Charity/22.gif",
  "name": "Shinjuku Samurai",
  "description": "We didn't make a 1000 of these but we are giving all proceeds to Save The Children. Remember: there's always hope",
  "attributes": [
      {
          "trait_type": "Set",
          "value": "Charity"
      },
      {
          "trait_type": "Rarity",
          "value": "off___rare"
      },
      {
          "trait_type": "Artist",
          "value": "@anonpowered"
      },
      {
          "trait_type": "Type",
          "value": "Varess"
      },
      {
          "trait_type": "Tag",
          "value": "@dontbuyrope"
      },
      {
          "trait_type": "Exhibition",
          "value": "Don't Buy Rope"
      },
      {
          "display_type": "date",
          "trait_type": "birthday",
          "value": 1602146820
      },
      {
          "trait_type": "Max Supply",
          "value": "20"
      }
  ]
}
)

cards.set(23, {
  "pool": {
  "name": "root",
      "points": 1
  },
  "external_url": "https://rope.lol/cards/api/23",
  "image": "https://rope.lol/images/cards/Charity/23.gif",
  "name": "Aokigahara Aiko",
  "description": "We didn't make a 1000 of these but we are giving all proceeds to Save The Children. Remember: there's always hope",
  "attributes": [
      {
          "trait_type": "Set",
          "value": "Charity"
      },
      {
          "trait_type": "Rarity",
          "value": "off___rare"
      },
      {
          "trait_type": "Artist",
          "value": "@anonpowered"
      },
      {
          "trait_type": "Type",
          "value": "Varess"
      },
      {
          "trait_type": "Tag",
          "value": "@dontbuyrope"
      },
      {
          "trait_type": "Exhibition",
          "value": "Don't Buy Rope"
      },
      {
          "display_type": "date",
          "trait_type": "birthday",
          "value": 1602146820
      },
      {
          "trait_type": "Max Supply",
          "value": "20"
      }
  ]
}
)

cards.set(24, {
  "pool": {
  "name": "root",
      "points": 1
  },
  "external_url": "https://rope.lol/cards/api/24",
  "image": "https://rope.lol/images/cards/Charity/24.gif",
  "name": "Osaka Onna-Bugeisha",
  "description": "We didn't make a 1000 of these but we are giving all proceeds to Save The Children. Remember: there's always hope",
  "attributes": [
      {
          "trait_type": "Set",
          "value": "Charity"
      },
      {
          "trait_type": "Rarity",
          "value": "off___rare"
      },
      {
          "trait_type": "Artist",
          "value": "@anonpowered"
      },
      {
          "trait_type": "Type",
          "value": "Varess"
      },
      {
          "trait_type": "Tag",
          "value": "@dontbuyrope"
      },
      {
          "trait_type": "Exhibition",
          "value": "Don't Buy Rope"
      },
      {
          "display_type": "date",
          "trait_type": "birthday",
          "value": 1602146820
      },
      {
          "trait_type": "Max Supply",
          "value": "20"
      }
  ]
}
)

cards.set(25, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/25",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/25.jpg",
 "name":"Premature Lunch",
 "description":"LUNCH NOW.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Shit Tier"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Skill Card"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"1111"
    },
    {
       "name":"Exhibition",
       "value":"Don't Buy Rope"
    }
 ]
}
)

cards.set(26, {
   "pool":{
      "name":"root",
      "points":1
   },
   "external_url":"https://rope.lol/cards/api/26",
   "image":"https://rope.lol/images/cards/Varess_Generation_Two/26.jpg",
   "name":"1 Dollar",
   "description":"Is go to dollah.",
   "attributes":[
      {
         "trait_type":"Set",
         "value":"Noombase - The Game"
      },
      {
         "trait_type":"Rarity",
         "value":"Shit Tier"
      },
      {
         "trait_type":"Exhibition",
         "value":"Don't Buy Rope"
      },
      {
         "trait_type":"Artist",
         "value":"Eleven Yellow"
      },
      {
         "trait_type":"Type",
         "value":"Varess"
      },
      {
         "trait_type":"Tag",
         "value":"Card Type"
      },
      {
         "trait_type":"Card Type",
         "value":"Skill Card"
      },
      {
         "display_type":"date",
         "trait_type":"birthday",
         "value":1601081917
      },
      {
         "trait_type":"Max Supply",
         "value":"1111"
      }
   ]
})

cards.set(27, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/27",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/27.jpg",
 "name":"Consoonter",
 "description":"I didn't consoooooooont!",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Shit Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Skill Card"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"1111"
    }
 ]
})

cards.set(28, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/28",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/28.jpg",
 "name":"Fair Looncher",
 "description":"The only dumps will be shitty.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Shit Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Character"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"1111"
    }
 ]
})

cards.set(29, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/29",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/29.jpg",
 "name":"DMT Journey",
 "description":"Channel Bro Jogan",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Gold Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Land Tile"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"666"
    }
 ]
})

cards.set(30, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/30",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/30.jpg",
 "name":"Warden Bufflet",
 "description":"Buying the blood in the streets or something.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Gold Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Trap Card"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"666"
    }
 ]
})

cards.set(31, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/31",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/31.jpg",
 "name":"Earth",
 "description":"The blue ball.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Gold Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Land Tile"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"666"
    }
 ]
})

cards.set(32, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/32",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/32.jpg",
 "name":"Ghool",
 "description":"Emotional card, can only be played while opponent is emotional.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Gold Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Character"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"666"
    }
 ]
})

cards.set(33, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/33",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/33.jpg",
 "name":"The Hostage Negotiator",
 "description":"Perfect for when hostages are being held hostage.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Gold Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Character"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"666"
    }
 ]
})

cards.set(34, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/34",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/34.jpg",
 "name":"NoomBoi",
 "description":"Cringe",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Gold Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Character"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"666"
    }
 ]
})

cards.set(35, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/35",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/35.jpg",
 "name":"Rope Devs",
 "description":"They'll be back soon, promise.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Gold Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Character"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"666"
    }
 ]
})

cards.set(36, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/36",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/36.jpg",
 "name":"Wen Noom",
 "description":"The white ball.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Gold Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Land Tile"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"666"
    }
 ]
})

cards.set(37, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/37",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/37.jpg",
 "name":"Buy The Top",
 "description":"Always FOMO in at the top.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Gold Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Character"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"666"
    }
 ]
})

cards.set(38, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/38",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/38.jpg",
 "name":"Mr.Biggs",
 "description":"Stay true to your values",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Gold Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Character"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"666"
    }
 ]
})

cards.set(39, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/39",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/39.jpg",
 "name":"Mr.Wick'd",
 "description":"Stopped tf out.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Gold Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Character"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"666"
    }
 ]
})

cards.set(40, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/40",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/40.jpg",
 "name":"HArdw00d",
 "description":"He says 'Im gay' over 9000 times a day.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Gold Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Land Tile"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"666"
    }
 ]
})

cards.set(41, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/41",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/41.jpg",
 "name":"Retire",
 "description":"You want it to be over.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Gold Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Land Tile"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"666"
    }
 ]
})

cards.set(42, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/42",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/42.jpg",
 "name":"You",
 "description":"Yes you.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Gold Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Character"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"666"
    }
 ]
})

cards.set(43, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/43",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/43.jpg",
 "name":"Transactional Relationship",
 "description":"Seems like a pretty good deal",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Gold Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Land Tile"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"666"
    }
 ]
})

cards.set(44, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/44",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/44.jpg",
 "name":"Karbon",
 "description":"lmao",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Gold Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Character"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"666"
    }
 ]
})

cards.set(45, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/45",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/45.jpg",
 "name":"Go Back To It",
 "description":"Let me google that for you",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Gold Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Character"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"666"
    }
 ]
})

cards.set(46, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/46",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/46.jpg",
 "name":"FUD",
 "description":"Bullish FUD.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Based Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Trap Card"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"420"
    }
 ]
})

cards.set(47, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/47",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/47.jpg",
 "name":"Just Market Buy",
 "description":"Ape buy, dev dump, ape buy more.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Based Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Skill Card"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"420"
    }
 ]
})

cards.set(48, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/48",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/48.jpg",
 "name":"NFT Airdrop",
 "description":"NFT soon bro.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Based Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Skill Card"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"420"
    }
 ]
})

cards.set(49, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/49",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/49.jpg",
 "name":"Save the Frogs",
 "description":"Frogs can't be kicked.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Based Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Character"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"420"
    }
 ]
})

cards.set(50, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/50",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/50.jpg",
 "name":"It's All Pasta",
 "description":"It's over, it's unironically going to zero.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Based Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Character"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"420"
    }
 ]
})

cards.set(51, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/51",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/51.jpg",
 "name":"Chinese Playbook",
 "description":"Unbelievable, this is not butter",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"420 Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Skill Card"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"69"
    }
 ]
})

cards.set(52, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/52",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/52.jpg",
 "name":"SMOL",
 "description":"He just smol.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"420 Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Skill Card"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"69"
    }
 ]
})

cards.set(53, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/53",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/53.jpg",
 "name":"Eleventh Hour",
 "description":"Sell with insider information if you hold this card.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"420 Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Trap Card"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"69"
    }
 ]
})

cards.set(54, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/54",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/54.jpg",
 "name":"Prisoner's Dilemma",
 "description":"Draw a card from your opponent's pocket.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"420 Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Character"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"69"
    }
 ]
})

cards.set(55, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/55",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/55.jpg",
 "name":"Noom Lambo",
 "description":"We just landed on the Noom in a Lambo.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"420 Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Land Tile"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"69"
    }
 ]
})

cards.set(56, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/56",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/56.mp4",
 "name":"The Fair Negotiatooor",
 "description":"Maybe pwese you gib us errything?",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Super Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Character"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"13"
    }
 ]
})

cards.set(57, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/57",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/57.mp4",
 "name":"Community",
 "description":"Let's do it for charity, fuck it...we'll do it live.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Super Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Skill Card"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"13"
    }
 ]
})

cards.set(58, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/58",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/58.mp4",
 "name":"HODL Bruh",
 "description":"Just HODL",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Super Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Trap Card"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"13"
    }
 ]
})

cards.set(59, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/59",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/59.mp4",
 "name":"Noombase Rover",
 "description":"Pay yourself, opponent must thank you and give you all his cards.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Super Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Land Tile"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"13"
    }
 ]
})

cards.set(60, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/60",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/60.mp4",
 "name":"Negative Rebase",
 "description":"Rebase to zero.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Super Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Skill Card"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"13"
    }
 ]
})

cards.set(61, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/61",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/61.mp4",
 "name":"VC Tantrum",
 "description":"For when things don't according to the marketing timeline.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Super Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Land Tile"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"13"
    }
 ]
})

cards.set(62, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/62",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/62.mp4",
 "name":"Drhouls",
 "description":"Put 5 industry bro tokens into play. These tokens have character type Drhoul.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Duper Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Character"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"3"
    }
 ]
})

cards.set(63, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/63",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/63.mp4",
 "name":"I sold my tokens",
 "description":"Sell your tokens before your opponent.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Duper Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Trap Card"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"3"
    }
 ]
})

cards.set(64, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/64",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/64.mp4",
 "name":"Liquidate",
 "description":"Over and out",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Duper Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Skill Card"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"3"
    }
 ]
})

cards.set(65, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/65",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/65.mp4",
 "name":"Unlock to Reveal",
 "description":"Derpy",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"Duper Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Skill Card"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"3"
    }
 ]
})

cards.set(66, {
  "pool":{
    "name":"root",
    "points":1
 },
 "external_url":"https://rope.lol/cards/api/66",
 "image":"https://rope.lol/images/cards/Varess_Generation_Two/66.mp4",
 "name":"The God Card",
 "description":"You win, it's over.",
 "attributes":[
    {
       "trait_type":"Set",
       "value":"Noombase - The Game"
    },
    {
       "trait_type":"Rarity",
       "value":"God Tier"
    },
    {
       "trait_type":"Exhibition",
       "value":"Don't Buy Rope"
    },
    {
       "trait_type":"Artist",
       "value":"Eleven Yellow"
    },
    {
       "trait_type":"Type",
       "value":"Varess"
    },
    {
       "trait_type":"Tag",
       "value":"Card Type"
    },
    {
       "trait_type":"Card Type",
       "value":"Character"
    },
    {
       "display_type":"date",
       "trait_type":"birthday",
       "value":1601081917
    },
    {
       "trait_type":"Max Supply",
       "value":"1"
    }
 ]
})

cards.set(67, {
  "pool":{
    "name":"root",
    "points":1
  },
  "external_url":"https://rope.lol/cards/api/67",
  "image":"https://rope.lol/images/cards/Plan_Trusters/67.gif",
  "name":"Plan Trusters Reward 1/4",
  "description":"Trusted the plan, I was rewarded",
  "attributes":[
    {
      "trait_type":"Set",
      "value":"LP Plan Truster Reward"
    },
    {
      "trait_type":"Rarity",
      "value":"Pretty Rare"
    },
    {
      "trait_type":"Exhibition",
      "value":"Don't Buy Rope"
    },
    {
      "trait_type":"Artist",
      "value":"Eleven Yellow"
    },
    {
      "trait_type":"Type",
      "value":"Varess"
    },
    {
      "trait_type":"Tag",
      "value":"Card Type"
    },
    {
      "trait_type":"Card Type",
      "value":"Reward"
    },
    {
      "display_type":"date",
      "trait_type":"birthday",
      "value":1603771388
    },
    {
      "trait_type":"Max Supply",
      "value":"97"
    }
  ]
});

cards.set(68, {
  "pool":{
    "name":"root",
    "points":1
  },
  "external_url":"https://rope.lol/cards/api/68",
  "image":"https://rope.lol/images/cards/Plan_Trusters/68.gif",
  "name":"Plan Trusters Reward 2/4",
  "description":"Trusted the plan, I was rewarded",
  "attributes":[
    {
      "trait_type":"Set",
      "value":"LP Plan Truster Reward"
    },
    {
      "trait_type":"Rarity",
      "value":"Very Rare"
    },
    {
      "trait_type":"Exhibition",
      "value":"Don't Buy Rope"
    },
    {
      "trait_type":"Artist",
      "value":"Eleven Yellow"
    },
    {
      "trait_type":"Type",
      "value":"Varess"
    },
    {
      "trait_type":"Tag",
      "value":"Card Type"
    },
    {
      "trait_type":"Card Type",
      "value":"Reward"
    },
    {
      "display_type":"date",
      "trait_type":"birthday",
      "value":1603771388
    },
    {
      "trait_type":"Max Supply",
      "value":"71"
    }
  ]
});

cards.set(69, {
  "pool":{
    "name":"root",
    "points":1
  },
  "external_url":"https://rope.lol/cards/api/69",
  "image":"https://rope.lol/images/cards/Plan_Trusters/69.gif",
  "name":"Plan Trusters Reward 3/4",
  "description":"Trusted the plan, I was rewarded",
  "attributes":[
    {
      "trait_type":"Set",
      "value":"LP Plan Truster Reward"
    },
    {
      "trait_type":"Rarity",
      "value":"Uncommon"
    },
    {
      "trait_type":"Exhibition",
      "value":"Don't Buy Rope"
    },
    {
      "trait_type":"Artist",
      "value":"Eleven Yellow"
    },
    {
      "trait_type":"Type",
      "value":"Varess"
    },
    {
      "trait_type":"Tag",
      "value":"Card Type"
    },
    {
      "trait_type":"Card Type",
      "value":"Reward"
    },
    {
      "display_type":"date",
      "trait_type":"birthday",
      "value":1603771388
    },
    {
      "trait_type":"Max Supply",
      "value":"57"
    }
  ]
});

cards.set(70, {
    "pool":{
      "name":"root",
      "points":1
    },
    "external_url":"https://rope.lol/cards/api/70",
    "image":"https://rope.lol/images/cards/Plan_Trusters/70.gif",
    "name":"Plan Trusters Reward 4/4",
    "description":"Trusted the plan, I was rewarded",
    "attributes":[
      {
        "trait_type":"Set",
        "value":"LP Plan Truster Reward"
      },
      {
        "trait_type":"Rarity",
        "value":"Ultra Rare"
      },
      {
        "trait_type":"Exhibition",
        "value":"Don't Buy Rope"
      },
      {
        "trait_type":"Artist",
        "value":"Eleven Yellow"
      },
      {
        "trait_type":"Type",
        "value":"Varess"
      },
      {
        "trait_type":"Tag",
        "value":"Card Type"
      },
      {
        "trait_type":"Card Type",
        "value":"Reward"
      },
      {
        "display_type":"date",
        "trait_type":"birthday",
        "value":1603771388
      },
      {
        "trait_type":"Max Supply",
        "value":"44"
      }
    ]
  }
);

cards.set(71, {
  "pool":{
    "name":"root",
    "points":1
  },
  "external_url":"https://rope.lol/cards/api/71",
  "image":"https://rope.lol/images/cards/Plan_Trusters/71.gif",
  "name":"Hodlers Reward",
  "description":"6.9 was hardcoded.",
  "attributes":[
    {
      "trait_type":"Set",
      "value":"Season 2.9.9 Hodlers Reward"
    },
    {
      "trait_type":"Rarity",
      "value":"Pretty Rare"
    },
    {
      "trait_type":"Exhibition",
      "value":"Don't Buy Rope"
    },
    {
      "trait_type":"Artist",
      "value":"Eleven Yellow"
    },
    {
      "trait_type":"Type",
      "value":"Varess"
    },
    {
      "trait_type":"Tag",
      "value":"Hodler"
    },
    {
      "trait_type":"Card Type",
      "value":"Reward"
    },
    {
      "display_type":"date",
      "trait_type":"birthday",
      "value":"042069"
    },
    {
      "trait_type":"Max Supply",
      "value": "469"
    }
  ]
});

cards.set(72, {
  "pool":{
    "name":"root",
    "points":1
  },
  "external_url":"https://rope.lol/cards/api/72",
  "image":"https://rope.lol/images/cards/Plan_Trusters/72.gif",
  "name":"LP Reward",
  "description":"I weathered the storm. I trusted the plan. I was rewarded.",
  "attributes":[
    {
      "trait_type":"Set",
      "value":"Season 2.9.9 LP Reward"
    },
    {
      "trait_type":"Rarity",
      "value":"Ultra Rare"
    },
    {
      "trait_type":"Exhibition",
      "value":"Don't Buy Rope"
    },
    {
      "trait_type":"Artist",
      "value":"Eleven Yellow"
    },
    {
      "trait_type":"Type",
      "value":"Varess"
    },
    {
      "trait_type":"Tag",
      "value":"LP plan truster"
    },
    {
      "trait_type":"Card Type",
      "value":"Reward"
    },
    {
      "display_type":"date",
      "trait_type":"birthday",
      "value":"042069"
    },
    {
      "trait_type":"Max Supply",
      "value": "123"
    }
  ]
});
cards.set(73, {
  "pool":{
    "name":"root",
    "points":1
  },
  "external_url":"https://rope.lol/cards/api/73",
  "image":"https://rope.lol/images/cards/Loss_Vegas/73.mp4",
  "name":"Blood and Thunder",
  "description":"Evolution:  From the rafters to the roof tops of Loss Vegas. There's no HOPE without ROPE.",
  "attributes":[
    {
      "trait_type":"Set",
      "value":"Loss Vegas Charity Collaboration"
    },
    {
      "trait_type":"Rarity",
      "value":"Charity"
    },
    {
      "trait_type":"Exhibition",
      "value":"Don't Buy Rope"
    },
    {
      "trait_type":"Artist",
      "value":"Krypticrooks"
    },
    {
      "trait_type":"Type",
      "value":"Varess"
    },
    {
      "trait_type":"Tag",
      "value":"Blood & Thunder"
    },
    {
      "trait_type":"Card Type",
      "value":"Charity  Event"
    },
    {
      "display_type":"date",
      "trait_type":"birthday",
      "value":"042069"
    },
    {
      "trait_type":"Max Supply",
      "value": "69"
    }
  ]
})

cards.set(74, {
   "pool":{
      "name":"root",
      "points":1
   },
   "external_url":"https://rope.lol/cards/api/74",
   "image":"https://anonpowered.azureedge.net/anonpowered/Cthulhu/cthulhu3d.gif",
   "animation_url":"https://anonpowered.azureedge.net/anonpowered/Cthulhu/cthulhu.glb",
   "name":"Cthulhu",
   "description":"They were not composed altogether of flesh and blood. They had shape but that shape was not made of matter. When the stars were right, They could plunge from world to world through the sky; but when the stars were wrong, They could not live. But although They no longer lived, They would never really die. They all lay in stone houses in Their great city of R'lyeh, preserved by the spells of mighty Cthulhu for a glorious resurrection when the stars and the earth might once more be ready for Them.",
   "attributes":[
      {
         "trait_type":"Set",
         "value":"Fear of The Unknown"
      },
      {
         "trait_type":"Rarity",
         "value":"Epic"
      },
      {
         "trait_type":"Exhibition",
         "value":"Don't Buy Rope"
      },
      {
         "trait_type":"Artist",
         "value":"@KikaArtD"
      },
      {
         "trait_type":"Type",
         "value":"Varess"
      },
      {
         "trait_type":"Tag",
         "value":"Lovecraft"
      },
      {
         "display_type":"date",
         "trait_type":"birthday",
         "value":"-2504563200"
      },
      {
         "trait_type":"Max Supply",
         "value": "10"
      }
   ]
})

cards.set(75, {
   "pool":{
      "name":"root",
      "points":1
   },
   "external_url":"https://rope.lol/cards/api/75",
   "image":"https://rope.lol/images/cards/Set_001/cthulhu.mp4",
   "name":"Cthulhu",
   "description":"They were not composed altogether of flesh and blood. They had shape but that shape was not made of matter. When the stars were right, They could plunge from world to world through the sky; but when the stars were wrong, They could not live. But although They no longer lived, They would never really die. They all lay in stone houses in Their great city of R'lyeh, preserved by the spells of mighty Cthulhu for a glorious resurrection when the stars and the earth might once more be ready for Them.",
   "attributes":[
      {
         "trait_type":"Set",
         "value":"Fear of The Unknown"
      },
      {
         "trait_type":"Rarity",
         "value":"Rare"
      },
      {
         "trait_type":"Exhibition",
         "value":"Don't Buy Rope"
      },
      {
         "trait_type":"Artist",
         "value":"@KikaArtD"
      },
      {
         "trait_type":"Type",
         "value":"Varess"
      },
      {
         "trait_type":"Tag",
         "value":"Lovecraft"
      },
      {
         "display_type":"date",
         "trait_type":"birthday",
         "value":"-2504563200"
      },
      {
         "trait_type":"Max Supply",
         "value": "50"
      }
   ]
})

cards.set(76, {
   "pool":{
      "name":"root",
      "points":1
   },
   "external_url":"https://rope.lol/cards/api/76",
   "image":"https://rope.lol/images/cards/Set_001/cthulhu.jpg",
   "name":"Cthulhu",
   "description":"They were not composed altogether of flesh and blood. They had shape but that shape was not made of matter. When the stars were right, They could plunge from world to world through the sky; but when the stars were wrong, They could not live. But although They no longer lived, They would never really die. They all lay in stone houses in Their great city of R'lyeh, preserved by the spells of mighty Cthulhu for a glorious resurrection when the stars and the earth might once more be ready for Them.",
   "attributes":[
      {
         "trait_type":"Set",
         "value":"Fear of The Unknown"
      },
      {
         "trait_type":"Rarity",
         "value":"Uncommon"
      },
      {
         "trait_type":"Exhibition",
         "value":"Don't Buy Rope"
      },
      {
         "trait_type":"Artist",
         "value":"@KikaArtD"
      },
      {
         "trait_type":"Type",
         "value":"Varess"
      },
      {
         "trait_type":"Tag",
         "value":"Lovecraft"
      },
      {
         "display_type":"date",
         "trait_type":"birthday",
         "value":"-2504563200"
      },
      {
         "trait_type":"Max Supply",
         "value": "250"
      }
   ]
})

cards.set(77, {
   "pool":{
      "name":"root",
      "points":1
   },
   "external_url":"https://rope.lol/cards/api/77",
   "image":"https://anonpowered.azureedge.net/anonpowered/Hastur/hastur3d.gif",
   "animation_url":"https://anonpowered.azureedge.net/anonpowered/Hastur/hastur.glb",
   "name":"Hastur",
   "description":"... after stumbling queerly upon the hellish and forbidden book of horrors the two learn, among other hideous things which no sane mortal should know, that this talisman is indeed the nameless Yellow Sign handed down from the accursed cult of Hastur – from primordial Carcosa, whereof the volume treats...",
   "attributes":[
      {
         "trait_type":"Set",
         "value":"Fear of The Unknown"
      },
      {
         "trait_type":"Rarity",
         "value":"Epic"
      },
      {
         "trait_type":"Exhibition",
         "value":"Don't Buy Rope"
      },
      {
         "trait_type":"Artist",
         "value":"@KikaArtD"
      },
      {
         "trait_type":"Type",
         "value":"Varess"
      },
      {
         "trait_type":"Tag",
         "value":"Lovecraft"
      },
      {
         "display_type":"date",
         "trait_type":"birthday",
         "value":"-2504563200"
      },
      {
         "trait_type":"Max Supply",
         "value": "10"
      }
   ]
})

cards.set(78, {
   "pool":{
      "name":"root",
      "points":1
   },
   "external_url":"https://rope.lol/cards/api/78",
  "image":"https://rope.lol/images/cards/Set_001/hastur.mp4",
   "name":"Hastur",
   "description":"... after stumbling queerly upon the hellish and forbidden book of horrors the two learn, among other hideous things which no sane mortal should know, that this talisman is indeed the nameless Yellow Sign handed down from the accursed cult of Hastur – from primordial Carcosa, whereof the volume treats...",
   "attributes":[
      {
         "trait_type":"Set",
         "value":"Fear of The Unknown"
      },
      {
         "trait_type":"Rarity",
         "value":"Rare"
      },
      {
         "trait_type":"Exhibition",
         "value":"Don't Buy Rope"
      },
      {
         "trait_type":"Artist",
         "value":"@KikaArtD"
      },
      {
         "trait_type":"Type",
         "value":"Varess"
      },
      {
         "trait_type":"Tag",
         "value":"Lovecraft"
      },
      {
         "display_type":"date",
         "trait_type":"birthday",
         "value":"-2504563200"
      },
      {
         "trait_type":"Max Supply",
         "value": "50"
      }
   ]
})

cards.set(79, {
   "pool":{
      "name":"root",
      "points":1
   },
   "external_url":"https://rope.lol/cards/api/79",
   "image":"https://rope.lol/images/cards/Set_001/hastur.jpg",
   "name":"Hastur",
   "description":"... after stumbling queerly upon the hellish and forbidden book of horrors the two learn, among other hideous things which no sane mortal should know, that this talisman is indeed the nameless Yellow Sign handed down from the accursed cult of Hastur – from primordial Carcosa, whereof the volume treats...",
   "attributes":[
      {
         "trait_type":"Set",
         "value":"Fear of The Unknown"
      },
      {
         "trait_type":"Rarity",
         "value":"Uncommon"
      },
      {
         "trait_type":"Exhibition",
         "value":"Don't Buy Rope"
      },
      {
         "trait_type":"Artist",
         "value":"@KikaArtD"
      },
      {
         "trait_type":"Type",
         "value":"Varess"
      },
      {
         "trait_type":"Tag",
         "value":"Lovecraft"
      },
      {
         "display_type":"date",
         "trait_type":"birthday",
         "value":"-2504563200"
      },
      {
         "trait_type":"Max Supply",
         "value": "250"
      }
   ]
})

cards.set(80, {
   "pool":{
      "name":"root",
      "points":1
   },
   "external_url":"https://rope.lol/cards/api/80",
   "image":"https://anonpowered.azureedge.net/anonpowered/Nya/nya3d.gif",
   "animation_url":"https://anonpowered.azureedge.net/anonpowered/Nya/nya.glb",
   "name":"Nyarlathotep",
   "description":"And where Nyarlathotep went, rest vanished, for the small hours were rent with the screams of nightmare.",
   "attributes":[
      {
         "trait_type":"Set",
         "value":"Fear of The Unknown"
      },
      {
         "trait_type":"Rarity",
         "value":"Epic"
      },
      {
         "trait_type":"Exhibition",
         "value":"Don't Buy Rope"
      },
      {
         "trait_type":"Artist",
         "value":"@KikaArtD"
      },
      {
         "trait_type":"Type",
         "value":"Varess"
      },
      {
         "trait_type":"Tag",
         "value":"Lovecraft"
      },
      {
         "display_type":"date",
         "trait_type":"birthday",
         "value":"-2504563200"
      },
      {
         "trait_type":"Max Supply",
         "value": "10"
      }
   ]
})

cards.set(81, {
   "pool":{
      "name":"root",
      "points":1
   },
   "external_url":"https://rope.lol/cards/api/81",
   "image":"https://rope.lol/images/cards/Set_001/nya.mp4",
   "name":"Nyarlathotep",
   "description":"And where Nyarlathotep went, rest vanished, for the small hours were rent with the screams of nightmare.",
   "attributes":[
      {
         "trait_type":"Set",
         "value":"Fear of The Unknown"
      },
      {
         "trait_type":"Rarity",
         "value":"Rare"
      },
      {
         "trait_type":"Exhibition",
         "value":"Don't Buy Rope"
      },
      {
         "trait_type":"Artist",
         "value":"@KikaArtD"
      },
      {
         "trait_type":"Type",
         "value":"Varess"
      },
      {
         "trait_type":"Tag",
         "value":"Lovecraft"
      },
      {
         "display_type":"date",
         "trait_type":"birthday",
         "value":"-2504563200"
      },
      {
         "trait_type":"Max Supply",
         "value": "50"
      }
   ]
})

cards.set(82, {
   "pool":{
      "name":"root",
      "points":1
   },
   "external_url":"https://rope.lol/cards/api/82",
   "image":"https://rope.lol/images/cards/Set_001/nya.jpg",
   "name":"Nyarlathotep",
   "description":"And where Nyarlathotep went, rest vanished, for the small hours were rent with the screams of nightmare.",
   "attributes":[
      {
         "trait_type":"Set",
         "value":"Fear of The Unknown"
      },
      {
         "trait_type":"Rarity",
         "value":"Uncommon"
      },
      {
         "trait_type":"Exhibition",
         "value":"Don't Buy Rope"
      },
      {
         "trait_type":"Artist",
         "value":"@KikaArtD"
      },
      {
         "trait_type":"Type",
         "value":"Varess"
      },
      {
         "trait_type":"Tag",
         "value":"Lovecraft"
      },
      {
         "display_type":"date",
         "trait_type":"birthday",
         "value":"-2504563200"
      },
      {
         "trait_type":"Max Supply",
         "value": "250"
      }
   ]
})

cards.set(83, {
   "pool":{
      "name":"root",
      "points":1
   },
   "external_url":"https://rope.lol/cards/api/83",
   "image":"https://anonpowered.azureedge.net/anonpowered/Yig/yig3d.gif",
   "animation_url":"https://anonpowered.azureedge.net/anonpowered/Yig/yig.glb",
   "name":"Yig",
   "description":"Yig was a great god. He was bad medicine. He did not forget things. In the autumn his children were hungry and wild, and Yig was hungry and wild, too.",
   "attributes":[
      {
         "trait_type":"Set",
         "value":"Fear of The Unknown"
      },
      {
         "trait_type":"Rarity",
         "value":"Epic"
      },
      {
         "trait_type":"Exhibition",
         "value":"Don't Buy Rope"
      },
      {
         "trait_type":"Artist",
         "value":"@KikaArtD"
      },
      {
         "trait_type":"Type",
         "value":"Varess"
      },
      {
         "trait_type":"Tag",
         "value":"Lovecraft"
      },
      {
         "display_type":"date",
         "trait_type":"birthday",
         "value":"-2504563200"
      },
      {
         "trait_type":"Max Supply",
         "value": "10"
      }
   ]
})

cards.set(84, {
   "pool":{
      "name":"root",
      "points":1
   },
   "external_url":"https://rope.lol/cards/api/84",
   "image":"https://rope.lol/images/cards/Set_001/yig.mp4",
   "name":"Yig",
   "description":"Yig was a great god. He was bad medicine. He did not forget things. In the autumn his children were hungry and wild, and Yig was hungry and wild, too.",
   "attributes":[
      {
         "trait_type":"Set",
         "value":"Fear of The Unknown"
      },
      {
         "trait_type":"Rarity",
         "value":"Rare"
      },
      {
         "trait_type":"Exhibition",
         "value":"Don't Buy Rope"
      },
      {
         "trait_type":"Artist",
         "value":"@KikaArtD"
      },
      {
         "trait_type":"Type",
         "value":"Varess"
      },
      {
         "trait_type":"Tag",
         "value":"Lovecraft"
      },
      {
         "display_type":"date",
         "trait_type":"birthday",
         "value":"-2504563200"
      },
      {
         "trait_type":"Max Supply",
         "value": "50"
      }
   ]
})

cards.set(85, {
   "pool":{
      "name":"root",
      "points":1
   },
   "external_url":"https://rope.lol/cards/api/85",
   "image":"https://rope.lol/images/cards/Set_001/yig.jpg",
   "name":"Yig",
   "description":"Yig was a great god. He was bad medicine. He did not forget things. In the autumn his children were hungry and wild, and Yig was hungry and wild, too.",
   "attributes":[
      {
         "trait_type":"Set",
         "value":"Fear of The Unknown"
      },
      {
         "trait_type":"Rarity",
         "value":"Uncommon"
      },
      {
         "trait_type":"Exhibition",
         "value":"Don't Buy Rope"
      },
      {
         "trait_type":"Artist",
         "value":"@KikaArtD"
      },
      {
         "trait_type":"Type",
         "value":"Varess"
      },
      {
         "trait_type":"Tag",
         "value":"Lovecraft"
      },
      {
         "display_type":"date",
         "trait_type":"birthday",
         "value":"-2504563200"
      },
      {
         "trait_type":"Max Supply",
         "value": "250"
      }
   ]
})

cards.set(86, {
   "pool":{
      "name":"root",
      "points":1
   },
   "external_url":"https://rope.lol/cards/api/86",
   "image":"https://anonpowered.azureedge.net/anonpowered/Yog/yog3d.gif",
   "animation_url":"https://anonpowered.azureedge.net/anonpowered/Yog/yog.glb",
   "name":"Yog-Sothoth",
   "description":"Yog-Sothoth knows the gate. Yog-Sothoth is the gate. Yog-Sothoth is the key and guardian of the gate. Past, present, future, all are one in Yog-Sothoth. He knows where the Old Ones broke through of old, and where They shall break through again. He knows where They have trod earth's fields, and where They still tread them, and why no one can behold Them as They tread.",
   "attributes":[
      {
         "trait_type":"Set",
         "value":"Fear of The Unknown"
      },
      {
         "trait_type":"Rarity",
         "value":"Epic"
      },
      {
         "trait_type":"Exhibition",
         "value":"Don't Buy Rope"
      },
      {
         "trait_type":"Artist",
         "value":"@KikaArtD"
      },
      {
         "trait_type":"Type",
         "value":"Varess"
      },
      {
         "trait_type":"Tag",
         "value":"Lovecraft"
      },
      {
         "display_type":"date",
         "trait_type":"birthday",
         "value":"-2504563200"
      },
      {
         "trait_type":"Max Supply",
         "value": "10"
      }
   ]
})

cards.set(87, {
   "pool":{
      "name":"root",
      "points":1
   },
   "external_url":"https://rope.lol/cards/api/87",
   "image":"https://rope.lol/images/cards/Set_001/yog.mp4",
   "name":"Yog-Sothoth",
   "description":"Yog-Sothoth knows the gate. Yog-Sothoth is the gate. Yog-Sothoth is the key and guardian of the gate. Past, present, future, all are one in Yog-Sothoth. He knows where the Old Ones broke through of old, and where They shall break through again. He knows where They have trod earth's fields, and where They still tread them, and why no one can behold Them as They tread.",
   "attributes":[
      {
         "trait_type":"Set",
         "value":"Fear of The Unknown"
      },
      {
         "trait_type":"Rarity",
         "value":"Rare"
      },
      {
         "trait_type":"Exhibition",
         "value":"Don't Buy Rope"
      },
      {
         "trait_type":"Artist",
         "value":"@KikaArtD"
      },
      {
         "trait_type":"Type",
         "value":"Varess"
      },
      {
         "trait_type":"Tag",
         "value":"Lovecraft"
      },
      {
         "display_type":"date",
         "trait_type":"birthday",
         "value":"-2504563200"
      },
      {
         "trait_type":"Max Supply",
         "value": "50"
      }
   ]
})

cards.set(88, {
   "pool":{
      "name":"root",
      "points":1
   },
   "external_url":"https://rope.lol/cards/api/88",
   "image":"https://rope.lol/images/cards/Set_001/yog.jpg",
   "name":"Yog-Sothoth",
   "description":"Yog-Sothoth knows the gate. Yog-Sothoth is the gate. Yog-Sothoth is the key and guardian of the gate. Past, present, future, all are one in Yog-Sothoth. He knows where the Old Ones broke through of old, and where They shall break through again. He knows where They have trod earth's fields, and where They still tread them, and why no one can behold Them as They tread.",
   "attributes":[
      {
         "trait_type":"Set",
         "value":"Fear of The Unknown"
      },
      {
         "trait_type":"Rarity",
         "value":"Uncommon"
      },
      {
         "trait_type":"Exhibition",
         "value":"Don't Buy Rope"
      },
      {
         "trait_type":"Artist",
         "value":"@KikaArtD"
      },
      {
         "trait_type":"Type",
         "value":"Varess"
      },
      {
         "trait_type":"Tag",
         "value":"Lovecraft"
      },
      {
         "display_type":"date",
         "trait_type":"birthday",
         "value":"-2504563200"
      },
      {
         "trait_type":"Max Supply",
         "value": "250"
      }
   ]
}
)

cards.set(89, {
    "pool":{
      "name":"root",
      "points":1
    },
    "external_url":"https://rope.lol/cards/api/89",
    "image":"https://rope.lol/images/cards/Diamond_Hands/uncommon.jpg",
    "name":"Diamond Hands - Uncommon",
    "description":"",
    "attributes":[
      {
        "trait_type":"Set",
        "value":"Diamond Hands"
      },
      {
        "trait_type":"Rarity",
        "value":"Uncommon"
      },
      {
        "trait_type":"Exhibition",
        "value":"Don't Buy Rope"
      },
      {
        "trait_type":"Artist",
        "value":"@KikaArtD"
      },
      {
        "trait_type":"Type",
        "value":"Varess"
      },
      {
        "trait_type":"Tag",
        "value":"Premia"
      },
      {
        "display_type":"date",
        "trait_type":"birthday",
        "value":"1612254689"
      },
      {
        "trait_type":"Max Supply",
        "value": "641"
      }
    ]
  }

)

cards.set(90, {
    "pool":{
      "name":"root",
      "points":1
    },
    "external_url":"https://rope.lol/cards/api/90",
    "image":"https://rope.lol/images/cards/Diamond_Hands/rare.gif",
    "name":"Diamond Hands - Rare",
    "description":"",
    "attributes":[
      {
        "trait_type":"Set",
        "value":"Diamond Hands"
      },
      {
        "trait_type":"Rarity",
        "value":"Rare"
      },
      {
        "trait_type":"Exhibition",
        "value":"Don't Buy Rope"
      },
      {
        "trait_type":"Artist",
        "value":"@KikaArtD"
      },
      {
        "trait_type":"Type",
        "value":"Varess"
      },
      {
        "trait_type":"Tag",
        "value":"Premia"
      },
      {
        "display_type":"date",
        "trait_type":"birthday",
        "value":"1612254689"
      },
      {
        "trait_type":"Max Supply",
        "value": "30"
      }
    ]
  }
)

const seasonOneUncommonHopePrice = 300;
const seasonOneRareHopePrice = 900;
const setOneUncommonHopePrice = 8000;
const setOneRareHopePrice = 40000;
const setOneEpicHopePrice = 200000;
const charityEthPrice = 1;

cardsExtraData.set(2, {hopePrice: seasonOneUncommonHopePrice });
cardsExtraData.set(3, {hopePrice: seasonOneRareHopePrice});
cardsExtraData.set(4, {hopePrice: seasonOneUncommonHopePrice});
cardsExtraData.set(5, {hopePrice: seasonOneRareHopePrice});
cardsExtraData.set(6, {hopePrice: seasonOneUncommonHopePrice});
cardsExtraData.set(7, {hopePrice: seasonOneRareHopePrice});
cardsExtraData.set(8, {hopePrice: seasonOneUncommonHopePrice});
cardsExtraData.set(9, {hopePrice: seasonOneRareHopePrice});
cardsExtraData.set(10, {hopePrice: seasonOneUncommonHopePrice});
cardsExtraData.set(11, {hopePrice: seasonOneRareHopePrice});
cardsExtraData.set(12, {hopePrice: seasonOneUncommonHopePrice});
cardsExtraData.set(13, {hopePrice: seasonOneRareHopePrice});
cardsExtraData.set(14, {hopePrice: seasonOneUncommonHopePrice});
cardsExtraData.set(15, {hopePrice: seasonOneRareHopePrice});
cardsExtraData.set(16, {hopePrice: seasonOneUncommonHopePrice});
cardsExtraData.set(17, {hopePrice: seasonOneRareHopePrice});
cardsExtraData.set(18, {hopePrice: seasonOneUncommonHopePrice});
cardsExtraData.set(19, {hopePrice: seasonOneRareHopePrice});
cardsExtraData.set(20, {hopePrice: seasonOneUncommonHopePrice});
cardsExtraData.set(21, {hopePrice: seasonOneRareHopePrice});
cardsExtraData.set(22, {ethPrice: charityEthPrice});
cardsExtraData.set(23, {ethPrice: charityEthPrice});
cardsExtraData.set(24, { ethPrice: charityEthPrice });
cardsExtraData.set(73, { ethPrice: charityEthPrice });
cardsExtraData.set(74, {hopePrice: setOneEpicHopePrice});
cardsExtraData.set(75, {hopePrice: setOneRareHopePrice});
cardsExtraData.set(76, {hopePrice: setOneUncommonHopePrice});
cardsExtraData.set(77, {hopePrice: setOneEpicHopePrice});
cardsExtraData.set(78, {hopePrice: setOneRareHopePrice});
cardsExtraData.set(79, {hopePrice: setOneUncommonHopePrice});
cardsExtraData.set(80, {hopePrice: setOneEpicHopePrice});
cardsExtraData.set(81, {hopePrice: setOneRareHopePrice});
cardsExtraData.set(82, {hopePrice: setOneUncommonHopePrice});
cardsExtraData.set(83, {hopePrice: setOneEpicHopePrice});
cardsExtraData.set(84, {hopePrice: setOneRareHopePrice});
cardsExtraData.set(85, {hopePrice: setOneUncommonHopePrice});
cardsExtraData.set(86, {hopePrice: setOneEpicHopePrice});
cardsExtraData.set(87, {hopePrice: setOneRareHopePrice});
cardsExtraData.set(88, {hopePrice: setOneUncommonHopePrice});

export function getCardSetSize(cardSetId: number) {
  switch (Number(cardSetId)) {
    case 1:
    case 2:
      return 10;
    case 3:
      return 3;
    case 4:
      return 4;
    case 5:
      return 17;
    case 6:
      return 5;
    case 7:
      return 5;
    case 8:
      return 6;
    case 9:
      return 4;
    case 10:
      return 1;
    case 11:
      return 4;
    case 12:
      return 2;
   case 13:
   case 14:
     return 5;
   case 15:
   case 16:
   case 17:
   case 18:
   case 19:
     return 5;
   case 20:
      return 1;
    default:
      return '∞';
  }
};