import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import useRope from 'hooks/useRope';

import AccountButton from './components/AccountButton';
import AnnouncementBanner from 'components/AnnouncementBanner';

const ThickLink = styled.div`
  font-weight: 600;
  white-space: nowrap;
  cursor: pointer;
`;

const GreyText = styled.span`
  color: ${({ theme }) => theme.color.grey[500]};
  white-space: nowrap;
`;

const PinkText = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.color.pink[200]};
`;

const PurpleText = styled.span`
  font-weight: 600;
  color: ${({ theme }) => theme.color.purple[400]};
`;

const Header = () => {
  const { account, ropeBalance, hopeBalance, hopeV2Balance } = useRope();

  return (
    <div className="flex flex-col w-screen">
      <AnnouncementBanner />

      <div className="flex flex-column w-full">
        <header className="visible sm:invisible sm:hidden w-full mb-10 px-20">
          <Link to="/" className="object-contain mt-5">
            <img src="/images/ROPE_Logo_Pink.png" alt="rope" />
          </Link>

          <div className="flex flex-column mt-10 w-full">
            <AccountButton className="w-full" />
          </div>

          <div className="flex flex-column mt-10 justify-center">
            {account && (
              <Link
                to="/"
                component={ThickLink}
                className="pr-10 text-sm hover:underline"
              >
                <i className="fas fa-tractor pr-2" /> farms
              </Link>
            )}

            {account && (
              <Link to="/deck" component={ThickLink} className="text-sm">
                <i className="fas fa-layer-group pr-2" /> my deck
              </Link>
            )}

            {account && <GreyText className="px-10">|</GreyText>}

            {ropeBalance !== '' && (
              <GreyText className="font-sans text-sm">
                <PinkText>
                  {Number(ropeBalance).toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                  })}
                </PinkText>{' '}
                $ROPE
              </GreyText>
            )}

            <div className="flex flex-col items-end">
              {hopeBalance !== '' && (
                <GreyText className="font-sans text-sm pl-10">
                  <PinkText>
                    {Number(hopeBalance).toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}
                  </PinkText>{' '}
                  HOPE
                </GreyText>
              )}

              {hopeV2Balance !== '' && (
                <GreyText className="font-sans text-sm pl-10">
                  <PurpleText>
                    {Number(hopeV2Balance).toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}
                  </PurpleText>{' '}
                  HOPE v2
                </GreyText>
              )}
            </div>
          </div>
        </header>

        <header className="hidden invisible container mx-auto sm:flex sm:visible w-full justify-between mb-10 px-8 md:px-16 lg:px-32">
          <Link to="/" className="mt-4">
            <img
              src="/images/ROPE_Logo_Pink.png"
              alt="rope"
              height="auto"
              width={120}
            />
          </Link>

          <div
            className={`flex flex-column mt-5 w-1/2 sm:w-2/3 items-center ${
              account ? 'justify-between' : 'justify-end'
            }`}
          >
            <AccountButton balance={ropeBalance} />

            {account && (
              <Link to="/" className="pl-5 text-sm hover:underline">
                <ThickLink>
                  <i className="fas fa-tractor pr-2" /> farms
                </ThickLink>
              </Link>
            )}

            {account && (
              <Link to="/deck" className="pl-5 text-sm hover:underline">
                <ThickLink>
                  <i className="fas fa-layer-group pr-2" /> my deck
                </ThickLink>
              </Link>
            )}

            {account && <GreyText className="pl-5">|</GreyText>}

            {ropeBalance !== '' && (
              <GreyText className="font-sans text-sm pl-5">
                <PinkText>
                  {Number(ropeBalance).toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                  })}
                </PinkText>{' '}
                $ROPE
              </GreyText>
            )}

            <div className="flex flex-col items-end">
              {hopeBalance !== '' && (
                <GreyText className="font-sans text-sm pl-10">
                  <PinkText>
                    {Number(hopeBalance).toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}
                  </PinkText>{' '}
                  HOPE
                </GreyText>
              )}

              {hopeV2Balance !== '' && (
                <GreyText className="font-sans text-sm pl-10">
                  <PurpleText>
                    {Number(hopeV2Balance).toLocaleString('en-US', {
                      maximumFractionDigits: 2,
                    })}
                  </PurpleText>{' '}
                  HOPE v2
                </GreyText>
              )}
            </div>
          </div>
        </header>
      </div>
    </div>
  );
};

export default Header;
