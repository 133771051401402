import { useState, useEffect } from 'react';

import { getRaffleTicketsHeld, getCurrentRaffleNftId, getPreviousRaffleWinner, purchaseRaffleTickets, settleHopeRaffle } from 'rope/utils';

import useRope from './useRope';

const useRaffle = ({ raffleId }: { raffleId: number | string }) => {
  const { rope, account } = useRope();

  const [ticketsHeld, setTicketsHeld] = useState('0');
  const [currentNftId, setCurrentNft] = useState(-1);
  const [previousWinner, setPreviousWinner] = useState(undefined);

  useEffect(() => {
    if (rope && account) {
      (async () => {
        const ticketsHeld = await getRaffleTicketsHeld(rope, account, raffleId);
        setTicketsHeld(ticketsHeld);
      })();
    }
  }, [rope, account, raffleId, setTicketsHeld]);

  useEffect(() => {
    if (rope) {
      (async () => {
        const currentNftId = await getCurrentRaffleNftId(rope, raffleId);
        setCurrentNft(currentNftId);
      })();
    }
  }, [rope, raffleId, setCurrentNft]);

  useEffect(() => {
    if (rope) {
      (async () => {
        const previousWinner = await getPreviousRaffleWinner(rope, raffleId);
        setPreviousWinner(previousWinner);
      })();
    }
  }, [rope, raffleId, setCurrentNft]);

  const purchaseTickets = async (amount: string, useNonTradeableHope: boolean = false) => {
    await purchaseRaffleTickets(rope, account, raffleId, amount, useNonTradeableHope);

    const ticketsHeld = await getRaffleTicketsHeld(rope, account, raffleId);
    setTicketsHeld(ticketsHeld);
  }

  const settleRaffle = async () => {
    await settleHopeRaffle(rope, account, raffleId);

    const currentNftId = await getCurrentRaffleNftId(rope, raffleId);
    setCurrentNft(currentNftId);
    setTicketsHeld('0');
  }

  return { currentNftId, ticketsHeld, previousWinner, purchaseTickets, settleRaffle };
}

export default useRaffle;

