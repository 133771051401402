import { useContext } from 'react'

import { Context } from 'contexts/NftCardProvider'

const useNftCards = () => {
  const nftCardContext = useContext(Context)
  return nftCardContext
}

export default useNftCards
