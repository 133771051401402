import React from 'react';
import styled from 'styled-components';

interface CardContentProps {
  children: any;
  style?: object;
  className?: string;
  onClick?: () => void;
}

const CardContent: React.FC<CardContentProps> = ({
  children,
  style,
  className,
  onClick,
}) => (
  <StyledCardContent style={style} className={className} onClick={onClick}>
    {children}
  </StyledCardContent>
);

const StyledCardContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: ${({ theme }) => theme.spacing[3]}px;
`;

export default CardContent;
