import React, { useEffect } from 'react';
import styled from 'styled-components';

import useModal from 'hooks/useModal';
import useRope from 'hooks/useRope';

import LearnMoreModal from './components/LearnMoreModal';

const PinkTag = styled.div`
  padding: 2px 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 5px;
  font-weight: 200;
  display: flex;
  flex: 0;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.pink[400]};
  color: ${({ theme }) => theme.color.white};
`;

const PinkAnchor = styled.a`
  color: ${({ theme }) => theme.color.pink[400]};
`;

const HopeBoosterMultiplier: React.FC = () => {
  const { hopeBoosterMultiplier } = useRope();

  const [onShowLearnMore] = useModal(<LearnMoreModal />);

  const localStorageKey = 'hasShownHopeBoosterInfo';
  const hasShown = localStorage.getItem(localStorageKey);

  useEffect(() => {
    if (!hasShown) {
      localStorage.setItem(localStorageKey, 'true');
      onShowLearnMore();
    }
  }, [hasShown, onShowLearnMore]);

  return (
    <div className="flex flex-col sm:flex-row items-end sm:items-start">
      <PinkAnchor
        className="mr-0 sm:mr-4 mb-4 sm:mb-0 cursor-pointer underline"
        onClick={onShowLearnMore}
      >
        Learn More
      </PinkAnchor>

      <PinkTag style={{ height: 30 }}>
        <span className="whitespace-no-wrap">
          <b>Current</b> <span>Multiplier:</span>
        </span>

        <b className="ml-16">
          {hopeBoosterMultiplier === '0'
            ? '1'
            : Number(hopeBoosterMultiplier).toLocaleString('en-US', {
                maximumFractionDigits: 2,
              })}
          X
        </b>
      </PinkTag>
    </div>
  );
};

export default HopeBoosterMultiplier;
