import { useState, useEffect } from 'react';

import { getRopeApy, getRopeEthLpApy, getHopeEthLpApy } from 'rope/utils';

import useRope from './useRope';

const useRaffle = () => {
  const { rope } = useRope();

  const [ropeApy, setRopeApy] = useState('0');
  const [ropeEthLpApy, setRopeEthLpApy] = useState('0');
  const [hopeEthLpApy, setHopeEthLpApy] = useState('0');

  useEffect(() => {
    if (rope) {
      (async () => {
        const ropeApy = await getRopeApy(rope);
        setRopeApy(ropeApy);

        const ropeEthLpApy = await getRopeEthLpApy(rope);
        setRopeEthLpApy(ropeEthLpApy);

        const hopeEthLpApy = await getHopeEthLpApy(rope);
        setHopeEthLpApy(hopeEthLpApy);
      })();
    }
  }, [rope, setRopeApy]);

  return { ropeApy, ropeEthLpApy, hopeEthLpApy };
}

export default useRaffle;

