import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import useRope from 'hooks/useRope';
import { getCardsOwned } from 'rope/utils';

import Header from 'components/Header';
import Footer from 'components/Footer';
import NavigationPills from 'components/NavigationPills';
import BackgroundCover from 'components/BackgroundCover';
import NftCard from 'components/NftCard';
import Button from 'components/Button';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import StakeHeader from './components/StakeHeader';

const DeckPage: React.FC = () => {
  const [cardsOwned, setCardsOwned] = useState<number[]>([]);
  const [selectedCards, setSelectedCards] = useState<number[]>([]);

  const { rope, account } = useRope();

  const selectCard = (id: number) => {
    setSelectedCards(Array.from(new Set([...selectedCards, id])));
  };

  const deselectCard = (id: number) => {
    setSelectedCards(selectedCards.filter((_id: number) => id !== _id));
  };

  const selectAll = () => {
    if (selectedCards.length === 0) {
      setSelectedCards(cardsOwned.slice());
    } else {
      setSelectedCards([]);
    }
  };

  useEffect(() => {
    let isSubscribed = true;

    if (!rope || !account) return;

    getCardsOwned(rope, account).then((cardsOwned) => {
      if (!isSubscribed) return;

      setCardsOwned(cardsOwned);
    });

    return () => {
      isSubscribed = false;
    };
  }, [rope, account]);

  return (
    <div className="flex flex-col w-screen min-h-screen items-center">
      <BackgroundCover />

      <Header />

      <NavigationPills />

      <div className="container mx-auto w-full mt-8 px-4 sm:px-8 md:px-16 lg:px-32">
        <Card flatVariant className="pt-2 pb-16 mb-16 px-8">
          <CardContent>
            <div className="flex flex-col sm:flex-row w-full">
              <div className="flex flex-col w-full xl:w-1/4">
                <div className="text-lg font-semibold mb-4">Your Deck</div>

                <div className="text-lg font-semibold mb-4 whitespace-no-wrap">
                  Select NFTs to Stake
                </div>
              </div>

              <div className="flex flex-col w-full ml-0 md:ml-5 xl:ml-0 xl:w-3/4 items-end mb-10">
                <StakeHeader
                  selectedCards={selectedCards}
                  onSelectAll={selectAll}
                />

                <Link to="/" className="text-sm font-semibold">
                  <i className="fas fa-arrow-left mt-4 mr-2" />{' '}
                  <span className="underline">view farms</span>
                </Link>
              </div>
            </div>

            <div className="flex flex-wrap justify-around">
              {cardsOwned.map((cardId) => {
                const selected = selectedCards.includes(cardId);

                return (
                  <div
                    key={cardId}
                    className={`mt-24 mb-10 w-full md:w-2/5 lg:w-3/10 lg:-mr-8`}
                  >
                    <NftCard
                      deckVariant
                      selected={selected}
                      charityVariant={[22, 23, 24].includes(cardId)}
                      id={cardId}
                      onSelect={
                        selected
                          ? () => deselectCard(cardId)
                          : () => selectCard(cardId)
                      }
                    />
                  </div>
                );
              })}

              {cardsOwned.length < 1 && (
                <div className="flex flex-col mx-8 my-16">
                  <div className="text-lg text-black font-semibold mb-4">
                    Anon. You do not own any NFTs.
                  </div>
                  <Button text="Start Farming" size="sm" to="/varess" />
                </div>
              )}
            </div>
          </CardContent>

          <Footer />
        </Card>
      </div>
    </div>
  );
};

export default DeckPage;
