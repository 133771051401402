import React from 'react';

import useRope from 'hooks/useRope';

import Card from 'components/Card';

const SectionArea: React.FC<any> = ({ title, amount, currency = false }) => {
  return (
    <div
      className="flex flex-col justify-center text-center px-4 py-2"
      style={{ minWidth: 120 }}
    >
      <span className="text-sm xl:text-base font-thin mb-1">{title}</span>
      {currency ? '$' : ''}
      {amount}
    </div>
  );
};

interface RopeStatsProps {
  flex?: number;
  minWidth?: number;
  noMargin?: boolean;
}

const RopeStats: React.FC<RopeStatsProps> = ({
  flex = 3,
  minWidth = 250,
  noMargin = false,
}) => {
  const {
    ropePrice,
    totalValueLocked,
    totalHopeSupply,
  } = useRope();

  return (
    <Card
      flatVariant
      className={`py-2 px-8 w-full md:w-4/5 mr-0 ${
        noMargin ? '' : 'sm:mr-4'
      } mt-8`}
      style={{ flex, minWidth }}
    >
      <div className="flex flex-row flex-wrap h-full items-around justify-around font-semibold">
        <SectionArea
          currency
          title="Current $ROPE Price"
          amount={`${ropePrice}`}
        />

        <SectionArea
          currency
          title="Total Value Locked (TVL)"
          amount={
            totalValueLocked === '0'
              ? 'TOO_MUCH'
              : (
                  Number(totalValueLocked)
                ).toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })
          }
        />

        <SectionArea title="Total $ROPE Supply" amount="28,000" />

        {/* <SectionArea
          title="Total $ROPE Farmed"
          amount={Number(totalRopeYieldEarned).toLocaleString('en-US', {
            maximumFractionDigits: 4,
          })}
        /> */}

        <SectionArea
          title="Total Circulating Hope"
          amount={Number(totalHopeSupply).toLocaleString('en-US', {
            maximumFractionDigits: 2,
          })}
        />
      </div>
    </Card>
  );
};

export default RopeStats;
