import React, { useState } from 'react';

import { redeemHopeForCardV2 } from 'rope/utils';

import useRope from 'hooks/useRope';

import Button from 'components/Button';
import Modal from 'components/Modal';
import ModalContent from 'components/ModalContent';
import Spacer from 'components/Spacer';

const PurchaseNftModal: React.FC<any> = ({
  cardId,
  purchasePrice,
  onDismiss,
}) => {
  const { rope, account, hopeBalance } = useRope();
  const [hopeAmount, setHopeAmount] = useState('0');

  const maxTicketBalance = Number(hopeBalance) / Number(purchasePrice);

  const onChangeAmount = (event: any) =>
    setHopeAmount(String(event.target.value).replace(/[^\d]/g, ''));

  const redeemHope = async (amount: string, useNonTradeableHope = false) =>
    redeemHopeForCardV2(
      rope,
      account,
      cardId,
      (Number(amount) >= Number(maxTicketBalance)
        ? maxTicketBalance
        : amount
      ).toString(),
      useNonTradeableHope
    ).then(onDismiss);

  return (
    <Modal>
      <ModalContent>
        <div className="flex flex-col w-full justify-center items-center px-12">
          <div className="flex flex-row items-center mb-4">
            <img
              width="40"
              height="40"
              src="/images/HopeTokenv3.gif"
              alt="rope_asset"
            />
          </div>

          <div className="text-md font-semibold">
            Please enter a number of cards to purchase.
          </div>

          <Spacer />

          <div className="relative overflow-x-scroll">
            <span
              className="absolute top-0 left-0 mt-2 ml-2 text-sm font-semibold cursor-pointer text-black"
              onClick={() =>
                onChangeAmount({ target: { value: maxTicketBalance } })
              }
            >
              max
            </span>

            <input
              type="number"
              value={hopeAmount}
              className="text-right border border-t-0 border-r-0 border-l-0 border-b-1 bg-transparent border-black border-solid xl:mr-2 pl-10 pr-2 py-1 font-semibold"
              onChange={onChangeAmount}
            />
          </div>
        </div>
      </ModalContent>

      <div className="flex flex-row w-full">
        <Button variant="secondary" text="Cancel" onClick={onDismiss} />

        <Button
          text="Purchase w/ HOPE"
          onClick={() => redeemHope(hopeAmount, true)}
        />

        <Button
          variant="pink"
          text="Purchase w/ HOPE v2"
          onClick={() => redeemHope(hopeAmount, false)}
        />
      </div>
    </Modal>
  );
};

export default PurchaseNftModal;
