export const white = '#FFF'
export const black = '#000'

export const offWhite = '#FAFAFA'
export const offBlack = '#0A0A0A'

export const pink = {
  100: '#FC73FF',
  200: '#D024C2',
  400: '#EB03FF',
};

export const purple = {
  100: '#5d54a4',
  400: '#590995',
};

export const blue = {
  400: '#30A8FF',
};

export const green = {
  500: '##00d1810',
}

export const red = {
  100: '#FFFDFE',
  200: '#ffc2a8',
  400: '#FF3C3C',
  500: '#d16c00',
}

export const grey = {
  100: '#f7f4f2',
  200: '#f0e9e7',
  300: '#eaeaea',
  400: '#aa9585',
  500: '#805e49',
  600: '#5b3926',
  800: '#2d1e12',
}
