import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

interface CardRevealProps {
  children: any;
  preview: any;
  className?: string;
  style?: object;
  flipped?: boolean;
}

const CardReveal: React.FC<CardRevealProps> = ({
  children,
  preview,
  className,
  style,
}) => {
  const [flipped, setFlipped] = useState(false);

  useEffect(() => {
    if (!flipped) {
      requestAnimationFrame(() => setFlipped(true));
    }
  }, [flipped]);

  return (
    <StyledCardReveal
      style={style}
      className={className}
      preview={preview}
      flipped={flipped}
    >
      <Preview flipped={flipped}>{preview}</Preview>

      <BacksideReveal flipped={flipped}>{children}</BacksideReveal>

      <PartyReveal flipped={flipped}>
        <img className="w-full" src="/images/confetti.gif" alt="confetti" />
      </PartyReveal>
    </StyledCardReveal>
  );
};

const PartyReveal = styled.div<any>`
  width: 150%;
  height: 150%;
  position: absolute;
  top: -25%;
  right: -25%;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  transition: all 6s;
  z-index: 0;
  ${({ flipped }) => (flipped ? 'opacity: 0;' : 'opacity: 1;')}
`;

const BacksideReveal = styled.div<any>`
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  transform: rotateY(180deg);
  transition: all 6s;
  ${({ flipped }) => (flipped ? 'opacity: 1;' : 'opacity: 0;')}
`;

const Preview = styled.div<any>`
  backface-visibility: hidden;
  transition: all 2s;
  ${({ flipped }) => (flipped ? 'opacity: 0;' : 'opacity: 1;')}
`;

const StyledCardReveal = styled.div<CardRevealProps>`
  background: transparent;
  display: flex;
  flex: 1;
  flex-direction: column;
  cursor: pointer;

  perspective: 1000px;
  position: relative;
  transition: all 3s;
  transform-style: preserve-3d;
  ${({ flipped }) => (flipped ? 'transform: rotateY(180deg);' : '')}
`;

export default CardReveal;
