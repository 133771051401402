import { black, green, grey, red, white, pink, purple, blue, offWhite, offBlack } from './colors'

const theme = {
  borderRadius: 0,
  breakpoints: {
    mobile: 400,
  },
  color: {
    black,
    grey,
    primary: {
      light: red[200],
      main: black,
    },
    secondary: {
      main: green[500],
    },
    red,
    pink,
    purple,
    green,
    blue,
    white,
    offWhite,
    offBlack
  },
  siteWidth: 1200,
  spacing: {
    1: 4,
    2: 8,
    3: 16,
    4: 24,
    5: 32,
    6: 48,
    7: 64,
  },
  topBarSize: 72
}

export default theme