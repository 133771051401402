import React, { createRef, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import web3 from 'web3';

import useModal from 'hooks/useModal';
import useOutsideClick from 'hooks/useOutsideClick';
import useRope from 'hooks/useRope';
import {
  approveStakeSeasonalPool,
  stakeRopeSeasonalPool,
  withdrawRopeSeasonalPool,
} from 'rope/utils';

import Card from 'components/Card';
import CardContent from 'components/CardContent';
import Button from 'components/Button';
import SelectTokenModal from 'components/SelectTokenModal';
import UpgradeHopeModal from 'components/UpgradeHopeModal';

const BlackPill = styled.div`
  cursor: pointer;
  padding: 4px 1rem;
  background-color: ${({ theme }) => theme.color.black};
  color: ${({ theme }) => theme.color.white};
  border-radius: 15px;

  &:hover {
    opacity: 0.75;
  }
`;

export interface StakePoolHeaderProps {
  backLink: string;
  backText: string;
}

const StakePoolHeader: React.FC<StakePoolHeaderProps> = ({
  backLink,
  backText,
}) => {
  const [state, setState] = useState({
    open: false,
    hasShownModal: false,
    stakeMoreAmount: '0',
  });
  const { open, hasShownModal, stakeMoreAmount } = state;

  const {
    account,
    rope,
    maxStakeInPool,
    balance,
    staked,
    allowance,
    hopesPerDayInPool,
    unclaimedHopeInPool,
    poolType,
    poolId,
    setPool,
  } = useRope();

  const [showExchangeHopeModal] = useModal(<UpgradeHopeModal />);
  const [onShowTokenModal] = useModal(<SelectTokenModal />);
  const ref = createRef<HTMLDivElement>();

  let tokenName = '$ROPE';
  let tokenLink =
    'https://app.uniswap.org/#/swap?outputCurrency=0x9d47894f8becb68b9cf3428d256311affe8b068b';

  switch (poolId) {
    case 1:
      tokenName = '$ROPE / ETH Uni-V2 LP';
      tokenLink =
        'https://app.uniswap.org/#/add/ETH/0x9d47894f8becb68b9cf3428d256311affe8b068b';
      break;
    case 4:
      tokenName = '$ROPE / ETH Sushi LP';
      tokenLink =
        'https://exchange.sushiswapclassic.org/#/add/ETH/0x9D47894f8BECB68B9cF3428d256311Affe8B068B';
      break;
    case 5:
      tokenName = 'HOPE / ETH Sushi LP';
      tokenLink =
        'https://exchange.sushiswapclassic.org/#/add/ETH/0x9d4aa5600e0c8c2085bb82d946ca6642742a8250';
      break;
  }

  const maxAdd = String(Number(maxStakeInPool) - Number(staked));
  const maximum = Number(maxAdd) > Number(balance) ? balance : maxAdd;

  const onChangeStakeMoreAmount = (event: any) =>
    setState({
      ...state,
      stakeMoreAmount: Number(event.target.value)
        .toLocaleString(undefined, {
          maximumFractionDigits: 18,
        })
        .replace(/,/g, ''),
    });

  const stakeMore = async (amount: string) => {
    const stakeAmount = poolId === 3 ? String(Number(amount) / 100) : amount;

    return stakeRopeSeasonalPool(
      rope,
      account,
      web3.utils.toWei(
        (Number(stakeAmount) > Number(balance)
          ? balance
          : stakeAmount
        ).toString(),
        'ether'
      ),
      poolId
    );
  };

  const unstake = async (amount: string) => {
    const stakeAmount = poolId === 3 ? String(Number(amount) / 100) : amount;

    return withdrawRopeSeasonalPool(
      rope,
      account,
      web3.utils.toWei(
        (Number(stakeAmount) > Number(staked)
          ? staked
          : stakeAmount
        ).toString(),
        'ether'
      ),
      poolId
    );
  };

  const claimHarvest = async () =>
    withdrawRopeSeasonalPool(rope, account, 0, poolId);

  const approveRope = async () =>
    approveStakeSeasonalPool(rope, account, poolId);

  useEffect(() => {
    if (poolId > 5 || poolType !== 'nft') {
      const favoritePool = Number(localStorage.getItem('favoritePool') || 0);

      setPool?.(favoritePool, 'nft');
    } else {
      localStorage.setItem('favoritePool', String(poolId));
    }
  }, [poolId, poolType, setPool]);

  useEffect(() => {
    if (!hasShownModal) {
      const favoritePool = Number(localStorage.getItem('favoritePool') || -1);

      if (favoritePool === -1) {
        onShowTokenModal();
      }

      setState((s) => ({ ...s, hasShownModal: true }));
    }
  }, [hasShownModal, onShowTokenModal]);

  useOutsideClick(ref, () => setState({ ...state, open: false }));

  return (
    <div
      ref={ref}
      className="relative flex flex-col w-full ml-0 md:ml-5 xl:ml-0 xl:w-2/3 items-end mb-10"
    >
      <div
        className="flex flex-col w-full xl:flex-row"
        onClick={() => {
          if (open) setState({ ...state, open: !open });
        }}
      >
        <Button
          disabled
          fullOpacity
          text={
            <span style={{ whiteSpace: 'nowrap' }}>
              <b>
                {Number(unclaimedHopeInPool).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{' '}
                HOPE
              </b>{' '}
              Unclaimed
            </span>
          }
          size="sm"
          variant="pink"
          className="mr-2"
        />

        <Button
          text={<b>Claim HOPE</b>}
          size="sm"
          variant="pink"
          className="mr-2"
          onClick={claimHarvest}
        />

        <Button
          text={<b>Manage Stake</b>}
          size="sm"
          variant="pink"
          icon={<i className="fas fa-campground mr-2" />}
          onClick={() => setState({ ...state, open: !open })}
        />
      </div>

      <Button
        size="sm"
        variant="purple"
        className="mt-4"
        onClick={showExchangeHopeModal}
      >
        Exchange Non-Tradeable HOPE
      </Button>

      <div className="flex flex-row w-full justify-end items-center mt-4">
        <Link to={backLink} className="text-sm font-semibold mr-6">
          <i className="fas fa-arrow-left mr-2" />{' '}
          <span className="underline">{backText}</span>
        </Link>

        <div
          className="text-sm font-semibold cursor-pointer mr-6"
          onClick={onShowTokenModal}
        >
          <i className="fas fa-coins mr-2" />{' '}
          <span className="underline">change token pool</span>
        </div>

        <BlackPill className="text-sm font-thin" onClick={onShowTokenModal}>
          <span className="whitespace-nowrap">
            <span className="mr-2">Staked:</span>
            <b>
              {Number(
                poolId === 3 ? Number(staked) * 100 : staked
              ).toLocaleString('en-US', {
                maximumFractionDigits: 2,
              })}{' '}
              {tokenName}
            </b>{' '}
          </span>
        </BlackPill>
      </div>

      {open && (
        <Card className="absolute z-20 top-auto right-0 translate-y-full -translate-x-full mt-12 flex flex-col w-full">
          <CardContent>
            <div className="text-lg font-semibold mb-2">
              Deposit{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={tokenLink}
                className="font-semibold hover:underline"
              >
                {tokenName}
              </a>
              , Earn HOPE, Mint solid NFT gold.
            </div>

            <div className="text-sm font-normal mb-4">
              Staking{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={tokenLink}
                className="font-semibold hover:underline"
              >
                {tokenName}
              </a>{' '}
              tokens in the Varess pool earns you roughly{' '}
              <span className="font-bold">
                {Number(
                  poolId === 3
                    ? Number(hopesPerDayInPool) / 100
                    : hopesPerDayInPool
                ).toLocaleString('en-US')}{' '}
                HOPE{' '}
              </span>
              per day, for each {tokenName} staked. With enough HOPE, you can
              mint an exclusive limited-edition NFT card. Supply and HOPE
              required differ across NFTs.
            </div>

            {Number(allowance) > 0 ? (
              <div className="relative flex flex-col xl:flex-row justify-between overflow-x-scroll text-black">
                <input
                  type="number"
                  value={stakeMoreAmount}
                  className="text-right border border-solid border-1 xl:mr-2 pl-10 pr-2 py-1 font-semibold"
                  onChange={onChangeStakeMoreAmount}
                />

                <span
                  className="absolute top-0 left-0 mt-2 ml-2 text-black text-sm font-semibold cursor-pointer"
                  onClick={() =>
                    setState({
                      ...state,
                      stakeMoreAmount: Number(maximum) > 0 ? maximum : staked,
                    })
                  }
                >
                  max
                </span>

                {staked !== '0' && (
                  <Button
                    text="Unstake"
                    size="sm"
                    className="mt-2 xl:mr-2 xl:mt-0"
                    onClick={() => unstake(stakeMoreAmount)}
                  />
                )}

                <Button
                  disabled={
                    Number(maximum) === 0 ||
                    Number(maxStakeInPool) === Number(staked)
                  }
                  text={`Stake ${tokenName}`}
                  size="sm"
                  className="mt-2 xl:ml-2 xl:mt-0"
                  variant="pink"
                  onClick={() => stakeMore(stakeMoreAmount)}
                />
              </div>
            ) : (
              <Button
                text={`Approve ${tokenName}`}
                size="sm"
                onClick={approveRope}
              />
            )}

            <div className="text-sm font-normal mt-4">
              You are currently staking{' '}
              <b
                className="cursor-pointer"
                onClick={() => setState({ ...state, stakeMoreAmount: staked })}
              >
                {Number(
                  poolId === 3 ? Number(staked) * 100 : staked
                ).toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                {tokenName}
              </b>{' '}
              – the maximum is{' '}
              {Number(
                poolId === 3 ? Number(maxStakeInPool) * 100 : maxStakeInPool
              ).toLocaleString('en-US', {
                maximumFractionDigits: 2,
              })}{' '}
              {tokenName}
            </div>
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default StakePoolHeader;
