import BigNumber from 'bignumber.js/bignumber';

export const SUBTRACT_GAS_LIMIT = 100000;

const ONE_MINUTE_IN_SECONDS = new BigNumber(60);
const ONE_HOUR_IN_SECONDS = ONE_MINUTE_IN_SECONDS.times(60);
const ONE_DAY_IN_SECONDS = ONE_HOUR_IN_SECONDS.times(24);
const ONE_YEAR_IN_SECONDS = ONE_DAY_IN_SECONDS.times(365);

export const INTEGERS = {
  ONE_MINUTE_IN_SECONDS,
  ONE_HOUR_IN_SECONDS,
  ONE_DAY_IN_SECONDS,
  ONE_YEAR_IN_SECONDS,
  ZERO: new BigNumber(0),
  ONE: new BigNumber(1),
  ONES_31: new BigNumber('4294967295'), // 2**32-1
  ONES_127: new BigNumber('340282366920938463463374607431768211455'), // 2**128-1
  ONES_255: new BigNumber(
    '115792089237316195423570985008687907853269984665640564039457584007913129639935'
  ), // 2**256-1
  INTEREST_RATE_BASE: new BigNumber('1e18'),
};

export const contractAddresses = {
  rope: {
    1: '0x9d47894f8becb68b9cf3428d256311affe8b068b',
    4: '0xc427c5B9bE1dfd0FaB70ac42F8cE52Fe77A3c51E',
  },
  uni: {
    1: '0x1f9840a85d5af5bf1d1762f925bdaddc4201f984',
    4: '0x5592EC0cfb4dbc12D3aB100b257153436a1f0FEa',
  },
  ropeEthLp: {
    1: '0x67e475577B4036EE4f0F12fa2d538Ed18CEF48e3',
    4: '0x2acc448d73e8d53076731fea2ef3fc38214d0a7d',
  },
  hopeEthLp: {
    1: '0x060c603d9243e5152c406ee4eb603e4e9e47a11f',
    4: '0x2acc448d73e8d53076731fea2ef3fc38214d0a7d',
  },
  daiEthLp: {
    1: '0xa478c2975ab1ea89e8196811f51a7b7ade33eb11',
    4: '0x5592EC0cfb4dbc12D3aB100b257153436a1f0FEa',
  },
  usdtEthLp: {
    1: '0x0d4a11d5eeaac28ec3f61d100daf4d40471f1852',
    4: '0xD9BA894E0097f8cC2BBc9D24D308b98e36dc6D02',
  },
  usdcEthLp: {
    1: '0xb4e16d0168e52d35cacd2c6185b44281ec28c9dc',
    4: '0x4dbcdf9b62e891a7cec5a2568c3f4faf9e8abe2b',
  },
  wbtcEthLp: {
    1: '0xbb2b8038a1640196fbe3e38816f3e67cba72d940',
    4: '0x577d296678535e4903d59a4c929b718e1d575e0a',
  },
  ropeMaker: {
    1: '0xDb68Df0e86Bc7C6176E6a2255a5365f51113BCe8',
    4: '0x41f384Fedd7a7Cb7c7286C9526e8ae15d04F42cF',
  },
  hope: {
    1: '0x9d4aa5600e0c8c2085bb82d946ca6642742a8250',
    4: '0x55F4ED66c59483aA4EaEbf2bFBa6dA99022BEFf2',
  },
  hopeNonTradeable: {
    1: '0x1EadC903341CFdB3406a04506239f52D076b170B',
    4: '0x3008C6f806f66DeE32742eF327075C9C6cf1F97D',
  },
  charityVendingMachine: {
    1: '0xe7a91c6f8f2f8404a302e67d03ae575a1db6e1ba',
    4: '0xD5F767e4c6c93aD8d711457a9d3870b9ccc72350',
  },
  hopeVendingMachine: {
    1: '0x4C842514Fb55323AcC51aa575Ec4b7D1Be1E0694',
    4: '0xd0481C9DC47DfA2df2d700f3BC003acc0c262c81',
  },
  hopeVendingMachineV2: {
    1: '0x926d29feda57a1152f302db2dd25bfd7aea76e9e',
    4: '0xb95cb7bc582D8593EAFAB693D65E2bb06d1Af89B',
  },
  giverOfHope: {
    1: '0x88faAC1678c864d080d592BC15d1CdAdf01d7129',
    4: '0x1BFB6808876bC9D86591f65b5274a19FB35CE874',
  },
  ropeUniSpindle: {
    1: '0xC0F1274a553C61c8873A7E1eF1D037bA9768E8D2',
    4: '0xeeF1346BEb0755542C26A4C3a56CA7C93620c7b7',
  },
  hopeBooster: {
    1: '0x2Bb489AA8efE8Ab95abc994E1E64B0DC22895739',
    4: '0xB368bad9b0ba092F2C5c2b34f1d3B3D850eAC8CB',
  },
  jumpRope: {
    1: '0x7fabb70bb18036c9398e6c857da31763eda49cdd',
    4: '0xc88C1f26904Ce711C2BC569d711fD9bE9Ab3c2d4',
  },
  cardKeeper: {
    1: '0xb8db9ad0e016854675120f03643ab8d41f797862',
    4: '0x45e2C6e7682B0B14851F300583ef0545161Ec555',
  },
  cardRedeemer: {
    1: '0x07e1a8683EBbf27837b2a526612E41C889a2150B',
    4: '0x50605Fe4EC8D372713180d5A13b104181997550f',
  },
  zerionAdapterRegistry: {
    1: '0x06FE76B2f432fdfEcAEf1a7d4f6C3d41B5861672',
    4: undefined,
  },
  merkleClaimer: {
    1: '0x52E15B7EcA8d7C104587c0d76dc8C4AEFCA6E0A1',
    4: '0xC931C5939B5f28589ce1a8f1C7a0128cD343F62e',
  },
  hopeRaffle: {
    1: '0x0bb907558f2d68310c7964a7af86b9e3b1b3a4db',
    4: '0xb238e86B593E7f32354ed1B437FfADdB0e63eDf6',
  },
};
