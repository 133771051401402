import { useContext } from 'react'

import { Context } from 'contexts/RopeProvider'

const useRope = () => {
  const ropeContext = useContext(Context)
  return ropeContext
}

export default useRope
