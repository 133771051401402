import BigNumber from 'bignumber.js/bignumber';
import ERC20Abi from './abi/erc20.json';
import RopeMakerAbi from './abi/ropeMaker.json';
import HopeAbi from './abi/hope.json';
import HopeNonTradeableAbi from './abi/hopeNonTradealbe.json';
import CharityVendingMachineAbi from './abi/charityVendingMachine.json';
import HopeVendingMachineAbi from './abi/hopeVendingMachine.json';
import HopeVendingMachineV2Abi from './abi/hopeVendingMachineV2.json';
import GiverOfHopeAbi from './abi/giverOfHope.json';
import RopeSpindleAbi from './abi/ropeUniSpindle.json';
import HopeBoosterAbi from './abi/hopeBooster.json';
import JumpRopeAbi from './abi/jumpRope.json';
import CardKeeperAbi from './abi/cardKeeper.json';
import CardRedeemerAbi from './abi/cardRedeemer.json';
import ZerionAdapterRegistryAbi from './abi/zerionAdapterRegistry.json';
import MerkleClaimerAbi from './abi/merkleClaimer.json';
import HopeRaffleAbi from './abi/hopeRaffle.json';
import UniswapPairAbi from './abi/uniswapPair.json';

import { contractAddresses, SUBTRACT_GAS_LIMIT } from './constants.js';
import * as Types from './types.js';

export class Contracts {
  constructor(provider, networkId, web3, options) {
    this.web3 = web3;
    this.defaultConfirmations = options.defaultConfirmations;
    this.autoGasMultiplier = options.autoGasMultiplier || 1.5;
    this.confirmationType =
      options.confirmationType || Types.ConfirmationType.Confirmed;
    this.defaultGas = options.defaultGas;
    this.defaultGasPrice = options.defaultGasPrice;

    this.rope = new this.web3.eth.Contract(ERC20Abi, {
      gasLimit: options.defaultGas,
    });
    this.uni = new this.web3.eth.Contract(ERC20Abi, {
      gasLimit: options.defaultGas,
    });
    this.ropeEthLp = new this.web3.eth.Contract(UniswapPairAbi, {
      gasLimit: options.defaultGas,
    });
    this.hopeEthLp = new this.web3.eth.Contract(UniswapPairAbi, {
      gasLimit: options.defaultGas,
    });
    this.daiEthLp = new this.web3.eth.Contract(UniswapPairAbi, {
      gasLimit: options.defaultGas,
    });
    this.usdcEthLp = new this.web3.eth.Contract(UniswapPairAbi, {
      gasLimit: options.defaultGas,
    });
    this.usdtEthLp = new this.web3.eth.Contract(UniswapPairAbi, {
      gasLimit: options.defaultGas,
    });
    this.wbtcEthLp = new this.web3.eth.Contract(UniswapPairAbi, {
      gasLimit: options.defaultGas,
    });
    this.ropeMaker = new this.web3.eth.Contract(RopeMakerAbi, {
      gasLimit: options.defaultGas,
    });
    this.hope = new this.web3.eth.Contract(HopeAbi, {
      gasLimit: options.defaultGas,
    });
    this.hopeNonTradeable = new this.web3.eth.Contract(HopeNonTradeableAbi, {
      gasLimit: options.defaultGas,
    });
    this.charityVendingMachine = new this.web3.eth.Contract(
      CharityVendingMachineAbi,
      { gasLimit: options.defaultGas }
    );
    this.hopeVendingMachine = new this.web3.eth.Contract(
      HopeVendingMachineAbi,
      { gasLimit: options.defaultGas }
    );
    this.hopeVendingMachineV2 = new this.web3.eth.Contract(
      HopeVendingMachineV2Abi,
      { gasLimit: options.defaultGas }
    );
    this.giverOfHope = new this.web3.eth.Contract(GiverOfHopeAbi, {
      gasLimit: options.defaultGas,
    });
    this.ropeUniSpindle = new this.web3.eth.Contract(RopeSpindleAbi, {
      gasLimit: options.defaultGas,
    });
    this.hopeBooster = new this.web3.eth.Contract(HopeBoosterAbi, {
      gasLimit: options.defaultGas,
    });
    this.jumpRope = new this.web3.eth.Contract(JumpRopeAbi, {
      gasLimit: options.defaultGas,
    });
    this.cardKeeper = new this.web3.eth.Contract(CardKeeperAbi, {
      gasLimit: options.defaultGas,
    });
    this.cardRedeemer = new this.web3.eth.Contract(CardRedeemerAbi, {
      gasLimit: options.defaultGas,
    });
    this.zerionAdapterRegistry = new this.web3.eth.Contract(
      ZerionAdapterRegistryAbi,
      { gasLimit: options.defaultGas }
    );
    this.merkleClaimer = new this.web3.eth.Contract(MerkleClaimerAbi, {
      gasLimit: options.defaultGas,
    });
    this.hopeRaffle = new this.web3.eth.Contract(HopeRaffleAbi, {
      gasLimit: options.defaultGas,
    });

    this.setProvider(provider, networkId);
  }

  setProvider(provider, networkId) {
    const setContractProvider = (contract, address) => {
      contract.setProvider(provider);
      if (address) contract.options.address = address;
      else console.error('Contract address not found in network', networkId);
    };

    setContractProvider(this.rope, contractAddresses.rope[networkId]);
    setContractProvider(this.uni, contractAddresses.uni[networkId]);
    setContractProvider(this.ropeEthLp, contractAddresses.ropeEthLp[networkId]);
    setContractProvider(this.hopeEthLp, contractAddresses.hopeEthLp[networkId]);
    setContractProvider(this.daiEthLp, contractAddresses.daiEthLp[networkId]);
    setContractProvider(this.usdcEthLp, contractAddresses.usdcEthLp[networkId]);
    setContractProvider(this.usdtEthLp, contractAddresses.usdtEthLp[networkId]);
    setContractProvider(this.wbtcEthLp, contractAddresses.wbtcEthLp[networkId]);
    setContractProvider(this.ropeMaker, contractAddresses.ropeMaker[networkId]);
    setContractProvider(this.hope, contractAddresses.hope[networkId]);
    setContractProvider(
      this.hopeNonTradeable,
      contractAddresses.hopeNonTradeable[networkId]
    );
    setContractProvider(
      this.charityVendingMachine,
      contractAddresses.charityVendingMachine[networkId]
    );
    setContractProvider(
      this.hopeVendingMachine,
      contractAddresses.hopeVendingMachine[networkId]
    );
    setContractProvider(
      this.hopeVendingMachineV2,
      contractAddresses.hopeVendingMachineV2[networkId]
    );
    setContractProvider(
      this.giverOfHope,
      contractAddresses.giverOfHope[networkId]
    );
    setContractProvider(
      this.ropeUniSpindle,
      contractAddresses.ropeUniSpindle[networkId]
    );
    setContractProvider(
      this.hopeBooster,
      contractAddresses.hopeBooster[networkId]
    );
    setContractProvider(this.jumpRope, contractAddresses.jumpRope[networkId]);
    setContractProvider(
      this.cardKeeper,
      contractAddresses.cardKeeper[networkId]
    );
    setContractProvider(
      this.cardRedeemer,
      contractAddresses.cardRedeemer[networkId]
    );
    setContractProvider(
      this.zerionAdapterRegistry,
      contractAddresses.zerionAdapterRegistry[networkId]
    );
    setContractProvider(
      this.merkleClaimer,
      contractAddresses.merkleClaimer[networkId]
    );
    setContractProvider(
      this.hopeRaffle,
      contractAddresses.hopeRaffle[networkId]
    );
  }

  async call(method, options) {
    const {
      confirmations,
      confirmationType,
      autoGasMultiplier,
      ...txOptions
    } = options;

    if (!this.blockGasLimit) {
      const block = await this.web3.eth.getBlock('latest');

      this.blockGasLimit = block.gasLimit - SUBTRACT_GAS_LIMIT;
    }

    if (!txOptions.gasPrice && this.defaultGasPrice) {
      txOptions.gasPrice = this.defaultGasPrice;
    }

    if (confirmationType === Types.ConfirmationType.Simulate || !options.gas) {
      try {
        const gasEstimate = await method.estimateGas(txOptions);

        const multiplier = autoGasMultiplier || this.autoGasMultiplier;
        const totalGas = Math.floor(gasEstimate * multiplier);

        txOptions.gas =
          totalGas < this.blockGasLimit ? totalGas : this.blockGasLimit;

        if (confirmationType === Types.ConfirmationType.Simulate) {
          return { gasEstimate, g: txOptions.gas };
        }
      } catch (error) {
        const { from, value } = options;
        const data = method.encodeABI();
        const to = method._parent._address;

        error.transactionData = { from, value, data, to };

        throw error;
      }
    }

    if (txOptions.value) {
      txOptions.value = new BigNumber(txOptions.value).toFixed(0);
    } else {
      txOptions.value = '0';
    }

    const promi = method.send(txOptions);

    const OUTCOMES = {
      INITIAL: 0,
      RESOLVED: 1,
      REJECTED: 2,
    };

    let hashOutcome = OUTCOMES.INITIAL;
    let confirmationOutcome = OUTCOMES.INITIAL;

    const t =
      confirmationType !== undefined ? confirmationType : this.confirmationType;

    if (!Object.values(Types.ConfirmationType).includes(t)) {
      throw new Error(`Invalid confirmation type: ${t}`);
    }

    let hashPromise;
    let confirmationPromise;

    if (
      t === Types.ConfirmationType.Hash ||
      t === Types.ConfirmationType.Both
    ) {
      hashPromise = new Promise((resolve, reject) => {
        promi.on('error', (error) => {
          if (hashOutcome === OUTCOMES.INITIAL) {
            hashOutcome = OUTCOMES.REJECTED;
            reject(error);
            const anyPromi = promi;
            anyPromi.off();
          }
        });

        promi.on('transactionHash', (txHash) => {
          if (hashOutcome === OUTCOMES.INITIAL) {
            hashOutcome = OUTCOMES.RESOLVED;
            resolve(txHash);
            if (t !== Types.ConfirmationType.Both) {
              const anyPromi = promi;
              anyPromi.off();
            }
          }
        });
      });
    }

    if (
      t === Types.ConfirmationType.Confirmed ||
      t === Types.ConfirmationType.Both
    ) {
      confirmationPromise = new Promise((resolve, reject) => {
        promi.on('error', (error) => {
          if (
            (t === Types.ConfirmationType.Confirmed ||
              hashOutcome === OUTCOMES.RESOLVED) &&
            confirmationOutcome === OUTCOMES.INITIAL
          ) {
            confirmationOutcome = OUTCOMES.REJECTED;
            reject(error);
            const anyPromi = promi;
            anyPromi.off();
          }
        });

        const desiredConf = confirmations || this.defaultConfirmations;
        if (desiredConf) {
          promi.on('confirmation', (confNumber, receipt) => {
            if (confNumber >= desiredConf) {
              if (confirmationOutcome === OUTCOMES.INITIAL) {
                confirmationOutcome = OUTCOMES.RESOLVED;
                resolve(receipt);
                const anyPromi = promi;
                anyPromi.off();
              }
            }
          });
        } else {
          promi.on('receipt', (receipt) => {
            confirmationOutcome = OUTCOMES.RESOLVED;
            resolve(receipt);
            const anyPromi = promi;
            anyPromi.off();
          });
        }
      });
    }

    if (t === Types.ConfirmationType.Hash) {
      const transactionHash = await hashPromise;
      if (this.notifier) {
        this.notifier.hash(transactionHash);
      }
      return { transactionHash };
    }

    if (t === Types.ConfirmationType.Confirmed) {
      return confirmationPromise;
    }

    const transactionHash = await hashPromise;
    if (this.notifier) {
      this.notifier.hash(transactionHash);
    }
    return {
      transactionHash,
      confirmation: confirmationPromise,
    };
  }

  async callConstant(method, { blockNumber, ...txOptions }) {
    return method.call(txOptions, blockNumber);
  }
}
