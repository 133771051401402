import React from 'react';

import useApys from 'hooks/useApys';

import Card from 'components/Card';

const SectionArea: React.FC<any> = ({ title, amount, currency = false }) => {
  return (
    <div
      className="flex flex-col justify-center text-center px-4 py-2"
      style={{ minWidth: 120 }}
    >
      <span className="text-sm xl:text-base font-thin mb-1">{title}</span>
      {amount} %
    </div>
  );
};

interface ApyStatsCardProps {
  flex?: number;
  minWidth?: number;
}

const ApyStatsCard: React.FC<ApyStatsCardProps> = ({
  flex = 3,
  minWidth = 250,
}) => {
  const { ropeApy, ropeEthLpApy, hopeEthLpApy } = useApys();

  return (
    <div className="container mx-auto flex flex-row flex-wrap w-full px-4 sm:px-8 md:px-16 lg:px-32 pb-8">
      <Card flatVariant className="py-2 px-8 w-full" style={{ flex, minWidth }}>
        <div className="flex flex-row flex-wrap h-full items-around justify-around font-semibold">
          <SectionArea
            title="$ROPE APY"
            amount={Number(ropeApy).toLocaleString('en-US', {
              maximumFractionDigits: 2,
            })}
          />

          <SectionArea
            title="$ROPE / ETH APY"
            amount={Number(ropeEthLpApy).toLocaleString('en-US', {
              maximumFractionDigits: 2,
            })}
          />

          <SectionArea
            title="$HOPE / ETH APY"
            amount={Number(hopeEthLpApy).toLocaleString('en-US', {
              maximumFractionDigits: 2,
            })}
          />
        </div>
      </Card>
    </div>
  );
};

export default ApyStatsCard;
