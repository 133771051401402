import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useWallet } from 'use-wallet';

import metamaskLogo from 'assets/img/metamask-fox.svg';
import walletConnectLogo from 'assets/img/wallet-connect.svg';
import authereumLogo from 'assets/img/authereum-shield.svg';
import fortmaticLogo from 'assets/img/fortmatic.svg';

import Button from '../Button';
import Modal from '../Modal';
import ModalActions from '../ModalActions';
import ModalContent from '../ModalContent';
import ModalTitle from '../ModalTitle';
import Spacer from '../Spacer';

import WalletCard from './components/WalletCard';
import { ModalProps } from '../Modal/Modal';

const StyledWalletsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    flex-direction: column;
    flex-wrap: none;
  }
`;

const StyledWalletCard = styled.div`
  flex-basis: calc(50% - ${(props) => props.theme.spacing[2]}px);
  margin-bottom: 20px;
`;

const WalletProviderModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const { account, connect } = useWallet();

  useEffect(() => {
    if (account && onDismiss) {
      onDismiss();
    }
  }, [account, onDismiss]);

  return (
    <Modal>
      <ModalTitle text="Select a wallet provider." />

      <ModalContent>
        <StyledWalletsWrapper>
          <StyledWalletCard>
            <WalletCard
              icon={
                <img src={metamaskLogo} alt="metamask" style={{ height: 32 }} />
              }
              onConnect={() => connect('injected')}
              title="Metamask"
            />
          </StyledWalletCard>

          <Spacer size="sm" />

          <StyledWalletCard>
            <WalletCard
              icon={
                <img
                  src={walletConnectLogo}
                  alt="walletconnect"
                  style={{ height: 24 }}
                />
              }
              onConnect={() => connect('walletconnect')}
              title="WalletConnect"
            />
          </StyledWalletCard>

          <StyledWalletCard>
            <WalletCard
              icon={
                <img
                  src={authereumLogo}
                  alt="authereum"
                  style={{ height: 48 }}
                />
              }
              onConnect={() => connect('authereum')}
              title="Authereum"
            />
          </StyledWalletCard>

          <StyledWalletCard>
            <WalletCard
              icon={
                <img
                  src={fortmaticLogo}
                  alt="formatic"
                  style={{ height: 48 }}
                />
              }
              onConnect={() => connect('fortmatic')}
              title="Fortmatic"
            />
          </StyledWalletCard>
        </StyledWalletsWrapper>
      </ModalContent>

      <ModalActions>
        <Button text="Cancel" variant="secondary" onClick={onDismiss} />
      </ModalActions>
    </Modal>
  );
};

export default WalletProviderModal;
