import React from 'react';
import { Link } from 'react-router-dom';

import Header from 'components/Header';
import Footer from 'components/Footer';
import BackgroundCover from 'components/BackgroundCover';
import NftCard from 'components/NftCard';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import RopeStatsCard from 'components/RopeStatsCard';
import NavigationPills from 'components/NavigationPills';
import { cards } from 'cardSet';

const ClaimNFTs: React.FC = () => {
  return (
    <div className="flex flex-col w-screen min-h-screen items-center">
      <BackgroundCover />

      <Header />

      <NavigationPills />

      <div className="container mx-auto flex flex-row flex-wrap w-full px-4 sm:px-8 md:px-16 lg:px-32 pb-8">
        <RopeStatsCard noMargin />
      </div>

      <div className="container mx-auto w-full px-4 sm:px-8 md:px-16 lg:px-32 pb-32">
        <Card flatVariant className="py-2 px-4 md:px-8">
          <CardContent>
            <div className="flex flex-col w-full">
              <div className="text-sm cursive mb-4">
                <Link to="/varess">Claim NFTs - "plan trusters v2"</Link>
              </div>

              <div className="text-lg font-semibold mb-4 whitespace-no-wrap">
                Plan Trusters v2
              </div>
            </div>

            <div className="flex flex-wrap w-full justify-around lg:-ml-4">
              {[...(cards as any).keys()]
                .filter((id: number) => [71, 72, 89].includes(id))
                .map((id: number) => (
                  <div
                    key={id}
                    className={`mt-24 mb-10 w-full md:w-2/5 lg:w-3/10 lg:-mr-8`}
                  >
                    <NftCard claimVariant id={id} />
                  </div>
                ))}
            </div>
          </CardContent>

          <Footer />
        </Card>
      </div>
    </div>
  );
};

export default ClaimNFTs;
