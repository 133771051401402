import React from 'react';

import {
  harvestAllYieldedHope,
  harvestAllYieldedRope,
  transferAllRopeYield,
} from 'rope/utils';
import useRope from 'hooks/useRope';
import useModal from 'hooks/useModal';

import Card from 'components/Card';
import Button from 'components/Button';
import RopeStatsCard from 'components/RopeStatsCard';
import ConfirmClaimModal from './components/ConfirmClaimModal';

const SectionArea: React.FC<any> = ({
  icon,
  amount,
  title,
  button,
  estimate = false,
}) => {
  return (
    <div
      className="flex flex-col flex-1 py-4 px-8 w-auto"
      style={{ minWidth: 230 }}
    >
      <div className="flex flex-row items-center">
        {icon}

        <div className="flex flex-col ml-4">
          <div className="text-sm xl:text-base font-thin whitespace-no-wrap">
            {title}
          </div>

          <div className="text-lg font-bold">
            {estimate ? '~' : ''}
            {amount || 0}
          </div>
        </div>
      </div>

      {button}
    </div>
  );
};

const SpindleStatsHeader: React.FC = () => {
  const {
    rope,
    account,
    ropeEarned,
    bonusHopeEarned,
  } = useRope();

  const ropeYieldAvailable = 0;

  const claimAllRope = async () => harvestAllYieldedRope(rope, account);

  const claimAllHope = async () => harvestAllYieldedHope(rope, account);

  const transferAllYield = async () => transferAllRopeYield(rope, account);

  const [onShowConfirmClaim] = useModal(
    <ConfirmClaimModal onConfirm={claimAllRope} />
  );

  return (
    <div className="container mx-auto flex flex-row flex-wrap w-full px-4 sm:px-8 md:px-16 lg:px-32 pb-8">
      <RopeStatsCard flex={1} minWidth={200} />

      <Card
        flatVariant
        className="w-full md:w-1/5 mt-8 overflow-hidden"
        style={{ flex: 2, minWidth: 300 }}
      >
        <div className="flex flex-row flex-wrap h-full justify-around items-center">
          <SectionArea
            title="$ROPE Earned"
            amount={Number(ropeEarned).toLocaleString('en-US', {
              minimumFractionDigits: 4,
              maximumFractionDigits: 4,
            })}
            icon={
              <img
                src="/images/ROPE_icon.png"
                height={30}
                width={30}
                alt="rope"
              />
            }
            button={
              <Button
                rounded
                disabled={
                  Number(ropeEarned).toLocaleString('en-US', {
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4,
                  }) === '0.0000'
                }
                size="sm"
                variant="pink"
                text="Claim $ROPE"
                className="mt-4 xl:mt-8"
                onClick={onShowConfirmClaim}
              />
            }
          />

          <SectionArea
            title="Bonus HOPE Earned"
            amount={Number(bonusHopeEarned).toLocaleString('en-US', {
              minimumFractionDigits: 4,
              maximumFractionDigits: 4,
            })}
            icon={
              <img
                src="/images/HopeTokenv3.gif"
                height={30}
                width={30}
                alt="hope"
              />
            }
            button={
              <Button
                rounded
                disabled={
                  Number(bonusHopeEarned).toLocaleString('en-US', {
                    minimumFractionDigits: 4,
                    maximumFractionDigits: 4,
                  }) === '0.0000'
                }
                size="sm"
                variant="pink"
                text="Claim All HOPE"
                className="mt-4 xl:mt-8"
                onClick={claimAllHope}
              />
            }
          />

          <SectionArea
            estimate={
              Number(ropeYieldAvailable).toLocaleString('en-us', {
                minimumFractionDigits: 8,
                maximumFractionDigits: 8,
              }) !== '0.00000000'
            }
            title="$ROPE Yield Available"
            amount={Number(ropeYieldAvailable).toLocaleString('en-US', {
              minimumFractionDigits: 8,
              maximumFractionDigits: 8,
            })}
            icon={
              <img
                src="/images/RopeLogo3D.gif"
                height={30}
                width={30}
                alt="rope"
              />
            }
            button={
              <Button
                rounded
                disabled={
                  Number(ropeYieldAvailable).toLocaleString('en-US', {
                    minimumFractionDigits: 8,
                    maximumFractionDigits: 8,
                  }) === '0.00000000'
                }
                size="sm"
                text="Transfer All Yield → $ROPE"
                className="mt-4 xl:mt-8"
                onClick={transferAllYield}
              />
            }
          />
        </div>
      </Card>
    </div>
  );
};

export default SpindleStatsHeader;
