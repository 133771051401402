import React, { createRef, useState } from 'react';

import useOutsideClick from 'hooks/useOutsideClick';
import useNftCards from 'hooks/useNftCards';
import useRope from 'hooks/useRope';
import {
  stakeNFTs,
  unstakeNFTs,
  harvestNFTHope,
  approveNFTStaking,
} from 'rope/utils';

import Card from 'components/Card';
import CardContent from 'components/CardContent';
import Button from 'components/Button';

interface StakeHeaderProps {
  selectedCards: any[];
  onSelectAll: () => void;
}

const StakeHeader: React.FC<StakeHeaderProps> = ({
  selectedCards,
  onSelectAll,
}) => {
  const [state, setState] = useState({
    open: false,
  });
  const { open } = state;

  const { account, rope } = useRope();
  const { isStakingApproved, totalNftsStaked, unclaimedHope } = useNftCards();

  const ref = createRef<HTMLDivElement>();

  const stakeCards = async () => stakeNFTs(rope, account, selectedCards);
  const unstakeCards = async () => unstakeNFTs(rope, account, selectedCards);

  const claimHarvest = async () => harvestNFTHope(rope, account);
  const approveStake = async () => approveNFTStaking(rope, account);

  useOutsideClick(ref, () => setState({ ...state, open: false }));

  return (
    <div ref={ref} className="relative flex flex-col w-full">
      <div
        className="flex flex-col w-full xl:flex-row"
        onClick={() => {
          if (open) setState({ ...state, open: !open });
        }}
      >
        <Button
          disabled
          fullOpacity
          text={
            <span style={{ whiteSpace: 'nowrap' }}>
              <b>
                {Number(totalNftsStaked).toLocaleString('en-US', {
                  maximumFractionDigits: 2,
                })}{' '}
                NFTs
              </b>{' '}
              Staked
            </span>
          }
          size="sm"
          variant="pink"
        />

        <Button
          disabled
          fullOpacity
          text={
            <span style={{ whiteSpace: 'nowrap' }}>
              <b>
                {Number(unclaimedHope).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{' '}
                HOPE
              </b>{' '}
              Unclaimed
            </span>
          }
          size="sm"
          variant="pink"
        />

        <Button
          text={<b>Claim HOPE</b>}
          size="sm"
          variant="pink"
          onClick={claimHarvest}
        />

        <Button
          text={<b>Manage Stake</b>}
          size="sm"
          variant="pink"
          icon={<i className="fas fa-campground mr-2" />}
          onClick={() => setState({ ...state, open: !open })}
        />
      </div>

      {open && (
        <Card className="absolute z-20 top-auto right-0 translate-y-full -translate-x-full mt-12 flex flex-col w-full">
          <CardContent>
            <div className="text-lg font-semibold mb-2">
              Deposit{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://opensea.io/assets/0xdb68df0e86bc7c6176e6a2255a5365f51113bce8/21"
                className="font-semibold hover:underline"
              >
                NFTs
              </a>
              , Earn HOPE, Mint more NFT gold.
            </div>

            <div className="text-sm font-normal mb-4">
              Each{' '}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://opensea.io/assets/0xdb68df0e86bc7c6176e6a2255a5365f51113bce8/21"
                className="font-semibold hover:underline"
              >
                NFT
              </a>{' '}
              staked earns a fixed amount per day, depending on the NFT. Staking
              completed NFT sets activates the <b>Bonus HOPE Multiplier</b> for
              each NFT in the set. With enough HOPE, you can mint an exclusive
              limited-edition NFT card. Supply and HOPE required differ across
              NFTs.
            </div>

            {isStakingApproved ? (
              <div className="relative flex flex-col xl:flex-row justify-between">
                <input
                  disabled
                  type="number"
                  value={selectedCards.length}
                  className="text-right border border-solid border-1 xl:mr-2 px-2 py-1 font-semibold"
                />

                <span
                  className="absolute top-0 left-0 mt-2 ml-2 text-sm font-semibold cursor-pointer"
                  onClick={onSelectAll}
                >
                  {selectedCards.length === 0 ? 'select all' : 'deselect all'}
                </span>

                {totalNftsStaked !== '0' && (
                  <Button
                    text="Unstake Selected"
                    size="sm"
                    className="mt-2 xl:mr-2 xl:mt-0"
                    onClick={unstakeCards}
                  />
                )}

                <Button
                  text="Stake Selected"
                  size="sm"
                  className="mt-2 xl:ml-2 xl:mt-0"
                  variant="pink"
                  onClick={stakeCards}
                />
              </div>
            ) : (
              <Button text="Approve Stake" size="sm" onClick={approveStake} />
            )}
          </CardContent>
        </Card>
      )}
    </div>
  );
};

export default StakeHeader;
