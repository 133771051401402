import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import styled from 'styled-components';

import {
  approveNFTStaking,
  stakeNFTs,
  unstakeNFTs,
  purchaseCardForEth,
  redeemHopeForCard,
  redeemHopeForCardV2,
  claimNFT,
} from 'rope/utils';
import seventyOne from 'rope/merkle/71.json';
import seventyTwo from 'rope/merkle/72.json';
import eightyNine from 'rope/merkle/89.json';
import useRope from 'hooks/useRope';
import useModal from 'hooks/useModal';
import useNftCards from 'hooks/useNftCards';

import Button from 'components/Button';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import ExpandableImage from 'components/ExpandableImage';
import PurchaseNftModal from './components/PurchaseNftModal';

import { pink, purple, blue, black } from 'theme/colors';
import { cards as allCards } from 'cardSet';

const merkle: any = {
  71: seventyOne,
  72: seventyTwo,
  89: eightyNine
};

const PinkText = styled.span`
  color: ${({ theme }) => theme.color.pink[400]};
`;

const Badge = (color: string) => styled.div`
  border-radius: 10px;
  padding: 3px 1rem 5px;
  min-width: 90px;
  text-align: center;
  color: ${({ theme }) => theme.color.white};
  background-color: ${color};
`;

const PurpleBadge = Badge(purple[400]);
const BlueBadge = Badge(blue[400]);
const PinkBadge = Badge(pink[400]);
const BlackBadge = Badge(black);

const GlowingBorder = styled.div`
  filter: blur(6px);
  position: absolute;
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  width: 101%;
  height: 101%;
  z-index: 0;
  background: linear-gradient(
      to right,
      rgb(252, 115, 255),
      rgb(235, 3, 255),
      rgb(208, 36, 194),
      rgb(255, 0, 204),
      rgb(89, 9, 149),
      rgb(93, 84, 164),
      rgb(48, 168, 255)
    )
    0% / 300% 300%;
  animation: 2s linear 0s infinite normal none running LYCJl;
  border-radius: 12px;
`;

const NotEnoughHopeButton = styled.div`
  border-radius: 10px;
  border: ${({ theme }) => theme.color.grey[200]} 2px dashed;
  color: ${({ theme }) => theme.color.red[400]};
`;

const BalanceDisplay = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.grey[200]};
  background-color: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.black};
`;

const StakeButton = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.pink[400]};
  color: ${({ theme }) => theme.color.white};

  &:hover {
    opacity: 0.8;
  }
`;

const UnstakeButton = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.purple[400]};
  color: ${({ theme }) => theme.color.white};

  &:hover {
    opacity: 0.8;
  }
`;

const ApproveButton = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.color.black};
  color: ${({ theme }) => theme.color.white};

  &:hover {
    opacity: 0.8;
  }
`;

interface NFTCardProps {
  id: number;
  selected?: boolean;
  deckVariant?: boolean;
  charityVariant?: boolean;
  claimVariant?: boolean;
  useV2?: boolean;
  allowPurchaseMultiple?: boolean;
  onSelect?: () => void;
}

const NftCard: React.FC<NFTCardProps> = ({
  id,
  selected = false,
  deckVariant = false,
  charityVariant = false,
  claimVariant = false,
  useV2 = false,
  allowPurchaseMultiple = false,
  onSelect,
}) => {
  const { rope, account, hopeBalance, hopeV2Balance } = useRope();
  const {
    isStakingApproved,
    cards: _cards,
    cardSets,
    checkedCards,
    fetchCard,
  } = useNftCards();
  const history = useHistory();

  const nftData = allCards.get(id) ?? {};

  const {
    cardSetId,
    isStaked,
    hopeRequired,
    purchaseCost,
    balance,
    supply,
    totalSupply,
    rarity,
  } = _cards[id] || {};

  const {
    setSize,
    totalStaked,
    hopePerDayPerCard,
    bonusHopeMultiplier,
  } = cardSets[cardSetId] || {
    setSize: '∞',
    totalStaked: '0',
    hopePerDayPerCard: '0',
    bonusHopeMultiplier: '0',
  };

  const [openPurchaseModal] = useModal(
    <PurchaseNftModal cardId={id} purchasePrice={purchaseCost} />
  );

  let imgPath = (nftData['image'] || '').replace('https://rope.lol', '');

  const isVideo = imgPath.includes('.mp4');
  const is3dObject = (nftData['animation_url'] || '').includes('.glb');

  const imgPathSplit = imgPath.split('/');

  if (imgPath.length && nftData['image'].includes('https://rope.lol')) {
    imgPathSplit.shift();
    imgPathSplit.splice(imgPathSplit.length - 1, 0, 'low');
    imgPath = '/' + imgPathSplit.join('/');
  }

  if (is3dObject) {
    switch (id) {
      case 74:
        imgPath = '168b32473a6b46fcb3def080b8b35e1a';
        break;
      case 77:
        imgPath = '79fd341469b24f6dae22d2b0c6865326';
        break;
      case 80:
        imgPath = '0c932dc5083946e68b60e772645deb83';
        break;
      case 83:
        imgPath = 'e588e267c55149ddaa9891e61d6202d1';
        break;
      case 86:
        imgPath = '653c7d5cf0a94962b5cb0e17e4137d56';
        break;
    }
  }

  useEffect(() => {
    let subscribed = true;

    if (rope && subscribed && !checkedCards[id]) {
      fetchCard(id);
    }

    return () => {
      subscribed = false;
    };
  }, [rope, fetchCard, checkedCards, id]);

  const approveStake = async () => approveNFTStaking(rope, account);
  const redeemCard = async () =>
    useV2
      ? redeemHopeForCardV2(rope, account, id, 1, true)
      : redeemHopeForCard(rope, account, id);
  const redeemCardV2 = async () =>
    redeemHopeForCardV2(rope, account, id, 1, false);
  const purchaseCard = async () => purchaseCardForEth(rope, account, id);
  const stakeCard = async () => stakeNFTs(rope, account, id);
  const unstakeCard = async () => unstakeNFTs(rope, account, id);
  const claimNFTCard = async () => claimNFT(rope, account, id);

  const hasClaim = get(merkle[id], `claims.${account}.index`, null) !== null;
  const remainingSupply = Math.max(totalSupply - supply, 0);

  let seasonTitle = 'Season 1';

  if (id > 24 && id < 74) {
    seasonTitle = 'Season 2.9.9';
  } else if (id > 73 && id < 89) {
    seasonTitle = 'Set 1';
  } else if (id > 88) {
    seasonTitle = 'Diamond Hands'
  }

  let rarityBadge: any;

  switch (rarity) {
    case 'Epic':
    case 'Super Rare':
      rarityBadge = (
        <PinkBadge>
          <div className="text-sm font-medium">{rarity.toLowerCase()}</div>
        </PinkBadge>
      );
      break;
    case 'off___rare':
    case 'Charity':
      rarityBadge = (
        <PinkBadge>
          <div className="text-sm font-medium">charity</div>
        </PinkBadge>
      );
      break;
    case 'Pretty Rare':
      rarityBadge = (
        <BlueBadge>
          <div className="text-sm font-medium">pretty rare</div>
        </BlueBadge>
      );
      break;
    case 'Rare':
      rarityBadge = (
        <BlueBadge>
          <div className="text-sm font-medium">rare</div>
        </BlueBadge>
      );
      break;
    case 'Legendary':
    case 'Very Rare':
      rarityBadge = (
        <PurpleBadge>
          <div className="text-sm font-medium">{rarity.toLowerCase()}</div>
        </PurpleBadge>
      );
      break;
    case 'Uncommon':
      rarityBadge = (
        <PurpleBadge>
          <div className="text-sm font-medium">uncommon</div>
        </PurpleBadge>
      );
      break;
    case 'Ultra Rare':
      rarityBadge = (
        <BlackBadge>
          <div className="text-sm font-medium">ultra rare</div>
        </BlackBadge>
      );
      break;
    default:
      rarityBadge = (
        <BlackBadge>
          <div className="text-sm font-medium">loading...</div>
        </BlackBadge>
      );
      break;
  }

  if ((rarity || '').includes('Tier')) {
    rarityBadge = (
      <PinkBadge>
        <div className="text-sm font-medium">{rarity}</div>
      </PinkBadge>
    );
  }

  function getImage() {
    if (isVideo) {
      return (
        <video
          controls={true}
          autoPlay={true}
          loop={true}
          onLoadStart={(e) => (e.currentTarget.volume = 0.0)}
        >
          <source src={imgPath} type="video/mp4" />
        </video>
      );
    } else if (is3dObject) {
      return (
        <div className="sketchfab-embed-wrapper">
          <iframe
            title={nftData['name']}
            height="400"
            src={`https://sketchfab.com/models/${imgPath}/embed?autostart=0&amp;ui_controls=1&amp;ui_infos=1&amp;ui_inspector=1&amp;ui_stop=1&amp;ui_watermark=0&amp;ui_watermark_link=0`}
            frameBorder="0"
            allow="autoplay; fullscreen; vr"
          />
        </div>
      );
    // } else if (deckVariant) {
    //   return <img src={imgPath} alt={`nft-${id}`} className="z-10" />;
    } else {
      return (
        <ExpandableImage src={imgPath} alt={`nft-${id}`} className="z-10" />
      );
    }
  }

  return (
    <Card>
      <CardContent className="relative z-10 cursor-pointer" onClick={onSelect}>
        <div className="flex flex-col w-full h-full items-center">
          <Card className="-mt-24 relative object-contain">
            {selected && <GlowingBorder />}

            {getImage()}
          </Card>

          <div className="text-base cursive mt-3 whitespace-no-wrap">
            <PinkText>{remainingSupply || 0}</PinkText> / {totalSupply} Left
          </div>

          <div className="text-xl font-semibold mt-3 text-center">
            {nftData['name']}
          </div>

          <div className="flex flex-grow-1 flex-row w-full font-semibold justify-between items-center mt-5 px-1">
            <div className="text-sm font-semibold">
              {charityVariant ? 'Charity' : seasonTitle}
            </div>

            {rarityBadge}
          </div>

          {deckVariant || remainingSupply < 1 ? (
            <div className="my-5 w-2/3">
              <Button
                rounded
                text="View on OpenSea"
                size="sm"
                href={`https://opensea.io/assets/0xdb68df0e86bc7c6176e6a2255a5365f51113bce8/${id}`}
              />
            </div>
          ) : (
            <>
              {charityVariant && (
                <div className="my-5 w-2/3">
                  <Button
                    rounded
                    text={`Purchase for ${purchaseCost || '∞'} Ξ`}
                    size="sm"
                    variant="purple"
                    onClick={purchaseCard}
                  />
                </div>
              )}

              {claimVariant && (
                <div className="my-5 w-2/3">
                  <Button
                    rounded
                    disabled={Number(balance) > 0 || !hasClaim}
                    text="Claim NFT"
                    size="sm"
                    variant="purple"
                    onClick={claimNFTCard}
                  />
                </div>
              )}

              {!charityVariant && !claimVariant && (
                <>
                  {(Number(hopeRequired) === -1 ||
                    (Number(hopeBalance) < Number(hopeRequired) &&
                      Number(hopeV2Balance) < Number(hopeRequired))) && (
                    <NotEnoughHopeButton
                      className="my-5 py-2 px-6 text-sm font-semibold"
                      onClick={redeemCard}
                    >
                      You need {hopeRequired.toLocaleString('en')} HOPE
                    </NotEnoughHopeButton>
                  )}

                  {Number(hopeRequired) !== -1 &&
                    (Number(hopeBalance) >= Number(hopeRequired) ||
                      (allowPurchaseMultiple &&
                        Number(hopeV2Balance) >= Number(hopeRequired))) && (
                      <div className="flex flex-col mt-5 w-2/3">
                        <div className="text-xs self-end mb-1">
                          <span className="font-thin mr-2">Price:</span>

                          <span className="font-semibold">
                            {(hopeRequired || '∞').toLocaleString('en')} HOPE
                          </span>
                        </div>

                        <Button
                          rounded
                          text={
                            allowPurchaseMultiple
                              ? 'Redeem HOPE'
                              : 'Redeem HOPE v1'
                          }
                          size="sm"
                          variant="pink"
                          onClick={
                            allowPurchaseMultiple
                              ? openPurchaseModal
                              : redeemCard
                          }
                        />
                      </div>
                    )}

                  {id > 73 && id < 89 &&
                    !allowPurchaseMultiple &&
                    Number(hopeRequired) !== -1 &&
                    Number(hopeV2Balance) >= Number(hopeRequired) && (
                      <div className="flex flex-col mt-2 mb-2 w-2/3">
                        <Button
                          rounded
                          text="Redeem HOPE v2"
                          size="sm"
                          variant="pink"
                          onClick={
                            allowPurchaseMultiple
                              ? openPurchaseModal
                              : redeemCardV2
                          }
                        />
                      </div>
                    )}
                </>
              )}
            </>
          )}

          <div className="w-full flex justify-between items-center text-xs px-2 py-1">
            <div className="flex flex-col items-center px-1">
              <span className="font-semibold">
                {hopePerDayPerCard}{' '}
                {Number(totalStaked) === Number(setSize) &&
                  `(${
                    Number(hopePerDayPerCard) * Number(bonusHopeMultiplier)
                  })`}
              </span>
              <span className="font-thin">HOPE / day</span>
            </div>

            <div className="flex flex-col items-center px-1">
              <div className="flex flex-row font-semibold">
                <span className="flex flex-row items-end">
                  {bonusHopeMultiplier}x
                </span>
                {Number(totalStaked) === Number(setSize) ? (
                  <img
                    style={{ height: 35 }}
                    alt="fire"
                    src="/images/fire_low.gif"
                  />
                ) : (
                  <div style={{ minHeight: 35 }} />
                )}
              </div>
              <span className="font-thin">Set Bonus</span>
            </div>

            <div className="flex flex-col items-center px-1">
              <span className="font-semibold">
                {totalStaked} / {setSize}
              </span>
              <span className="font-thin">of Set Staked</span>
            </div>
          </div>
        </div>
      </CardContent>

      <BalanceDisplay
        className="flex w-full py-2 px-6 justify-between items-center text-sm text-center"
        onClick={() => history.push('/deck')}
      >
        <div className="cursor-pointer hover:opacity-75">
          <i className="fas fa-layer-group mr-2 text-black" />{' '}
          <span className="underline">
            {isStaked ? 1 + Number(balance) : balance}{' '}
            <span className="font-thin">Owned</span>
          </span>
        </div>

        <div className="font-semibold cursor-pointer hover:opacity-75">
          {isStaked ? 'Staked' : 'Unstaked'}{' '}
          <i className="fas fa-info-circle ml-2 text-black" />
        </div>
      </BalanceDisplay>

      {(Number(balance) > 0 || isStaked) && (
        <>
          {isStaked ? (
            <UnstakeButton
              className="flex w-full py-2 px-6 justify-center items-center text-sm text-center"
              onClick={unstakeCard}
            >
              <div className="font-semibold">
                <i className="fas fa-campground mr-2 text-black" /> Unstake NFT
              </div>
            </UnstakeButton>
          ) : isStakingApproved ? (
            <StakeButton
              className="flex w-full py-2 px-6 justify-center items-center text-sm text-center"
              onClick={stakeCard}
            >
              <div className="font-semibold">
                <i className="fas fa-campground mr-2 text-black" /> Stake NFT
              </div>
            </StakeButton>
          ) : (
            <ApproveButton
              className="flex w-full py-2 px-6 justify-center items-center text-sm text-center"
              onClick={approveStake}
            >
              <div className="font-semibold">Approve Stake</div>
            </ApproveButton>
          )}
        </>
      )}
    </Card>
  );
};

export default NftCard;
