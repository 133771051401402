import React from 'react';

import useRope from 'hooks/useRope';
import useNftCards from 'hooks/useNftCards';
import { NFT_POOL_ID_LIST } from 'contexts/RopeProvider';

import {
  harvestAllSeasonalHope,
  harvestNFTHope,
  harvestAllYieldedHope,
} from 'rope/utils';

import Button from 'components/Button';

const ClaimableHopeHeader: React.FC = () => {
  const { rope, account, bonusHopeEarned, unclaimedHopeInAllPools } = useRope();
  const { unclaimedHope } = useNftCards();

  const claimSeasonalHarvest = async () =>
    harvestAllSeasonalHope(rope, account, NFT_POOL_ID_LIST);

  const claimNFTHarvest = async () => harvestNFTHope(rope, account);

  const claimAllSpindleHope = async () => harvestAllYieldedHope(rope, account);

  return (
    <div className="flex flex-col w-full xl:flex-row">
      <Button
        fullOpacity
        text={
          <span style={{ whiteSpace: 'nowrap' }}>
            <b>
              {Number(unclaimedHopeInAllPools).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{' '}
              HOPE
            </b>{' '}
            Unclaimed Seasonal
          </span>
        }
        size="sm"
        variant="pink"
        className="mr-2"
        onClick={claimSeasonalHarvest}
      />

      <Button
        fullOpacity
        text={
          <span style={{ whiteSpace: 'nowrap' }}>
            <b>
              {Number(unclaimedHope).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{' '}
              HOPE
            </b>{' '}
            Unclaimed NFT
          </span>
        }
        size="sm"
        variant="pink"
        className="mr-2"
        onClick={claimNFTHarvest}
      />

      <Button
        fullOpacity
        text={
          <span style={{ whiteSpace: 'nowrap' }}>
            <b>
              {Number(bonusHopeEarned).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{' '}
              HOPE
            </b>{' '}
            Unclaimed Spindle
          </span>
        }
        size="sm"
        variant="pink"
        onClick={claimAllSpindleHope}
      />
    </div>
  );
};

export default ClaimableHopeHeader;
