import React from 'react';
import styled from 'styled-components';

import useRope from 'hooks/useRope';
import { NFT_POOL_ID_LIST } from 'contexts/RopeProvider';
import { harvestAllSeasonalHope } from 'rope/utils';

import Card from 'components/Card';
import Button from 'components/Button';
import RopeStatsCard from 'components/RopeStatsCard';

const PinkText = styled.div`
  color: ${({ theme }) => theme.color.pink[400]};
`;

const VaressStatsHeader: React.FC = () => {
  const { rope, account, unclaimedHopeInAllPools, hopeBalance } = useRope();

  const onRugPull = async () =>
    harvestAllSeasonalHope(rope, account, NFT_POOL_ID_LIST);

  return (
    <div className="container mx-auto flex flex-row flex-wrap w-full px-4 sm:px-8 md:px-16 lg:px-32 pb-8">
      <RopeStatsCard />

      <Card
        flatVariant
        className="w-full md:w-1/5 justify-between mt-8 overflow-hidden"
        style={{ flex: 1, minWidth: 250 }}
      >
        <div className="flex flex-col py-3 px-5 flex-1 justify-around">
          <div className="flex flex-row w-full justify-between items-center font-thin mb-3">
            <img
              height="25"
              width="25"
              src="/images/HopeTokenv3.gif"
              alt="HOPE"
            />
            <span className="text-sm text-right">Your HOPE</span>
          </div>

          <div className="flex flex-row w-full justify-end items-center">
            {unclaimedHopeInAllPools === '0' ? null : (
              <PinkText className="text-xs mr-2 pt-1 text-center">
                (
                {Number(unclaimedHopeInAllPools).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{' '}
                Unclaimed)
              </PinkText>
            )}

            <div className="text-xl">
              {Number(hopeBalance).toLocaleString('en-US', {
                maximumFractionDigits: 2,
              })}
            </div>
          </div>
        </div>

        <Button
          onClick={onRugPull}
          disabled={unclaimedHopeInAllPools === '0'}
          text={<b>Claim All HOPE</b>}
          variant="pink"
          size="sm"
        />
      </Card>
    </div>
  );
};

export default VaressStatsHeader;
