import React from 'react';

import useRope from 'hooks/useRope';

import Button from 'components/Button';
import Modal from 'components/Modal';
import ModalContent from 'components/ModalContent';
import ModalActions from 'components/ModalActions';

const HopeBoosterModal: React.FC<any> = ({ onDismiss }) => {
  const { unclaimedHopeInAllPools, bonusHopeEarned } = useRope();

  return (
    <Modal>
      <ModalContent>
        <div className="flex flex-col w-full justify-center items-center px-12">
          <div className="font-semibold text-lg text-center mb-8">
            Put your tokens to work in the
            <br />
            Rope Spindle!
          </div>

          <div className="text-base text-center mb-5">
            The Spindle allows you to earn yield from liquidity you've got
            deposited in Uniswap (UNI-V2 LP tokens).
          </div>

          <div className="text-base text-center mb-5">
            Stake your UNI-V2 LP tokens to start accumulating yield in $ROPE. At
            any moment, you can transfer your accumulated yield into the
            HopeBooster to get a bonus on your daily HOPE generation in the
            Seasonal Pools.
          </div>

          <div className="text-base text-center mb-5">
            The more $ROPE you accumulate in the HopeBooster, the more your
            multiplier will grow. You can claim your $ROPE at any moment, but be
            warned, claiming your $ROPE from the HopeBooster will reset your
            multiplier.
          </div>

          <div className="text-base text-center mb-5">
            No worries though, your bonus HOPE can be claimed anytime, without
            affecting your multiplier.
          </div>

          <div className="flex flex-col items-center sm:items-start w-3/4 mb-8">
            <span className="font-thin text-center mb-2">
              <span className="whitespace-no-wrap">
                Giver of Hope Rewards:{' '}
              </span>

              <span className="font-bold ml-4">
                {Number(unclaimedHopeInAllPools).toLocaleString('en-US', {
                  maximumFractionDigits: 4,
                  minimumFractionDigits: 4,
                })}
              </span>
            </span>

            <span className="font-thin text-center">
              <span className="whitespace-no-wrap">Hope Booster Rewards: </span>

              <span className="font-bold ml-4">
                {Number(bonusHopeEarned).toLocaleString('en-US', {
                  maximumFractionDigits: 4,
                  minimumFractionDigits: 4,
                })}
              </span>
            </span>
          </div>
        </div>
      </ModalContent>

      <ModalActions>
        <Button variant="secondary" text="Cancel" onClick={onDismiss} />
      </ModalActions>
    </Modal>
  );
};

export default HopeBoosterModal;
