import React from 'react';
import { Link } from 'react-router-dom';

import Header from 'components/Header';
import Footer from 'components/Footer';
import BackgroundCover from 'components/BackgroundCover';
import NftCard from 'components/NftCard';
import NftCardPack from 'components/NftCardPack';
import StakePoolHeader from 'components/StakePoolHeader';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import ApyStatsCard from 'components/ApyStatsCard';
import VaressStatsHeader from 'components/VaressStatsHeader';
import NavigationPills from 'components/NavigationPills';
import { cards } from 'cardSet';

const SeasonTwoPools: React.FC = () => {
  return (
    <div className="flex flex-col w-screen min-h-screen items-center">
      <BackgroundCover />

      <Header />

      <NavigationPills />

      <VaressStatsHeader />

      <ApyStatsCard />

      <div className="container mx-auto w-full px-4 sm:px-8 md:px-16 lg:px-32 pb-32">
        <Card flatVariant className="py-2 px-4 md:px-8">
          <CardContent>
            <div className="flex flex-col flex-wrap sm:flex-row w-full">
              <div className="flex flex-col w-full xl:w-1/3">
                <div className="text-sm cursive mb-4">
                  <Link to="/varess">Varess Pool - NFT Farm</Link>
                </div>

                <div className="text-lg font-semibold mb-4 whitespace-no-wrap">
                  Season 2.9.9 - "noombase - the game"
                </div>
              </div>

              <StakePoolHeader backLink="/varess" backText="change set" />
            </div>

            <div className="flex flex-col flex-wrap w-full items-center lg:-ml-4">
              <div className="flex flex-row flex-wrap justify-around w-full">
                <div className="w-1/3 mt-32" style={{ minWidth: 400 }}>
                  <NftCardPack id={1} />
                </div>
                <div className="w-1/3 mt-32" style={{ minWidth: 400 }}>
                  <NftCardPack id={2} />
                </div>
              </div>

              <div className="flex flex-row flex-wrap w-full justify-around mt-8">
                {[...(cards as any).keys()]
                  .filter((id: number) => id > 24 && id < 67)
                  .map((id: number) => (
                    <div
                      key={id}
                      className={`mt-24 mb-10 w-full md:w-2/5 lg:w-3/10 lg:-mr-8`}
                    >
                      <NftCard deckVariant id={id} />
                    </div>
                  ))}
              </div>
            </div>
          </CardContent>

          <Footer />
        </Card>
      </div>
    </div>
  );
};

export default SeasonTwoPools;
