import React, { useCallback } from 'react';
import { useWallet } from 'use-wallet';

import useModal from '../../../hooks/useModal';
import Button from '../../Button';
import WalletProviderModal from '../../WalletProviderModal';
import AccountModal from './AccountModal';

interface AccountButtonProps {
  balance?: string;
  className?: string;
}

const AccountButton: React.FC<AccountButtonProps> = ({
  balance,
  className,
}) => {
  const [onPresentAccountModal] = useModal(<AccountModal balance={balance} />);
  const [onPresentWalletProviderModal] = useModal(
    <WalletProviderModal />,
    'provider'
  );

  const { account } = useWallet();

  const handleUnlockClick = useCallback(() => {
    onPresentWalletProviderModal();
  }, [onPresentWalletProviderModal]);

  return (
    <div className={className}>
      {!account ? (
        <Button
          onClick={handleUnlockClick}
          size="sm"
          variant="black"
          text="Unlock Wallet"
        />
      ) : (
        <Button
          onClick={onPresentAccountModal}
          size="sm"
          variant="black"
          text={`${account.substr(0, 7)}...${account.substr(
            account.length - 5
          )}`}
        />
      )}
    </div>
  );
};

export default AccountButton;
