import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import useModal from 'hooks/useModal';
import useRaffle from 'hooks/useRaffle';
import useRaffleTicketsPurchased from 'hooks/useRaffleTicketsPurchased';

import Button from 'components/Button';
import Header from 'components/Header';
import Footer from 'components/Footer';
import BackgroundCover from 'components/BackgroundCover';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import ApyStatsCard from 'components/ApyStatsCard';
import VaressStatsHeader from 'components/VaressStatsHeader';
import NavigationPills from 'components/NavigationPills';
import ExpandableImage from 'components/ExpandableImage';
import RaffleHeader from './components/RaffleHeader';
import PurchaseTicketModal from './components/PurchaseTicketModal';

import { cards } from 'cardSet';

const PinkSpan = styled.span`
  color: ${({ theme }) => theme.color.pink[400]};
`;

const LovecraftRaffle: React.FC = () => {
  const location = useLocation();

  const urlTier = location.pathname.split('/')[4] || '';

  const {
    uncommonTicketsPurchased,
    rareTicketsPurchased,
    epicTicketsPurchased,
  } = useRaffleTicketsPurchased();

  let raffleId = 0;
  let rarity = 'Uncommon';
  let ticketsPurchased = uncommonTicketsPurchased;
  let totalTickets = 8;

  switch (urlTier) {
    case 'rare':
      raffleId = 1;
      rarity = 'Rare';
      ticketsPurchased = rareTicketsPurchased;
      totalTickets = 40;
      break;
    case 'epic':
      raffleId = 2;
      rarity = 'Epic';
      ticketsPurchased = epicTicketsPurchased;
      totalTickets = 200;
      break;
    default:
      break;
  }

  const [showPurchaseTicketModal] = useModal(
    <PurchaseTicketModal raffleId={raffleId} />
  );

  const { currentNftId, previousWinner, settleRaffle }: any = useRaffle({
    raffleId,
  });
  const card = cards.get(Number(currentNftId)) ?? { attributes: [] };

  const title = card.name;
  const description = card.description;
  const artistTrait =
    card.attributes.find(({ trait_type }: any) => trait_type === 'Artist') ||
    {};
  const artist = artistTrait.value;

  const prevCard =
    cards.get(Number(previousWinner ? previousWinner.nftId : -1)) ?? {};

  let imgPath = (card['image'] || '').replace('https://rope.lol', '');

  const isVideo = imgPath.includes('.mp4');
  const is3dObject = (card['animation_url'] || '').includes('.glb');

  const imgPathSplit = imgPath.split('/');

  if (imgPath.length && card['image'].includes('https://rope.lol')) {
    imgPathSplit.shift();
    imgPathSplit.splice(imgPathSplit.length - 1, 0, 'low');
    imgPath = '/' + imgPathSplit.join('/');
  }

  if (is3dObject) {
    switch (Number(currentNftId)) {
      case 74:
        imgPath = '168b32473a6b46fcb3def080b8b35e1a';
        break;
      case 77:
        imgPath = '79fd341469b24f6dae22d2b0c6865326';
        break;
      case 80:
        imgPath = '0c932dc5083946e68b60e772645deb83';
        break;
      case 83:
        imgPath = 'e588e267c55149ddaa9891e61d6202d1';
        break;
      case 86:
        imgPath = '653c7d5cf0a94962b5cb0e17e4137d56';
        break;
    }
  }

  function getImage() {
    if (isVideo) {
      return (
        <video
          controls={true}
          autoPlay={true}
          loop={true}
          onLoadStart={(e) => (e.currentTarget.volume = 0.0)}
        >
          <source src={imgPath} type="video/mp4" />
        </video>
      );
    } else if (is3dObject) {
      return (
        <div className="sketchfab-embed-wrapper">
          <iframe
            title={card['name']}
            width="100%"
            height="600"
            src={`https://sketchfab.com/models/${imgPath}/embed?autostart=0&amp;ui_controls=1&amp;ui_infos=1&amp;ui_inspector=1&amp;ui_stop=1&amp;ui_watermark=0&amp;ui_watermark_link=0`}
            frameBorder="0"
            allow="autoplay; fullscreen; vr"
          />
        </div>
      );
    } else {
      return (
        <ExpandableImage
          src={imgPath}
          alt={`nft-${currentNftId}`}
          className="z-10 mx-auto"
        />
      );
    }
  }

  return (
    <div className="flex flex-col w-screen min-h-screen items-center">
      <BackgroundCover />

      <Header />

      <NavigationPills />

      <VaressStatsHeader />

      <ApyStatsCard />

      <div className="container mx-auto w-full px-4 sm:px-8 md:px-16 lg:px-32 pb-32">
        <Card flatVariant className="py-2 px-4 md:px-8">
          <CardContent>
            <div className="flex flex-col sm:flex-row flex-wrap w-full">
              <div className="flex flex-col w-full xl:w-1/3">
                <div className="text-sm cursive mb-4">
                  <Link to="/varess">Lovecraft Raffle - NFT Lottery</Link>
                </div>

                <div className="text-lg font-semibold mb-4 whitespace-no-wrap">
                  Set 1 - {rarity} Raffle
                </div>
              </div>

              <RaffleHeader raffleId={raffleId} />
            </div>

            <Card className="px-2 py-6 justify-center items-center">
              {previousWinner && (
                <span className="flex flex-row items-between self-end mr-4 mb-6">
                  <div className="flex flex-col">
                    <span className="font-semibold mr-2">Previous Winner:</span>

                    <span className="font-semibold mr-2 mt-2">NFT Won:</span>

                    <span className="font-semibold mr-2 mt-2">
                      Tickets Purchased:
                    </span>
                  </div>

                  <div className="flex flex-col items-end">
                    <span className="font-thin">
                      {(previousWinner.winner || '').substr(0, 7)}...
                      {(previousWinner.winner || '').substr(
                        (previousWinner.winner || '').length - 5
                      )}
                    </span>

                    <span className="font-thin mt-2">
                      {rarity} {prevCard.name}
                    </span>

                    <span className="font-thin mt-2">
                      {previousWinner.ticketsPurchased}
                    </span>
                  </div>
                </span>
              )}

              <div className="w-1/2 mx-auto object-contain">{getImage()}</div>

              <div className="flex flex-col w-2/3 mt-4 mx-auto justify-center items-center">
                <h2 className="text-xl font-semibold">{title}</h2>

                <h4 className="text-md">Set: {rarity}</h4>

                <h4 className="text-md">Artist: {artist}</h4>

                <PinkSpan className="self-end">
                  <i className="fas fa-ticket-alt mr-2" />
                  <b>
                    {ticketsPurchased} / {totalTickets}
                  </b>{' '}
                  <span className="font-thin">tickets sold</span>
                </PinkSpan>

                <p className="text-md font-thin mt-2">{description}</p>

                <Button
                  fullOpacity
                  icon={<i className="fas fa-ticket-alt mr-2" />}
                  text="Purchase Tickets"
                  size="sm"
                  variant="pink"
                  className="mt-4"
                  onClick={showPurchaseTicketModal}
                />

                {Number(ticketsPurchased) === totalTickets && (
                  <Button
                    fullOpacity
                    icon={<i className="fas fa-gavel mr-2" />}
                    text="Settle Raffle"
                    size="sm"
                    variant="black"
                    className="mt-4"
                    onClick={settleRaffle}
                  />
                )}
              </div>
            </Card>
          </CardContent>

          <Footer />
        </Card>
      </div>
    </div>
  );
};

export default LovecraftRaffle;
