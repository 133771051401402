import React from 'react';
import styled from 'styled-components';

import useRope from 'hooks/useRope';

import Button from '../Button';
import Modal from '../Modal';
import ModalActions from '../ModalActions';
import ModalContent from '../ModalContent';
import ModalTitle from '../ModalTitle';
import Spacer from '../Spacer';

import TokenCard from './components/TokenCard';
import { ModalProps } from '../Modal/Modal';

const StyledTokensWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}px) {
    flex-direction: column;
    flex-wrap: none;
  }
`;

const StyledTokenCard = styled.div`
  flex-basis: calc(50% - ${(props) => props.theme.spacing[2]}px);
  margin-bottom: 20px;
`;

const SelectTokenModal: React.FC<ModalProps> = ({ onDismiss }) => {
  const { setPool } = useRope();

  return (
    <Modal>
      <ModalTitle text="Select a token." />

      <ModalContent>
        <StyledTokensWrapper>
          <StyledTokenCard>
            <TokenCard
              title="$ROPE"
              icon={
                <img
                  src="/images/ROPE_icon.png"
                  alt="ROPE"
                  style={{ height: 48 }}
                />
              }
              onSelect={() => {
                setPool?.(0, 'nft');
                onDismiss?.();
              }}
            />
          </StyledTokenCard>

          <Spacer size="sm" />

          <StyledTokenCard>
            <TokenCard
              title="$ROPE / ETH Sushi LP"
              icon={
                <img
                  src="/images/RopeLogo3D.gif"
                  alt="Sushi $ROPE / ETH"
                  style={{ height: 50 }}
                />
              }
              onSelect={() => {
                setPool?.(4, 'nft');
                onDismiss?.();
              }}
            />
          </StyledTokenCard>

          <Spacer size="sm" />

          <StyledTokenCard>
            <TokenCard
              title="HOPE / ETH Sushi LP"
              icon={
                <img
                  src="/images/HopeTokenv3.gif"
                  alt="Sushi HOPE / ETH"
                  style={{ height: 50 }}
                />
              }
              onSelect={() => {
                setPool?.(5, 'nft');
                onDismiss?.();
              }}
            />
          </StyledTokenCard>

          <Spacer size="sm" />

          <StyledTokenCard>
            <TokenCard
              title="$ROPE / ETH Uni-V2 LP"
              icon={
                <img
                  src="/images/RopeLogo3D.gif"
                  alt="Uni $ROPE / ETH"
                  style={{ height: 50 }}
                />
              }
              onSelect={() => {
                setPool?.(1, 'nft');
                onDismiss?.();
              }}
            />
          </StyledTokenCard>

          <Button
            variant="pink"
            href="https://exchange.sushiswapclassic.org/#/add/ETH/0x9D47894f8BECB68B9cF3428d256311Affe8B068B"
            text="Get LP Tokens"
            className="mb-4"
          />

          <Button
            href="https://lite.sushiswap.fi/#/liquidity/migrate"
            text="Migrate LP to Sushi"
          />
        </StyledTokensWrapper>
      </ModalContent>

      <ModalActions>
        <Button text="Cancel" variant="secondary" onClick={onDismiss} />
      </ModalActions>
    </Modal>
  );
};

export default SelectTokenModal;
