import React, { useCallback } from 'react';
import { useWallet } from 'use-wallet';
import styled from 'styled-components';

import Button from '../../Button';
import CardIcon from '../../CardIcon';
import Modal from '../../Modal';
import ModalActions from '../../ModalActions';
import ModalContent from '../../ModalContent';
import ModalTitle from '../../ModalTitle';
import Spacer from '../../Spacer';
import { ModalProps } from '../../Modal/Modal';

interface AccountModalProps extends ModalProps {
  balance?: string;
}

const AccountModal: React.FC<AccountModalProps> = ({ balance, onDismiss }) => {
  const { account, reset } = useWallet();

  const handleSignOutClick = useCallback(() => {
    onDismiss!();
    reset();
  }, [onDismiss, reset]);

  return (
    <Modal>
      <ModalTitle text="My Account" />
      <ModalContent>
        <Spacer />

        <div style={{ display: 'flex' }}>
          <StyledBalanceWrapper>
            <CardIcon>
              <img src="/images/ROPE_icon.png" alt="ROPE" />
            </CardIcon>

            <StyledBalance>
              <span>
                <b>
                  {balance === '0'
                    ? balance
                    : Number(balance).toLocaleString('en-US', {
                        maximumFractionDigits: 2,
                      })}
                </b>
              </span>

              <span>$ROPE</span>
            </StyledBalance>
          </StyledBalanceWrapper>
        </div>

        <Spacer />

        <Button
          href={`https://etherscan.io/address/${account}`}
          text="View on Etherscan"
          variant="secondary"
        />

        <Spacer />

        <Button
          onClick={handleSignOutClick}
          text="Sign out"
          variant="secondary"
        />
      </ModalContent>

      <ModalActions>
        <Button onClick={onDismiss} text="Cancel" />
      </ModalActions>
    </Modal>
  );
};

const StyledBalance = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const StyledBalanceWrapper = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: ${(props) => props.theme.spacing[4]}px;
`;

export default AccountModal;
