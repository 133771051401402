import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { withdrawFromAllPools } from 'rope/utils';
import { YIELD_POOL_ID_LIST } from 'contexts/RopeProvider';
import useRope from 'hooks/useRope';

import Header from 'components/Header';
import Footer from 'components/Footer';
import BackgroundCover from 'components/BackgroundCover';
import Button from 'components/Button';
import Card from 'components/Card';
import PoolCard from 'components/PoolCard';
import CardContent from 'components/CardContent';
import SpindleStatsHeader from 'components/SpindleStatsHeader';
import NavigationPills from 'components/NavigationPills';
import HopeBoosterMultiplier from 'components/HopeBoosterMultiplier';

const PinkTag = styled.div`
  padding: 2px 1.5rem;
  margin-bottom: 1.5rem;
  border-radius: 5px;
  font-weight: 200;
  display: flex;
  flex: 0;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.color.pink[400]};
  color: ${({ theme }) => theme.color.white};
`;

const SpindleFarms: React.FC = () => {
  const {
    rope,
    account,
    poolType,
    setPool,
  } = useRope();
  const history = useHistory();

  const ethDaiLpApy = 0;
  const ethUsdcLpApy = 0;
  const ethUsdtLpApy = 0;
  const ethWbtcLpApy = 0;

  useEffect(() => {
    if (poolType !== 'yield') {
      setPool?.(0, 'yield');
    }
  }, [poolType, setPool]);

  const withdrawAllLiquidity = async () =>
    withdrawFromAllPools(rope, account, YIELD_POOL_ID_LIST);

  return (
    <div className="flex flex-col w-screen min-h-screen items-center">
      <BackgroundCover />

      <Header />

      <NavigationPills />

      <SpindleStatsHeader />

      <div className="container mx-auto w-full px-4 sm:px-8 md:px-16 lg:px-32 mb-16">
        <Card flatVariant className="pt-2 px-4 sm:px-8">
          <CardContent>
            <div className="flex flex-col md:flex-row justify-between">
              <div className="flex flex-col">
                <div className="text-sm cursive mb-4">
                  The Rope Spindle - "get your pamp on"
                </div>

                <div className="text-lg font-semibold mb-4">
                  Uni-V2 Pools - $ROPE + HOPE Farm
                </div>
              </div>

              <div className="flex flex-col items-end">
                <HopeBoosterMultiplier />

                <Link to="/" className="-mt-4 text-sm font-semibold">
                  <i className="fas fa-arrow-left mr-2" />{' '}
                  <span className="underline">change farm</span>
                </Link>
              </div>
            </div>

            <div className="flex flex-col flex-wrap md:flex-row justify-between w-full pt-4">
              <PoolCard
                variant="black"
                onClick={() => history.push('/spindles/uni-v2/eth-dai')}
              >
                <div
                  className="flex flex-row items-center mb-1"
                  style={{ height: 80 }}
                >
                  <img
                    width="25"
                    height="25"
                    src="/images/eth-logo.svg"
                    alt="rope_asset"
                  />

                  <img
                    width="40"
                    height="40"
                    src="/images/dai-logo.svg"
                    alt="rope_asset"
                    className="ml-5"
                  />
                </div>

                <div className="text-xl font-semibold pb-6">ETH / DAI</div>

                <PinkTag>
                  <b className="mr-1">{Number(ethDaiLpApy).toFixed(2)} %</b> APY
                </PinkTag>

                <Button
                  to="/spindles/uni-v2/eth-dai"
                  text="Don't select this pair."
                />
              </PoolCard>

              <PoolCard
                variant="black"
                onClick={() => history.push('/spindles/uni-v2/eth-usdc')}
              >
                <div
                  className="flex flex-row items-center mb-1"
                  style={{ height: 80 }}
                >
                  <img
                    width="25"
                    height="25"
                    src="/images/eth-logo.svg"
                    alt="rope_asset"
                  />

                  <img
                    width="50"
                    height="50"
                    src="/images/usdc-logo.svg"
                    alt="rope_asset"
                    className="ml-4"
                  />
                </div>

                <div className="text-xl font-semibold pb-6">ETH / USDC</div>

                <PinkTag>
                  <b className="mr-1">{Number(ethUsdcLpApy).toFixed(2)} %</b>{' '}
                  APY
                </PinkTag>

                <Button
                  to="/spindles/uni-v2/eth-usdc"
                  text="Don't select this pair."
                />
              </PoolCard>

              <PoolCard
                variant="black"
                onClick={() => history.push('/spindles/uni-v2/eth-usdt')}
              >
                <div
                  className="flex flex-row items-center mb-1"
                  style={{ height: 80 }}
                >
                  <img
                    width="25"
                    height="25"
                    src="/images/eth-logo.svg"
                    alt="rope_asset"
                  />

                  <img
                    width="40"
                    height="40"
                    src="/images/usdt-logo.svg"
                    alt="rope_asset"
                    className="ml-5"
                  />
                </div>

                <div className="text-xl font-semibold pb-6">ETH / USDT</div>

                <PinkTag>
                  <b className="mr-1">{Number(ethUsdtLpApy).toFixed(2)} %</b>{' '}
                  APY
                </PinkTag>

                <Button
                  to="/spindles/uni-v2/eth-usdt"
                  text="Don't select this pair."
                />
              </PoolCard>

              <PoolCard
                variant="black"
                onClick={() => history.push('/spindles/uni-v2/eth-wbtc')}
              >
                <div
                  className="flex flex-row items-center -mr-4 mb-1"
                  style={{ height: 80 }}
                >
                  <img
                    width="25"
                    height="25"
                    src="/images/eth-logo.svg"
                    alt="rope_asset"
                  />

                  <img
                    width="80"
                    height="80"
                    src="/images/wbtc-logo.svg"
                    alt="rope_asset"
                    className="ml-1"
                  />
                </div>

                <div className="text-xl font-semibold pb-6">ETH / WBTC</div>

                <PinkTag>
                  <b className="mr-1">{Number(ethWbtcLpApy).toFixed(2)} %</b>{' '}
                  APY
                </PinkTag>

                <Button
                  to="/spindles/uni-v2/eth-wbtc"
                  text="Don't select this pair."
                />
              </PoolCard>

              <Button
                text="Withdraw All Liquidity From All Pools"
                onClick={withdrawAllLiquidity}
              />
            </div>
          </CardContent>

          <Footer />
        </Card>
      </div>
    </div>
  );
};

export default SpindleFarms;
