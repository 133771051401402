import React from 'react';
import styled from 'styled-components';

const BlackPoolCard = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 5px solid ${({ theme }) => theme.color.black};
  color: ${({ theme }) => theme.color.black};
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12); ;
`;

const WhitePoolCard = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius};
  color: ${({ theme }) => theme.color.white};
  border-width: 5px;
  border-style: solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    60deg,
    ${({ theme }) => theme.color.white} 0%,
    ${({ theme }) => theme.color.offWhite} 70%
  );
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12); ;
`;

const PinkPoolCard = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius};
  border-width: 5px;
  border-style: solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    60deg,
    ${({ theme }) => theme.color.pink[400]} 0%,
    ${({ theme }) => theme.color.pink[100]} 70%
  );
  color: ${({ theme }) => theme.color.pink[400]};
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12); ;
`;

const PurplePoolCard = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius};
  border-width: 5px;
  border-style: solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    60deg,
    ${({ theme }) => theme.color.purple[400]} 0%,
    ${({ theme }) => theme.color.purple[100]} 70%
  );
  color: ${({ theme }) => theme.color.purple[400]};
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12); ;
`;

const BluePoolCard = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius};
  border-width: 5px;
  border-style: solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    60deg,
    ${({ theme }) => theme.color.blue[400]} 0%,
    ${({ theme }) => theme.color.blue[100]} 70%
  );
  color: ${({ theme }) => theme.color.blue[400]};
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12); ;
`;

interface PoolCardProps {
  children: any;
  style?: object;
  className?: string;
  variant?: undefined | 'pink' | 'purple' | 'black' | 'white' | 'blue';
  onClick?: () => any;
}

const PoolCard: React.FC<PoolCardProps> = ({
  children,
  style,
  className,
  variant,
  onClick,
}) => {
  let StyledPoolCard = BlackPoolCard;

  switch (variant) {
    case 'pink':
      StyledPoolCard = PinkPoolCard;
      break;

    case 'purple':
      StyledPoolCard = PurplePoolCard;
      break;

    case 'white':
      StyledPoolCard = WhitePoolCard;
      break;

    case 'blue':
      StyledPoolCard = BluePoolCard;
      break;

    default:
      break;
  }

  return (
    <StyledPoolCard
      style={style}
      className={`flex flex-col w-full h-auto justify-between items-center md:w-5/12 pt-8 mb-10 ${
        className || ''
      } ${onClick ? 'cursor-pointer hover:opacity-75' : ''}`}
      onClick={onClick}
    >
      {children}
    </StyledPoolCard>
  );
};

export default PoolCard;
