import React from 'react';

import Button from 'components/Button';
import Card from 'components/Card';
import CardContent from 'components/CardContent';
import CardIcon from 'components/CardIcon';
import CardTitle from 'components/CardTitle';

interface TokenCardProps {
  icon: React.ReactNode;
  title: string;
  onSelect: () => void;
}

const TokenCard: React.FC<TokenCardProps> = ({ icon, title, onSelect }) => (
  <Card>
    <CardContent>
      <CardIcon>{icon}</CardIcon>
      <CardTitle text={title} />
      <Button onClick={onSelect} text="Select" />
    </CardContent>
  </Card>
);

export default TokenCard;
