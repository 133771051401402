import React from 'react';
import styled from 'styled-components';

const TopBorderedDiv = styled.div`
  position: relative;

  &:before {
    content: ' ';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 1px;
    width: 33%;
    border: 0;
    border-top: 1px solid ${({ theme }) => theme.color.black};
  }
`;

const Footer = () => {
  return (
    <TopBorderedDiv className="flex flex-col flex-wrap lg:flex-no-wrap w-full items-center lg:flex-row lg:justify-around mt-16 py-16">
      <a
        className="zoom py-2 lg:py-0"
        href="https://twitter.com/dontbuyrope"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="object-contain px-4 py-4"
          src="/images/Twitter.png"
          alt="twitter"
        />
      </a>

      <a
        className="zoom py-2 lg:py-0"
        href="https://app.uniswap.org/#/swap?inputCurrency=0x9d47894f8becb68b9cf3428d256311affe8b068b&outputCurrency=ETH"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="object-contain px-4 py-4"
          src="/images/Uniswap.png"
          alt="uniswap"
        />
      </a>

      <a
        className="zoom py-2 lg:py-0"
        href="https://t.me/dontbuyrope"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="object-contain px-4 py-4"
          src="/images/Telegram.png"
          alt="telegram"
        />
      </a>

      <a
        className="zoom py-2 lg:py-0"
        href="https://medium.com/@dontbuyrope"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="object-contain px-4 py-4"
          src="/images/Medium.png"
          alt="medium"
        />
      </a>
    </TopBorderedDiv>
  );
};

export default Footer;
