import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import useRope from 'hooks/useRope';
import useModal from 'hooks/useModal';

import Header from 'components/Header';
import Footer from 'components/Footer';
import BackgroundCover from 'components/BackgroundCover';
import Button from 'components/Button';
import Card from 'components/Card';
import PoolCard from 'components/PoolCard';
import CardContent from 'components/CardContent';
import RopeStatsCard from 'components/RopeStatsCard';
import ApyStatsCard from 'components/ApyStatsCard';
import NavigationPills from 'components/NavigationPills';
import UpgradeHopeModal from 'components/UpgradeHopeModal';
import ClaimableHopeHeader from './components/ClaimableHopeHeader';

const PurpleSpan = styled.span`
  color: ${({ theme }) => theme.color.purple[400]};
`;

const PinkSpan = styled.span`
  color: ${({ theme }) => theme.color.pink[400]};
`;

const RedSpan = styled.span`
  color: ${({ theme }) => theme.color.red[400]};
`;

const FarmSelection: React.FC = () => {
  const { poolId, poolType, setPool } = useRope();
  const [showExchangeHopeModal] = useModal(<UpgradeHopeModal />);
  const history = useHistory();

  useEffect(() => {
    if (poolType !== null) {
      setPool?.(0, null);
    }
  }, [poolId, poolType, setPool]);

  return (
    <div className="flex flex-col w-screen min-h-screen items-center">
      <BackgroundCover />

      <Header />

      <NavigationPills />

      <div className="container mx-auto flex flex-row flex-wrap w-full px-4 sm:px-8 md:px-16 lg:px-32 pb-8">
        <RopeStatsCard noMargin />
      </div>

      <ApyStatsCard />

      <div className="container mx-auto w-full px-4 sm:px-8 md:px-16 lg:px-32 mb-16">
        <Card flatVariant className="pt-2 px-4 sm:px-8">
          <CardContent>
            <div className="flex flex-col md:flex-row justify-between">
              <div className="flex flex-col w-full xl:w-1/4 mb-4">
                <div className="text-sm cursive mb-4">The Rope Estate</div>

                <div className="text-lg font-semibold mb-4 whitespace-no-wrap">
                  Select a Farm
                </div>
              </div>

              <div className="flex flex-col w-full ml-0 md:ml-5 xl:ml-0 xl:w-3/4">
                <ClaimableHopeHeader />

                <Button
                  size="sm"
                  variant="purple"
                  className="mt-2"
                  onClick={showExchangeHopeModal}
                >
                  Exchange Non-Tradeable HOPE
                </Button>
              </div>
            </div>

            <div
              className="flex flex-col md:flex-row flex-wrap
             justify-between w-full pt-4"
            >
              <PoolCard variant="pink" onClick={() => history.push('/varess')}>
                <img
                  width="100"
                  height="100"
                  src="/images/swimming-pool.svg"
                  alt="pool"
                  className="mb-2"
                />

                <div className="text-xl font-semibold pb-6">Seasonal Pools</div>

                <div className="w-1/5 border border-l-0 border-r-0 border-t-0 border-b-1 border-black"></div>

                <div className="cursive text-black py-8 px-4 text-center">
                  Stake your tokens to <PinkSpan>Farm HOPE</PinkSpan>
                  <br />
                  and
                  <br />
                  <PinkSpan>Purchase NFTs</PinkSpan> with your HOPE.
                </div>

                <Button to="/varess" text="Don't farm HOPE." variant="pink" />
              </PoolCard>

              <PoolCard variant="black" onClick={() => history.push('/deck')}>
                <img
                  width="100"
                  height="100"
                  src="/images/card-game.svg"
                  alt="cards"
                  className="mb-2"
                />

                <div className="text-xl font-semibold pb-6">NFT Staking</div>

                <div className="w-1/5 border border-l-0 border-r-0 border-t-0 border-b-1 border-black"></div>

                <div className="cursive text-black py-8 px-4 text-center">
                  Stake NFTs in your deck to <PinkSpan>Farm HOPE</PinkSpan>
                </div>

                <Button to="/deck" text="Don't stake NFTs." variant="black" />
              </PoolCard>

              <PoolCard
                variant="purple"
                onClick={() => history.push('/jump-rope')}
              >
                <img
                  width="80"
                  height="auto"
                  src="/images/jump-rope.svg"
                  alt="jump rope"
                  className="mb-2"
                />

                <div className="text-xl font-semibold pb-6">
                  Jump Rope v2 - HOPE LGE
                </div>

                <div className="w-1/5 mb-6 border border-l-0 border-r-0 border-t-0 border-b-1 border-black"></div>

                <RedSpan>
                  Jump Rope v2 is complete - claim your HOPE now.
                </RedSpan>

                <div className="cursive text-black py-8 px-4 text-center">
                  Deposit ETH to <PurpleSpan>Purchase HOPE</PurpleSpan> in the
                  HOPE liquidity generation event.
                </div>

                <Button
                  to="/jump-rope"
                  text="Don't jump ROPE."
                  variant="purple"
                />
              </PoolCard>

              <PoolCard
                variant="white"
                onClick={() => history.push('/spindles')}
              >
                <img
                  width="104"
                  height="104"
                  src="/images/safe-box.svg"
                  alt="safe"
                  className="mb-2"
                />

                <div className="text-xl font-semibold text-black pb-6">
                  The Rope Spindle
                </div>

                <div className="w-1/5 mb-6 border border-l-0 border-r-0 border-t-0 border-b-1 border-black"></div>

                <RedSpan>Spindle staking is currently disabled.</RedSpan>

                <div className="cursive text-black py-8 px-4 text-center">
                  Stake UNI-V2 LP to <PurpleSpan>Farm $ROPE + HOPE</PurpleSpan>
                </div>

                <Button
                  to="/spindles"
                  text="Don't farm ROPE."
                  variant="white"
                />
              </PoolCard>
            </div>
          </CardContent>

          <Footer />
        </Card>
      </div>
    </div>
  );
};

export default FarmSelection;
