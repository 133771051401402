import BigNumber from 'bignumber.js';
import { ethers } from 'ethers';
import { get } from 'lodash';
import web3 from 'web3';

import { cards, cardsExtraData } from 'cardSet';
import ERC20Abi from './lib/abi/erc20.json';
// import { YIELD_POOL_ID_LIST } from "contexts/RopeProvider";
import seventyOne from './merkle/71.json';
import seventyTwo from './merkle/72.json';
import eightyNine from './merkle/89.json';

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
});

const merkle = {
  71: seventyOne,
  72: seventyTwo,
  89: eightyNine,
};

const DEFAULT_GAS_PRICE = 70;

// const UNI_WEEKLY_REWARD = 583333;

const tokenPrices = {};
const lastTokenCheck = {};

export const getGasPrice = async () => {
  try {
    const url = 'https://gasprice.poa.network/';
    const priceString = await fetch(url);
    const priceJSON = await priceString.json();

    if (priceJSON) {
      return web3.utils.toWei(priceJSON.fast.toFixed(0), 'gwei');
    }

    return DEFAULT_GAS_PRICE;
  } catch (e) {
    console.log('Error getting gas price: ', e);
    return DEFAULT_GAS_PRICE;
  }
};

export const getPrice = async (coinName) => {
  const geckoUrl = `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=${coinName}`;
  const lastCheck = lastTokenCheck[coinName];

  if (!lastCheck || new Date() - lastCheck > 5 * 60 * 1000) {
    try {
      const result = await fetch(geckoUrl);
      const priceJson = await result.json();

      tokenPrices[coinName] = Number(get(priceJson, '0.current_price'));
      lastTokenCheck[coinName] = new Date();

      return tokenPrices[coinName];
    } catch (err) {
      console.log(`Error fetching price for ${coinName}:`, err);

      tokenPrices[coinName] = Number(1);
      lastTokenCheck[coinName] = new Date();
    }
  }

  return tokenPrices[coinName] || 1;
};

export const getRopePrice = async () => {
  return getPrice('rope');
};

export const getUniPrice = async () => {
  return getPrice('uniswap');
};

export const getEthPrice = async () => {
  return getPrice('ethereum');
};

export const getBtcPrice = async () => {
  return getPrice('bitcoin');
};

// Addresses

export const getRopeAddress = (rope) => {
  return get(rope, 'ropeAddress');
};

export const getUniAddress = (rope) => {
  return get(rope, 'uniAddress');
};

export const getRopeEthLpAddress = (rope) => {
  return get(rope, 'ropeEthLpAddress');
};

export const getHopeEthLpAddress = (rope) => {
  return get(rope, 'hopeEthLpAddress');
};

export const getDaiEthLpAddress = (rope) => {
  return get(rope, 'daiEthLpAddress');
};

export const getUsdtEthLpAddress = (rope) => {
  return get(rope, 'usdtEthLpAddress');
};

export const getUsdcEthLpAddress = (rope) => {
  return get(rope, 'usdcEthLpAddress');
};

export const getWbtcEthLpAddress = (rope) => {
  return get(rope, 'wbtcEthLpAddress');
};

export const getRopeMakerAddress = (rope) => {
  return get(rope, 'ropeMakerAddress');
};

export const getHopeAddress = (rope) => {
  return get(rope, 'hopeNonTradeableAddress');
};

export const getCharityVendingMachineAddress = (rope) => {
  return get(rope, 'charityVendingMachineAddress');
};

export const getHopeVendingMachineAddress = (rope) => {
  return get(rope, 'hopeVendingMachineAddress');
};

export const getHopeVendingMachineV2Address = (rope) => {
  return get(rope, 'hopeVendingMachineV2Address');
};

export const getGiverOfHopeAddress = (rope) => {
  return get(rope, 'giverOfHopeAddress');
};

export const getRopeSpindleAddress = (rope) => {
  return get(rope, 'ropeUniSpindleAddress');
};

export const getHopeBoosterAddress = (rope) => {
  return get(rope, 'hopeBoosterAddress');
};

export const getJumpRopeAddress = (rope) => {
  return get(rope, 'jumpRopeAddress');
};

export const getCardKeeperAddress = (rope) => {
  return get(rope, 'cardKeeperAddress');
};

export const getCardRedeemerAddress = (rope) => {
  return get(rope, 'cardRedeemerAddress');
};

export const getZerionAdapterRegistryAddress = (rope) => {
  return get(rope, 'zerionAdapterRegistryAddress');
};

export const getMerkleClaimerAddress = (rope) => {
  return get(rope, 'merkleClaimerAddress');
};

export const getHopeRaffleAddress = (rope) => {
  return get(rope, 'hopeRaffleAddress');
};

// Contracts

export const getRopeContract = (rope) => {
  return get(rope, 'contracts.rope');
};

export const getUniContract = (rope) => {
  return get(rope, 'contracts.uni');
};

export const getRopeEthLpContract = (rope) => {
  return get(rope, 'contracts.ropeEthLp');
};

export const getHopeEthLpContract = (rope) => {
  return get(rope, 'contracts.hopeEthLp');
};

export const getDaiEthLpContract = (rope) => {
  return get(rope, 'contracts.daiEthLp');
};

export const getUsdtEthLpContract = (rope) => {
  return get(rope, 'contracts.usdtEthLp');
};

export const getUsdcEthLpContract = (rope) => {
  return get(rope, 'contracts.usdcEthLp');
};

export const getWbtcEthLpContract = (rope) => {
  return get(rope, 'contracts.wbtcEthLp');
};

export const getRopeMakerContract = (rope) => {
  return get(rope, 'contracts.ropeMaker');
};

export const getHopeContract = (rope) => {
  return get(rope, 'contracts.hope');
};

export const getHopeNonTradeableContract = (rope) => {
  return get(rope, 'contracts.hopeNonTradeable');
};

export const getCharityVendingMachineContract = (rope) => {
  return get(rope, 'contracts.charityVendingMachine');
};

export const getHopeVendingMachineContract = (rope) => {
  return get(rope, 'contracts.hopeVendingMachine');
};

export const getHopeVendingMachineV2Contract = (rope) => {
  return get(rope, 'contracts.hopeVendingMachineV2');
};

export const getGiverOfHopeContract = (rope) => {
  return get(rope, 'contracts.giverOfHope');
};

export const getRopeSpindleContract = (rope) => {
  return get(rope, 'contracts.ropeUniSpindle');
};

export const getHopeBoosterContract = (rope) => {
  return get(rope, 'contracts.hopeBooster');
};

export const getJumpRopeContract = (rope) => {
  return get(rope, 'contracts.jumpRope');
};

export const getCardKeeperContract = (rope) => {
  return get(rope, 'contracts.cardKeeper');
};

export const getCardRedeemerContract = (rope) => {
  return get(rope, 'contracts.cardRedeemer');
};

export const getZerionAdapterRegistryContract = (rope) => {
  return get(rope, 'contracts.zerionAdapterRegistry');
};

export const getMerkleClaimerContract = (rope) => {
  return get(rope, 'contracts.merkleClaimer');
};

export const getHopeRaffleContract = (rope) => {
  return get(rope, 'contracts.hopeRaffle');
};

// Balances

export const getRopeBalance = async (rope, account) => {
  const ropeContract = getRopeContract(rope);
  return web3.utils.fromWei(
    await ropeContract.methods.balanceOf(account).call()
  );
};

export const getNFTBalance = async (rope, address, id) => {
  const ropeMakerContract = getRopeMakerContract(rope);
  return new BigNumber(
    await ropeMakerContract.methods.balanceOf(address, id).call()
  );
};

// Seasonal Pool Getters

export const getSeasonalPoolInfo = async (rope, poolId) => {
  if (poolId === undefined) return;

  const giverOfHopeContract = getGiverOfHopeContract(rope);
  return giverOfHopeContract.methods.poolInfo(poolId).call();
};

export const getNFTUserInfo = async (rope, account, poolId) => {
  if (poolId === undefined) return;

  const giverOfHopeContract = getGiverOfHopeContract(rope);
  return giverOfHopeContract.methods.userInfo(poolId, account).call();
};

export const getNFTSupply = async (rope, id) => {
  const ropeMakerContract = getRopeMakerContract(rope);
  return new BigNumber(await ropeMakerContract.methods.totalSupply(id).call());
};

export const getNFTMaxSupply = async (rope, id) => {
  const ropeMakerContract = getRopeMakerContract(rope);
  return new BigNumber(await ropeMakerContract.methods.maxSupply(id).call());
};

export const getSeasonalPoolAllowance = async (rope, account, poolId) => {
  const poolInfo = await getSeasonalPoolInfo(rope, poolId);
  const tokenContract = new rope.web3.eth.Contract(ERC20Abi, poolInfo.token);

  return tokenContract.methods
    .allowance(account, getGiverOfHopeAddress(rope))
    .call({ from: account });
};

export const getRopeStakedNFT = async (rope, account, poolId) => {
  const userInfo = await getNFTUserInfo(rope, account, poolId);

  return web3.utils.fromWei(userInfo.amount);
};

export const getSeasonalPoolTokenBalance = async (rope, account, poolId) => {
  const poolInfo = await getSeasonalPoolInfo(rope, poolId);
  const tokenContract = new rope.web3.eth.Contract(ERC20Abi, poolInfo.token);

  return web3.utils.fromWei(
    await tokenContract.methods.balanceOf(account).call()
  );
};

export const getMaxStakeNFT = async (rope, poolId) => {
  return web3.utils.fromWei(
    (await getSeasonalPoolInfo(rope, poolId)).maxStake,
    'ether'
  );
};

export const getPendingHope = async (rope, account, poolId) => {
  const giverOfHopeContract = getGiverOfHopeContract(rope);
  return web3.utils.fromWei(
    await giverOfHopeContract.methods.pendingHope(poolId, account).call()
  );
};

export const getTotalPendingHope = async (rope, account) => {
  const giverOfHopeContract = getGiverOfHopeContract(rope);
  return web3.utils.fromWei(
    await giverOfHopeContract.methods.totalPendingHope(account).call()
  );
};

export const getTotalPendingHopeInPool = async (rope, poolId) => {
  const giverOfHopeContract = getGiverOfHopeContract(rope);
  return web3.utils.fromWei(
    await giverOfHopeContract.methods.pendingHopeOfPool(poolId).call()
  );
};

export const getTotalPendingHopeInAllPools = async (rope) => {
  const giverOfHopeContract = getGiverOfHopeContract(rope);
  const pool0 = web3.utils.fromWei(
    await giverOfHopeContract.methods.pendingHopeOfPool(0).call()
  );
  const pool1 = web3.utils.fromWei(
    await giverOfHopeContract.methods.pendingHopeOfPool(1).call()
  );
  return Number(pool0) + Number(pool1);
};

export const getTotalHopeEarned = async (rope) => {
  const hopeContract = getHopeNonTradeableContract(rope);
  return web3.utils.fromWei(await hopeContract.methods.totalSupply().call());
};

export const getTotalValueLocked = async (rope) => {
  // Doesnt exist on testnet
  if (!getZerionAdapterRegistryAddress(rope)) return 0;

  const zerionAdapter = getZerionAdapterRegistryContract(rope);

  const ethPrice = await getEthPrice();
  const ropePrice = await getRopePrice();
  const hopePrice = await getHopePrice(rope);

  let data = await zerionAdapter.methods
    .getAdapterBalance(
      getGiverOfHopeAddress(rope),
      '0x4EdBac5c8cb92878DD3fd165e43bBb8472f34c3f',
      [
        '0xfaab5238f5d2163e25518b0c1af205da0f783dd0',
        getRopeEthLpAddress(rope),
        getHopeEthLpAddress(rope),
      ]
    )
    .call();

  const ropeContract = getRopeContract(rope);
  const giverOfHopeAddress = getGiverOfHopeAddress(rope);

  const ropeBalance = await ropeContract.methods
    .balanceOf(giverOfHopeAddress)
    .call();

  let total = Number(web3.utils.fromWei(ropeBalance)) * ropePrice;

  for (const dataEl of data[1]) {
    const tokenAPrice =
      dataEl.underlying[0][0].name === '$ROPE' ? ropePrice : hopePrice;
    const tokenADecimals = Number(dataEl.underlying[0][0].decimals);
    const tokenASupply = new BigNumber(dataEl.underlying[0].amount)
      .dividedBy(10 ** tokenADecimals)
      .toNumber();

    const tokenBIsEth = dataEl.underlying[1][0].name === 'Wrapped Ether';
    const tokenBPrice =
      dataEl.underlying[1][0].name === '$ROPE' ? ropePrice : hopePrice;
    const tokenBDecimals = Number(dataEl.underlying[1][0].decimals);
    const tokenBSupply = new BigNumber(dataEl.underlying[1].amount)
      .dividedBy(10 ** tokenBDecimals)
      .toNumber();

    total += tokenBIsEth
      ? tokenAPrice * tokenASupply
      : tokenBPrice * tokenBSupply;

    total += tokenBIsEth ? ethPrice * tokenBSupply : ethPrice * tokenASupply;
  }

  return total;
};

export const getHopePrice = async (rope) => {
  const ethPrice = await getEthPrice();
  const hopeEthLp = getHopeEthLpContract(rope);

  const reserves = await hopeEthLp.methods.getReserves().call();

  const tokenASupply = reserves._reserve0 / 1e18;
  const tokenBSupply = reserves._reserve1 / 1e18;

  const hopePerEth = tokenASupply / tokenBSupply;

  return ethPrice / hopePerEth;
};

export const getRopeApy = async (rope) => {
  const ropePrice = await getRopePrice();
  const hopePrice = await getHopePrice(rope);

  const giverOfHopeContract = getGiverOfHopeContract(rope);

  const { hopesPerDay } = await giverOfHopeContract.methods.poolInfo(0).call();

  const hopePerYear = 365 * Number(hopesPerDay);
  const usdPerYear = hopePrice * hopePerYear;

  return String((usdPerYear / ropePrice) * 100);
};

export const getRopeEthLpPrice = async (rope) => {
  const ropeEthLpContract = getRopeEthLpContract(rope);
  const ethPrice = await getEthPrice();

  const reserves = await ropeEthLpContract.methods.getReserves().call();
  const totalSupply = await ropeEthLpContract.methods.totalSupply().call();

  const supplyTokenA = reserves._reserve1;
  const tokenAPerLp = supplyTokenA / totalSupply;

  return tokenAPerLp * ethPrice * 2;
};

export const getRopeEthLpApy = async (rope) => {
  const hopePrice = await getHopePrice(rope);
  const ropeEthLpPrice = await getRopeEthLpPrice(rope);

  const giverOfHopeContract = getGiverOfHopeContract(rope);

  const { hopesPerDay } = await giverOfHopeContract.methods.poolInfo(4).call();

  const hopePerYear = 365 * Number(hopesPerDay);
  const usdPerYear = hopePrice * hopePerYear;

  return String((usdPerYear / ropeEthLpPrice) * 100);
};

export const getHopeEthLpPrice = async (rope) => {
  const hopeEthLpContract = getHopeEthLpContract(rope);
  const ethPrice = await getEthPrice();

  const reserves = await hopeEthLpContract.methods.getReserves().call();
  const totalSupply = await hopeEthLpContract.methods.totalSupply().call();

  const supplyTokenA = reserves._reserve1;
  const tokenAPerLp = supplyTokenA / totalSupply;

  return tokenAPerLp * ethPrice * 2;
};

export const getHopeEthLpApy = async (rope) => {
  const hopePrice = await getHopePrice(rope);
  const hopeEthLpPrice = await getHopeEthLpPrice(rope);

  const giverOfHopeContract = getGiverOfHopeContract(rope);

  const { hopesPerDay } = await giverOfHopeContract.methods.poolInfo(5).call();

  const hopePerYear = 365 * Number(hopesPerDay);
  const usdPerYear = hopePrice * hopePerYear;

  return String((usdPerYear / hopeEthLpPrice) * 100);
};

export const getHopeBalance = async (rope, account) => {
  const hopeContract = getHopeNonTradeableContract(rope);
  return web3.utils.fromWei(
    await hopeContract.methods.balanceOf(account).call()
  );
};

export const getHopeV2Balance = async (rope, account) => {
  const hopeContract = getHopeContract(rope);
  return web3.utils.fromWei(
    await hopeContract.methods.balanceOf(account).call()
  );
};

export const getHopesPerDay = async (rope, poolId) => {
  return Number((await getSeasonalPoolInfo(rope, poolId)).hopesPerDay);
};

export const getHopeRequired = async (rope, card) => {
  const hopeVendingMachineContract = getHopeVendingMachineContract(rope);
  return web3.utils.fromWei(
    await hopeVendingMachineContract.methods.cardCosts(card).call()
  );
};

export const getHopeRequiredV2 = async (rope, card) => {
  const hopeVendingMachineV2Contract = getHopeVendingMachineV2Contract(rope);
  return web3.utils.fromWei(
    await hopeVendingMachineV2Contract.methods.cardCosts(card).call()
  );
};

export const getEthPurchaseCost = async (rope, card) => {
  const charityVendingMachineContract = getCharityVendingMachineContract(rope);
  return web3.utils.fromWei(
    await charityVendingMachineContract.methods.cardCosts(card).call()
  );
};

export const getTotalHopePerSecond = async (rope, account, poolIdList) => {
  let hopePerSecond = 0;

  for (const poolId of poolIdList) {
    const staked = await getRopeStakedNFT(rope, account, poolId);
    const hopesPerDay = await getHopesPerDay(rope, poolId);

    // Make the real time update being slower than normal, to avoid count going down when sync on new block happen
    hopePerSecond += (parseFloat(staked) / (24 * 3600)) * hopesPerDay * 0.8;
  }

  return hopePerSecond;
};

// Seasonal Pool Mutations

export const approveStakeSeasonalPool = async (rope, account, poolId) => {
  const poolInfo = await getSeasonalPoolInfo(rope, poolId);
  const tokenContract = new rope.web3.eth.Contract(ERC20Abi, poolInfo.token);

  return tokenContract.methods
    .approve(getGiverOfHopeAddress(rope), ethers.constants.MaxUint256)
    .send({ from: account });
};

export const stakeRopeSeasonalPool = async (rope, account, amount, poolId) => {
  const giverOfHopeContract = getGiverOfHopeContract(rope);

  return giverOfHopeContract.methods
    .deposit(poolId, amount)
    .send({ from: account });
};

export const withdrawRopeSeasonalPool = async (
  rope,
  account,
  amount,
  poolId
) => {
  const giverOfHopeContract = getGiverOfHopeContract(rope);

  if (poolId && Number(amount) === 0) {
    amount = 1;
  }

  return giverOfHopeContract.methods
    .withdraw(poolId, amount)
    .send({ from: account });
};

export const harvestAllSeasonalHope = async (rope, account, poolIdList) => {
  const giverOfHopeContract = getGiverOfHopeContract(rope);

  const toHarvest = [];

  for (const poolId in poolIdList) {
    const userInfo = await getNFTUserInfo(rope, account, poolId);
    const amount = Number(web3.utils.fromWei(userInfo.amount));

    if (amount > 0) {
      toHarvest.push(poolId);
    }
  }

  if (!toHarvest.length) return;

  return giverOfHopeContract.methods.rugPull(toHarvest).send({
    from: account,
  });
};

export const redeemHopeForCard = async (rope, account, card) => {
  const hopeVendingMachineContract = getHopeVendingMachineContract(rope);

  return hopeVendingMachineContract.methods.redeem(card).send({
    from: account,
  });
};

export const redeemHopeForCardV2 = async (
  rope,
  account,
  card,
  amount,
  useNonTradeableHope
) => {
  const hopeVendingMachineV2Contract = getHopeVendingMachineV2Contract(rope);

  return hopeVendingMachineV2Contract.methods
    .redeem(card, amount, useNonTradeableHope)
    .send({
      from: account,
    });
};

export const purchaseCardForEth = async (rope, account, card) => {
  const charityVendingMachineContract = getCharityVendingMachineContract(rope);

  return charityVendingMachineContract.methods.redeem(card).send({
    from: account,
    value: web3.utils.toWei('1', 'ether'),
  });
};

// NFT Staking Getters

export const getCardStakingApproval = async (rope, account) => {
  const ropeMakerContract = getRopeMakerContract(rope);
  const cardKeeperAddress = getCardKeeperAddress(rope);

  return ropeMakerContract.methods
    .isApprovedForAll(account, cardKeeperAddress)
    .call();
};

export const getTotalCardsStaked = async (rope, account) => {
  const cardKeeperContract = getCardKeeperContract(rope);

  const totalStaked = await cardKeeperContract.methods
    .getNbNftStakedOfAddress(account)
    .call();

  return totalStaked;
};

export const getTotalPendingHopeNFTStaking = async (rope, account) => {
  const cardKeeperContract = getCardKeeperContract(rope);

  const totalPendingHope = await cardKeeperContract.methods
    .totalPendingHopeOfAddress(account, true)
    .call();

  return web3.utils.fromWei(totalPendingHope);
};

export const getCardSetIdForCard = async (rope, cardId) => {
  const cardKeeperContract = getCardKeeperContract(rope);

  const cardSetId = await cardKeeperContract.methods
    .cardToSetMap(cardId)
    .call();

  return cardSetId;
};

export const getCardSetData = async (rope, account, cardSetId) => {
  const cardKeeperContract = getCardKeeperContract(rope);

  const cardSet = await cardKeeperContract.methods.cardSets(cardSetId).call();
  const totalStaked = await cardKeeperContract.methods
    .getNbSetNftStakedOfAddress(account, cardSetId)
    .call();

  return {
    id: cardSetId,
    totalStaked: totalStaked,
    hopePerDayPerCard: web3.utils.fromWei(cardSet.hopePerDayPerCard.toString()),
    bonusHopeMultiplier: String(cardSet.bonusHopeMultiplier / 1e5),
    isRemoved: cardSet.isRemoved,
  };
};

export const getCardData = async (rope, account, cardId) => {
  const card = cards.get(cardId);
  const cardKeeperContract = getCardKeeperContract(rope);

  const balance = await getNFTBalance(rope, account, cardId);
  const supply = await getNFTSupply(rope, cardId);
  const totalSupply = await getNFTMaxSupply(rope, cardId);

  // Hardcoded costs as it avoid quite a few requests, and S2 will not have price per card anymore
  const hopeRequired = cardsExtraData.get(cardId)?.hopePrice;
  const purchaseCost = cardsExtraData.get(cardId)?.ethPrice;
  // const hopeRequired = await getHopeRequired(rope, cardId);
  // const purchaseCost = await getEthPurchaseCost(rope, cardId);
  const isStaked = await cardKeeperContract.methods
    .userCards(account, cardId)
    .call();

  const rarity = card.attributes.find(
    ({ trait_type }) => trait_type === 'Rarity'
  ).value;

  return {
    id: cardId,
    rarity,
    isStaked,
    hopeRequired,
    purchaseCost,
    balance: balance.toNumber(),
    supply: supply.toNumber(),
    totalSupply: totalSupply.toNumber(),
  };
};

export const getCardsOwned = async (rope, account) => {
  const cardKeeperContract = getCardKeeperContract(rope);
  const owned = [];

  const cardsStaked = await cardKeeperContract.methods
    .getCardsStakedOfAddress(account)
    .call();

  for (const cardId of cards.keys()) {
    if (cardsStaked[cardId]) {
      owned.push(cardId);
      continue;
    }

    const balance = await getNFTBalance(rope, account, cardId);

    if (balance > 0) {
      owned.push(cardId);
    }
  }

  return owned;
};

// NFT Staking Mutations

export const approveNFTStaking = async (rope, account) => {
  const ropeMakerContract = getRopeMakerContract(rope);
  const cardKeeperAddress = getCardKeeperAddress(rope);

  return ropeMakerContract.methods
    .setApprovalForAll(cardKeeperAddress, true)
    .send({ from: account });
};

export const stakeNFTs = async (rope, account, cardIds) => {
  const cardKeeperContract = getCardKeeperContract(rope);

  return cardKeeperContract.methods
    .stake(cardIds.length ? cardIds : [cardIds])
    .send({ from: account });
};

export const unstakeNFTs = async (rope, account, cardIds) => {
  const cardKeeperContract = getCardKeeperContract(rope);

  return cardKeeperContract.methods
    .unstake(cardIds.length ? cardIds : [cardIds])
    .send({ from: account });
};

export const harvestNFTHope = async (rope, account) => {
  const cardKeeperContract = getCardKeeperContract(rope);

  return cardKeeperContract.methods.harvest().send({
    from: account,
  });
};

// Yield Farm Getters

// export const getUniLpApy = async (poolId, uniPrice) => {
//     const dailyReward = UNI_WEEKLY_REWARD / 7;
//     const estimateAmounts = [366493671, 566360360, 498921531, 699104546];
//     const uniAPY = (dailyReward * uniPrice / estimateAmounts[poolId]) * 36500;
//
//     return 0.9 * uniAPY;
// }

export const getYieldPoolInfo = async (rope, poolId) => {
  if (poolId === undefined) return;

  const ropeUniSpindleContract = getRopeSpindleContract(rope);
  return ropeUniSpindleContract.methods.poolInfo(poolId).call();
};

export const getYieldPoolUserInfo = async (rope, account, poolId) => {
  if (poolId === undefined) return;

  const ropeUniSpindleContract = getRopeSpindleContract(rope);
  return ropeUniSpindleContract.methods.userInfo(poolId, account).call();
};

export const getHopeBoosterUserInfo = async (rope, account) => {
  const hopeBoosterContract = getHopeBoosterContract(rope);
  return hopeBoosterContract.methods.userInfo(account).call();
};

export const getYieldPoolTokenBalance = async (rope, account, poolId) => {
  const poolInfo = await getYieldPoolInfo(rope, poolId);
  const tokenContract = new rope.web3.eth.Contract(ERC20Abi, poolInfo.token);

  return web3.utils.fromWei(
    await tokenContract.methods.balanceOf(account).call()
  );
};

export const getYieldPoolAllowance = async (rope, account, poolId) => {
  const poolInfo = await getYieldPoolInfo(rope, poolId);
  const tokenContract = new rope.web3.eth.Contract(ERC20Abi, poolInfo.token);

  return tokenContract.methods
    .allowance(account, getRopeSpindleAddress(rope))
    .call({ from: account });
};

export const getYieldPoolStaked = async (rope, account, poolId) => {
  const userInfo = await getYieldPoolUserInfo(rope, account, poolId);
  return web3.utils.fromWei(userInfo.amount);
};

export const getRopeEarned = async (rope, account) => {
  const userInfo = await getHopeBoosterUserInfo(rope, account);
  return web3.utils.fromWei(userInfo.ropeAmount);
};

export const getBonusHopeEarned = async (rope, account) => {
  const hopeBoosterContract = getHopeBoosterContract(rope);
  const pendingHope = await hopeBoosterContract.methods
    .pendingHope(account)
    .call();
  const userInfo = await getHopeBoosterUserInfo(rope, account);
  const total = new BigNumber(pendingHope).plus(userInfo.hopeAmount);
  return web3.utils.fromWei(total.toString());
};

export function getLpContractOfPool(rope, poolId) {
  switch (poolId) {
    case 0:
      return getDaiEthLpContract(rope);
    case 1:
      return getUsdcEthLpContract(rope);
    case 2:
      return getUsdtEthLpContract(rope);
    case 3:
      return getWbtcEthLpContract(rope);
    default:
      return;
  }
}

// export const getSpindleTotalValueLocked = async (rope) => {
//     // Doesnt exist on testnet
//     if (!getZerionAdapterRegistryAddress(rope)) return 0;
//
//     const zerionAdapter = getZerionAdapterRegistryContract(rope);
//
//     const ethPrice = await getEthPrice();
//     const btcPrice = await getBtcPrice();
//
//     const _getPrice = (symbol) => {
//         if (symbol === 'WETH') {
//             return ethPrice;
//         } else if (symbol === 'DAI' || symbol === 'USDC' || symbol === 'USDT') {
//             return 1;
//         } else if (symbol === 'WBTC') {
//             return btcPrice;
//         } else {
//             throw new Error('Symbol not handled : ' + symbol);
//         }
//     }
//
//     let total = 0;
//
//     for (const poolId of YIELD_POOL_ID_LIST) {
//         const poolInfo = await getYieldPoolInfo(rope, poolId)
//
//         const data = await zerionAdapter.methods.getAdapterBalance(
//             poolInfo.uniStaking,
//             '0x4EdBac5c8cb92878DD3fd165e43bBb8472f34c3f',
//             [poolInfo.token]).call();
//
//         const dataEl = data[1][0];
//
//         const tokenASymbol = dataEl.underlying[0][0].symbol;
//         const tokenADecimals = Number(dataEl.underlying[0][0].decimals);
//         const tokenASupply = new BigNumber(dataEl.underlying[0].amount).dividedBy(10**tokenADecimals).toNumber();
//
//         const tokenBSymbol = dataEl.underlying[1][0].symbol;
//         const tokenBDecimals = Number(dataEl.underlying[1][0].decimals);
//         const tokenBSupply = new BigNumber(dataEl.underlying[1].amount).dividedBy(10**tokenBDecimals).toNumber();
//
//         const lpSupply = Number(web3.utils.fromWei(dataEl.base.amount));
//
//         const amountInPool = Number(web3.utils.fromWei(poolInfo.supply));
//
//         const percentPool = amountInPool / lpSupply;
//         const tokenAPool = tokenASupply * percentPool;
//         const tokenBPool = tokenBSupply * percentPool;
//
//         let poolTotal = 0
//         poolTotal += _getPrice(tokenASymbol) * tokenAPool;
//         poolTotal += _getPrice(tokenBSymbol) * tokenBPool;
//
//         total += poolTotal;
//
//         // console.log(lpSupply);
//         // console.log(tokenASymbol, tokenADecimals, tokenASupply);
//         // console.log(tokenBSymbol, tokenBDecimals, tokenBSupply);
//         // console.log(percentPool, tokenAPool, tokenBPool);
//         // console.log(poolTotal);
//         // console.log('--')
//     }
//
//     // console.log(total);
//     return total;
// }

// export const getRopeYieldAvailableInPool = async (rope, account, poolId, ropePerUni) => {
//     if (!ropePerUni) {
//         const ropePrice = await getRopePrice();
//         const uniPrice = await getUniPrice();
//         ropePerUni = Number(uniPrice)/ Number(ropePrice);
//     }
//
//     const uniPerBlock = 13.5;
//
//     const poolInfo = await getYieldPoolInfo(rope, poolId);
//     const userInfo = await getYieldPoolUserInfo(rope, account, poolId);
//
//     const totalStakedUniPool = web3.utils.fromWei(await getLpContractOfPool(rope, poolId).methods.balanceOf(poolInfo.uniStaking).call());
//     // console.log(totalStakedUniPool.toString());
//
//     // Calculate pending reward not yet calculated by pool update
//     const lpInSpindlePool = web3.utils.fromWei(poolInfo.supply);
//     const spindleUniPoolShare = lpInSpindlePool / totalStakedUniPool;
//     // console.log(spindleUniPoolShare.toString())
//     const currentBlock = await rope.web3.eth.getBlockNumber();
//     // console.log('block', poolInfo.lastRewardBlock, currentBlock);
//     const pendingSpindleUniReward = uniPerBlock * (Number(currentBlock) - Number(poolInfo.lastRewardBlock)) * spindleUniPoolShare;
//     // console.log(pendingSpindleUniReward.toString())
//     const accUniPerSharePending = pendingSpindleUniReward / lpInSpindlePool;
//     // console.log(accUniPerSharePending);
//
//     const totalUniPerShare = poolInfo.accUniPerShare;
//     const uniTakenAlready = web3.utils.fromWei(userInfo.uniRewardDebt);
//     const amountStaked = web3.utils.fromWei(userInfo.amount);
//
//     if (amountStaked === '0') {
//         return '0';
//     }
//
//     const uniReward = (Number(amountStaked) * Number(accUniPerSharePending)) + (Number(totalUniPerShare) * Number(amountStaked) / 1e12) - Number(uniTakenAlready);
//
//     return String(ropePerUni * uniReward);
// }

// export const getRopeYieldAvailable = async (rope, account, poolIdList) => {
//     const ropePrice = await getRopePrice();
//     const uniPrice = await getUniPrice();
//     const ropePerUni = Number(uniPrice) / Number(ropePrice);
//
//     const yields = await Promise.all(poolIdList.map(async (poolId) => getRopeYieldAvailableInPool(rope, account, poolId, ropePerUni)))
//
//     const availableYield = String(yields.reduce((sum, _yield) => Number(sum) + Number(_yield), 0));
//
//     return availableYield;
// }

// export const getTotalRopeYieldEarned = async (rope) => {
//     const hopeBoosterContract = getHopeBoosterContract(rope);
//     const uniContract = getUniContract(rope);
//     const ropeUniSpindleAddress = getRopeSpindleAddress(rope);
//
//     const ropeEarned = await hopeBoosterContract.methods.totalRopesEarned().call();
//     const uniEarned = await uniContract.methods.balanceOf(ropeUniSpindleAddress).call();
//
//     const ropePrice = await getRopePrice();
//     const uniPrice = await getUniPrice();
//     const ropePerUni = Number(uniPrice) / Number(ropePrice);
//
//     return web3.utils.fromWei(String(Number(ropeEarned) + Number(uniEarned * ropePerUni)));
// }

export const getHopeBoosterMultiplier = async (rope, account) => {
  const userInfo = await getHopeBoosterUserInfo(rope, account);
  const hopeBoosterContract = getHopeBoosterContract(rope);

  return hopeBoosterContract.methods.getMultiplier(userInfo.ropeAmount).call();
};

// Yield Farm Mutations

export const approveStakeYieldPool = async (rope, account, poolId) => {
  const poolInfo = await getYieldPoolInfo(rope, poolId);
  const tokenContract = new rope.web3.eth.Contract(ERC20Abi, poolInfo.token);
  const ropeUniSpindleAddress = getRopeSpindleAddress(rope);

  return tokenContract.methods
    .approve(ropeUniSpindleAddress, ethers.constants.MaxUint256)
    .send({ from: account });
};

export const stakeRopeYieldPool = async (rope, account, amount, poolId) => {
  const ropeUniSpindleContract = getRopeSpindleContract(rope);

  return ropeUniSpindleContract.methods
    .deposit(poolId, amount)
    .send({ from: account });
};

export const withdrawRopeYieldPool = async (rope, account, amount, poolId) => {
  const ropeUniSpindleContract = getRopeSpindleContract(rope);

  return ropeUniSpindleContract.methods
    .withdraw(poolId, amount)
    .send({ from: account });
};

export const transferAllRopeYield = async (rope, account) => {
  const ropeUniSpindleContract = getRopeSpindleContract(rope);

  return ropeUniSpindleContract.methods
    .loadAllRopesIntoBooster()
    .send({ from: account });
};

export const harvestAllYieldedHope = async (rope, account) => {
  const hopeBoosterContract = getHopeBoosterContract(rope);

  return hopeBoosterContract.methods.harvest().send({
    from: account,
  });
};

export const harvestAllYieldedRope = async (rope, account) => {
  const hopeBoosterContract = getHopeBoosterContract(rope);

  return hopeBoosterContract.methods.withdraw().send({
    from: account,
  });
};

export const withdrawFromAllPools = async (rope, account, poolIdList) => {
  const ropeUniSpindleContract = getRopeSpindleContract(rope);

  return ropeUniSpindleContract.methods
    .massWithdraw(poolIdList)
    .send({ from: account });
};

// Jump Rope Getters

export const getJumpRopeTotalHope = async (rope) => {
  // const hopeContract = getHopeNonTradeableContract(rope);
  // const jumpRopeAddress = getJumpRopeAddress(rope);

  // const hopeBalance = await hopeContract.methods.balanceOf(jumpRopeAddress).call();

  // return web3.utils.fromWei(hopeBalance);

  return '225000';
};

export const getJumpRopeTotalRope = async (rope) => {
  const jumpRopeContract = getJumpRopeContract(rope);
  return web3.utils.fromWei(await jumpRopeContract.methods.ethTotal().call());
};

export const getJumpRopeOwnershipPercent = async (rope, account) => {
  const jumpRopeContract = getJumpRopeContract(rope);

  const amountDeposited = web3.utils.fromWei(
    await jumpRopeContract.methods
      .amountDeposited(account)
      .call({ from: account })
  );
  const ropeTotal = await getJumpRopeTotalRope(rope);

  return String(Number(amountDeposited) / (Number(ropeTotal) || 1));
};

export const getJumpRopeBlocksLeft = async (rope) => {
  const jumpRopeContract = getJumpRopeContract(rope);

  const endBlock = await jumpRopeContract.methods.endBlock().call();
  const currentBlock = await rope.web3.eth.getBlockNumber();

  return Number(endBlock) - Number(currentBlock);
};

export const getJumpRopeHasCollected = async (rope, account) => {
  const jumpRopeContract = getJumpRopeContract(rope);

  const hasCollected = await jumpRopeContract.methods
    .hasCollected(account)
    .call({ from: account });

  return hasCollected;
};

// Jump Rope Mutations

export const depositEthInJumpRope = async (rope, account, amount) => {
  const jumpRopeContract = getJumpRopeContract(rope);

  return jumpRopeContract.methods.deposit().send({
    from: account,
    value: web3.utils.toWei(amount, 'ether'),
  });
};

export const claimHopeInJumpRope = async (rope, account) => {
  const jumpRopeContract = getJumpRopeContract(rope);

  return jumpRopeContract.methods.collect().send({
    from: account,
  });
};

export const exchangeHopeForTradeable = async (rope, account, amount) => {
  const hopeContract = getHopeContract(rope);

  return hopeContract.methods.upgradeHopeNonTradable(amount).send({
    from: account,
  });
};

// Card Redeemer

export const getPackInfo = async (rope, packId) => {
  const cardRedeemerContract = getCardRedeemerContract(rope);
  return cardRedeemerContract.methods.packs(packId).call();
};

export const getPacksLeft = async (rope, packId) => {
  const cardRedeemerContract = getCardRedeemerContract(rope);
  return cardRedeemerContract.methods.getPacksLeft(packId).call();
};

export const getCardIdListOfPack = async (rope, packId) => {
  const cardRedeemerContract = getCardRedeemerContract(rope);
  return cardRedeemerContract.methods.getCardIdListOfPack(packId).call();
};

export const getPackProbabilities = async (rope, packId) => {
  const cardRedeemerContract = getCardRedeemerContract(rope);
  const r = await cardRedeemerContract.methods
    .getPackProbabilities(packId)
    .call();
  return r.map((el) => el / 1e5);
};

export const getCardsLeftInPack = async (rope, packId) => {
  const cardRedeemerContract = getCardRedeemerContract(rope);
  return cardRedeemerContract.methods.getCardsLeft(packId).call();
};

export const getTotalPacksRedeemed = async (rope) => {
  const cardRedeemerContract = getCardRedeemerContract(rope);
  return cardRedeemerContract.methods.getTotalRedeemed().call();
};

export const redeemPack = async (rope, account, packId) => {
  const cardRedeemerContract = getCardRedeemerContract(rope);

  return cardRedeemerContract.methods.redeem(packId).send({
    from: account,
  });
};

// Merkle Claimer

export const claimNFT = async (rope, account, cardId) => {
  const merkleClaimerContract = getMerkleClaimerContract(rope);
  const index = get(merkle[cardId], `claims.${account}.index`);
  const merkleProof = get(merkle[cardId], `claims.${account}.proof`);

  return merkleClaimerContract.methods
    .claim(cardId, index, account, 1, merkleProof)
    .send({ from: account });
};

// Raffle

export const getRaffleTicketsPurchased = async (rope, raffleId) => {
  const hopeRaffle = getHopeRaffleContract(rope);
  const raffle = await hopeRaffle.methods.raffles(raffleId).call();

  return raffle.ticketsPurchased;
};

export const getRaffleTicketsHeld = async (rope, account, raffleId) => {
  const hopeRaffle = getHopeRaffleContract(rope);

  return hopeRaffle.methods
    .getRaffleUserTicketBalance(raffleId, account)
    .call();
};

export const getCurrentRaffleNftId = async (rope, raffleId) => {
  const hopeRaffle = getHopeRaffleContract(rope);
  const raffle = await hopeRaffle.methods.raffles(raffleId).call();
  const raffleIds = await hopeRaffle.methods.getRaffleNftIds(raffleId).call();

  return raffleIds[raffle.currentNftIndex];
};

export const getPreviousRaffleWinner = async (rope, raffleId) => {
  const hopeRaffle = getHopeRaffleContract(rope);

  const settledRaffles = await hopeRaffle.getPastEvents('RaffleSettled', {
    filter: { id: String(raffleId) },
    fromBlock: 0,
  });

  return get(
    settledRaffles,
    `${settledRaffles.length - 1}.returnValues`,
    undefined
  );
};

export const purchaseRaffleTickets = async (
  rope,
  account,
  raffleId,
  amount,
  useNonTradeableHope = false
) => {
  const hopeRaffle = getHopeRaffleContract(rope);

  return hopeRaffle.methods
    .buyTickets(raffleId, amount, useNonTradeableHope)
    .send({
      from: account,
    });
};

export const settleHopeRaffle = async (rope, account, raffleId) => {
  const hopeRaffle = getHopeRaffleContract(rope);

  return hopeRaffle.methods.settleRaffle(raffleId).send({
    from: account,
  });
};
