import React, { useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';

import { Link } from 'react-router-dom';

interface ButtonProps {
  children?: React.ReactNode;
  rounded?: boolean;
  bordered?: boolean;
  disabled?: boolean;
  fullOpacity?: boolean;
  href?: string;
  icon?: React.ReactNode;
  onClick?: () => void;
  size?: 'sm' | 'md' | 'lg';
  text?: React.ReactNode;
  to?: string;
  className?: string;
  variant?:
    | 'default'
    | 'black'
    | 'secondary'
    | 'white'
    | 'blue'
    | 'pink'
    | 'purple';
}

const Button: React.FC<ButtonProps> = ({
  children,
  rounded,
  bordered,
  disabled,
  fullOpacity,
  href,
  icon,
  onClick,
  size,
  text,
  to,
  className,
  variant,
}) => {
  const { color, spacing } = useContext(ThemeContext);

  let buttonColor: string;
  let backgroundColor: string;
  let withBorder = bordered;

  switch (variant) {
    case 'pink':
      buttonColor = color.white;
      backgroundColor = `linear-gradient(60deg, ${color.pink[400]} 0%, ${color.pink[100]} 70%)`;
      break;
    case 'purple':
      buttonColor = color.white;
      backgroundColor = `linear-gradient(60deg, ${color.purple[400]} 0%, ${color.purple[100]} 70%)`;
      break;
    case 'blue':
      buttonColor = color.white;
      backgroundColor = `linear-gradient(60deg, ${color.blue[400]} 0%, ${color.blue[400]} 70%)`;
      break;
    case 'white':
    case 'secondary':
      buttonColor = color.primary.main;
      backgroundColor = color.white;
      withBorder = true;
      break;
    case 'black':
    case 'default':
    default:
      buttonColor = color.white;
      backgroundColor = color.black;
  }

  let buttonSize: number;
  let buttonPadding: number;
  let fontSize: number;

  switch (size) {
    case 'sm':
      buttonPadding = spacing[3];
      buttonSize = 36;
      fontSize = 14;
      break;
    case 'lg':
      buttonPadding = spacing[4];
      buttonSize = 72;
      fontSize = 16;
      break;
    case 'md':
    default:
      buttonPadding = spacing[4];
      buttonSize = 56;
      fontSize = 16;
  }

  const ButtonChild = useMemo(() => {
    if (to) {
      return <StyledLink to={to}>{text}</StyledLink>;
    } else if (href) {
      return (
        <StyledExternalLink href={href} target="__blank">
          {text}
        </StyledExternalLink>
      );
    } else {
      return text;
    }
  }, [href, text, to]);

  return (
    <StyledButton
      className={className}
      color={buttonColor}
      backgroundColor={backgroundColor}
      rounded={rounded}
      bordered={withBorder}
      disabled={disabled}
      fullOpacity={fullOpacity}
      fontSize={fontSize}
      onClick={onClick}
      padding={buttonPadding}
      size={buttonSize}
    >
      {children}
      {icon}
      {ButtonChild}
    </StyledButton>
  );
};

interface StyledButtonProps {
  color: string;
  backgroundColor: string;
  rounded?: boolean;
  bordered?: boolean;
  disabled?: boolean;
  fullOpacity?: boolean;
  fontSize: number;
  padding: number;
  size: number;
}

const StyledButton = styled.button<StyledButtonProps>`
  align-items: center;
  background: ${(props) => props.backgroundColor};
  border: ${({ bordered }) => (bordered ? '2px solid black' : '0')};
  border-radius: ${({ theme, rounded }) =>
    rounded ? '10px' : theme.borderRadius};
  color: ${(props) => props.color};
  cursor: pointer;
  display: flex;
  font-size: ${(props) => props.fontSize}px;
  font-weight: 400;
  text-align: center;
  height: ${(props) => props.size}px;
  justify-content: center;
  outline: none;
  opacity: ${({ disabled, fullOpacity }) =>
    disabled && !fullOpacity ? 0.6 : 1};
  padding-left: ${(props) => props.padding}px;
  padding-right: ${(props) => props.padding}px;
  pointer-events: ${(props) => (!props.disabled ? undefined : 'none')};
  width: 100%;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledLink = styled(Link)`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  height: 56px;
  justify-content: center;
  margin: 0 ${(props) => -props.theme.spacing[4]}px;
  padding: 0 ${(props) => props.theme.spacing[4]}px;
  text-decoration: none;
`;

const StyledExternalLink = styled.a`
  align-items: center;
  color: inherit;
  display: flex;
  flex: 1;
  height: 56px;
  justify-content: center;
  margin: 0 ${(props) => -props.theme.spacing[4]}px;
  padding: 0 ${(props) => props.theme.spacing[4]}px;
  text-decoration: none;
`;

export default Button;
