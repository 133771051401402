import React from 'react';
import styled from 'styled-components';

import Button from 'components/Button';
import CardContent from 'components/CardContent';
import Modal from 'components/Modal';
import ModalActions from 'components/ModalActions';
import ModalContent from 'components/ModalContent';
import ExpandableImage from 'components/ExpandableImage';

const ModalHeader = styled.div`
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PinkSpan = styled.div`
  color: ${({ theme }) => theme.color.pink[400]};
`;

interface WhatsInsideModalProps {
  packName: string;
  probabilities: any;
  remainingSupply: any;
  cardsInPack: any;
  onDismiss?: () => {};
}

const WhatsInsideModal: React.FC<WhatsInsideModalProps> = ({
  packName,
  probabilities,
  remainingSupply,
  cardsInPack,
  onDismiss,
}) => {
  return (
    <Modal style={{ maxHeight: '90vh' }}>
      <ModalHeader className="text-xl">
        <span className="font-thin">What's inside</span>{' '}
        <span className="font-bold ml-2">{packName}</span>
      </ModalHeader>

      <ModalContent>
        <div className="flex flex-row flex-wrap w-full -mb-8">
          {cardsInPack.map((cardId: number, i: number) => (
            <div key={cardId} className="w-1/2 sm:w-1/4 px-2 pb-4">
              <ExpandableImage
                className="w-full"
                src={`/images/cards/Varess_Generation_Two/${cardId}.gif`}
                alt="card-preview"
              />

              <CardContent>
                <div className="flex flex-row w-full justify-center items-center text-xs mb-2">
                  <PinkSpan className="font-semibold whitespace-no-wrap">
                    {Number(probabilities[i] * 100).toFixed(3)} %
                  </PinkSpan>{' '}
                  <span className="font-thin ml-1 whitespace-no-wrap">
                    Chance
                  </span>
                </div>

                <div className="flex flex-row w-full justify-center items-center text-xs">
                  <PinkSpan className="font-semibold whitespace-no-wrap">
                    {remainingSupply[i]}
                  </PinkSpan>{' '}
                  <span className="font-thin ml-1 whitespace-no-wrap">
                    Remaining
                  </span>
                </div>
              </CardContent>
            </div>
          ))}
        </div>
      </ModalContent>

      <ModalActions>
        <Button text="Cancel" variant="secondary" onClick={onDismiss} />
      </ModalActions>
    </Modal>
  );
};

export default WhatsInsideModal;
