import Web3 from 'web3';
import BigNumber from 'bignumber.js';
import { Contracts } from './lib/contracts.js';
import { Account } from './lib/accounts.js';
import { EVM } from './lib/evm.js';

import { contractAddresses } from './lib/constants';

export class Rope {
  constructor(provider, networkId, testing, options) {
    let realProvider;

    if (typeof provider === 'string') {
      if (provider.includes('wss')) {
        realProvider = new Web3.providers.WebsocketProvider(
          provider,
          options.ethereumNodeTimeout || 10000
        );
      } else {
        realProvider = new Web3.providers.HttpProvider(
          provider,
          options.ethereumNodeTimeout || 10000
        );
      }
    } else {
      realProvider = provider;
    }

    this.web3 = new Web3(realProvider);

    if (testing) {
      this.testing = new EVM(realProvider);
      this.snapshot = this.testing.snapshot();
    }

    if (options.defaultAccount) {
      this.web3.eth.defaultAccount = options.defaultAccount;
    }

    this.contracts = new Contracts(realProvider, networkId, this.web3, options);

    this.ropeAddress = contractAddresses.rope[networkId];
    this.uniAddress = contractAddresses.uni[networkId];
    this.ropeEthLpAddress = contractAddresses.ropeEthLp[networkId];
    this.hopeEthLpAddress = contractAddresses.hopeEthLp[networkId];
    this.daiEthLpAddress = contractAddresses.daiEthLp[networkId];
    this.usdtEthLpAddress = contractAddresses.usdtEthLp[networkId];
    this.usdcEthLpAddress = contractAddresses.usdcEthLp[networkId];
    this.wbtcEthLpAddress = contractAddresses.wbtcEthLp[networkId];
    this.ropeMakerAddress = contractAddresses.ropeMaker[networkId];
    this.hope = contractAddresses.hope[networkId];
    this.hopeNonTradeableAddress =
      contractAddresses.hopeNonTradeable[networkId];
    this.charityVendingMachineAddress =
      contractAddresses.charityVendingMachine[networkId];
    this.hopeVendingMachineAddress =
      contractAddresses.hopeVendingMachine[networkId];
    this.hopeVendingMachineV2Address =
      contractAddresses.hopeVendingMachineV2[networkId];
    this.giverOfHopeAddress = contractAddresses.giverOfHope[networkId];
    this.ropeUniSpindleAddress = contractAddresses.ropeUniSpindle[networkId];
    this.hopeBoosterAddress = contractAddresses.hopeBooster[networkId];
    this.jumpRopeAddress = contractAddresses.jumpRope[networkId];
    this.cardKeeperAddress = contractAddresses.cardKeeper[networkId];
    this.cardRedeemerAddress = contractAddresses.cardRedeemer[networkId];
    this.zerionAdapterRegistryAddress =
      contractAddresses.zerionAdapterRegistry[networkId];
    this.merkleClaimerAddress = contractAddresses.merkleClaimer[networkId];
    this.hopeRaffleAddress = contractAddresses.hopeRaffle[networkId];
    this.cards = {};
  }

  async resetEVM() {
    this.testing.resetEVM(this.snapshot);
  }

  addAccount(address, number) {
    this.accounts.push(new Account(this.contracts, address, number));
  }

  setProvider(provider, networkId) {
    this.web3.setProvider(provider);
    this.contracts.setProvider(provider, networkId);
    this.operation.setNetworkId(networkId);
  }

  setDefaultAccount(account) {
    this.web3.eth.defaultAccount = account;
    this.contracts.setDefaultAccount(account);
  }

  getDefaultAccount() {
    return this.web3.eth.defaultAccount;
  }

  loadAccount(account) {
    const newAccount = this.web3.eth.accounts.wallet.add(account.privateKey);

    if (
      !newAccount ||
      (account.address &&
        account.address.toLowerCase() !== newAccount.address.toLowerCase())
    ) {
      throw new Error(`Loaded account address mismatch.
        Expected ${account.address}, got ${
        newAccount ? newAccount.address : null
      }`);
    }
  }

  toBigN(a) {
    return BigNumber(a);
  }
}
