import React from 'react';
import styled from 'styled-components';

interface CardProps {
  children: any;
  className?: string;
  style?: object;
  transparent?: boolean;
  flatVariant?: boolean;
  onClick?: () => void;
}

const Card: React.FC<CardProps> = ({
  children,
  className,
  style,
  transparent,
  flatVariant,
  onClick,
}) => {
  return (
    <StyledCard
      style={style}
      className={className}
      flatVariant={flatVariant}
      transparent={transparent}
      onClick={onClick}
    >
      {children}
    </StyledCard>
  );
};

const StyledCard = styled.div<CardProps>`
  background: ${({ theme, transparent }) =>
    transparent
      ? 'transparent;'
      : `linear-gradient(
    ${theme.color.offWhite},
    ${theme.color.white}
  );`}
  border-radius: ${({ theme, flatVariant }) =>
    flatVariant ? '10px' : theme.borderRadius};
  ${({ transparent }) =>
    transparent
      ? ''
      : `box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
                      0 6.7px 5.3px rgba(0, 0, 0, 0.048),
                      0 12.5px 10px rgba(0, 0, 0, 0.06),
                      0 22.3px 17.9px rgba(0, 0, 0, 0.072),
                      0 41.8px 33.4px rgba(0, 0, 0, 0.086),
                      0 100px 80px rgba(0, 0, 0, 0.12);`}
  display: flex;
  flex: 1;
  flex-direction: column;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')}
`;

export default Card;
