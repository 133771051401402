import React from 'react';
import styled from 'styled-components';

import Button from 'components/Button';
import CardContent from 'components/CardContent';
import Spacer from 'components/Spacer';
import Modal from 'components/Modal';
import ModalActions from 'components/ModalActions';
import ModalContent from 'components/ModalContent';
import NftPackHeartBeat from './NftPackHeartBeat';
import NftCardReveal from './NftCardReveal';

const ModalHeader = styled.div`
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PinkSpan = styled.div`
  color: ${({ theme }) => theme.color.pink[400]};
`;

interface RevealModalProps {
  cardId: number;
  packName: string;
  packImage: string;
  probability: number;
  remainingSupply: number;
  onDismiss?: () => {};
}

const RevealModal: React.FC<RevealModalProps> = ({
  cardId,
  packName,
  packImage,
  probability,
  remainingSupply,
  onDismiss,
}) => {
  const isRevealed = cardId !== -1;

  return (
    <Modal>
      <ModalHeader className="text-xl">
        <span className="font-thin">
          {isRevealed ? 'Redeemed' : 'Redeeming'}
        </span>
        <span className="font-bold ml-2">{packName}</span>
      </ModalHeader>

      <ModalContent>
        {!isRevealed ? (
          <NftPackHeartBeat>
            <img src={packImage} alt="card pack" />
          </NftPackHeartBeat>
        ) : (
          <NftCardReveal preview={<img src={packImage} alt="card pack" />}>
            <div className="flex flex-col justify-center items-center w-full">
              <img
                className="w-1/2"
                src={`/images/cards/Varess_Generation_Two/${cardId}.gif`}
                alt="card-preview"
              />

              <CardContent>
                <div className="flex flex-row w-full justify-center items-center text-base mb-2">
                  <PinkSpan className="font-semibold whitespace-no-wrap">
                    {Number(probability * 100).toFixed(3)} %
                  </PinkSpan>{' '}
                  <span className="font-thin ml-1 whitespace-no-wrap">
                    Chance
                  </span>
                </div>

                <div className="flex flex-row w-full justify-center items-center text-base">
                  <PinkSpan className="font-semibold whitespace-no-wrap">
                    {remainingSupply}
                  </PinkSpan>{' '}
                  <span className="font-thin ml-1 whitespace-no-wrap">
                    Remaining
                  </span>
                </div>
              </CardContent>
            </div>
          </NftCardReveal>
        )}
      </ModalContent>

      <Spacer />

      {!isRevealed ? (
        <ModalActions>
          <Button
            text="Cancel"
            variant="secondary"
            className="z-10"
            onClick={onDismiss}
          />
        </ModalActions>
      ) : (
        <ModalActions>
          <Button
            to="/deck"
            text="View in Deck"
            className="z-10"
            onClick={onDismiss}
          />
          <Button
            text="Cancel"
            variant="secondary"
            className="z-10"
            onClick={onDismiss}
          />
        </ModalActions>
      )}
    </Modal>
  );
};

export default RevealModal;
