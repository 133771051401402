import React from 'react';
import { Link } from 'react-router-dom';

import useModal from 'hooks/useModal';
import useRaffle from 'hooks/useRaffle';

import Button from 'components/Button';
import PurchaseTicketModal from './PurchaseTicketModal';

const RaffleHeader: React.FC<any> = ({ raffleId }) => {
  const { ticketsHeld } = useRaffle({ raffleId });
  const [showPurchaseTicketModal] = useModal(
    <PurchaseTicketModal raffleId={raffleId} />
  );

  return (
    <div className="flex flex-col items-end w-2/3">
      <div className="flex flex-col w-1/2 xl:flex-row">
        <Button
          fullOpacity
          icon={<i className="fas fa-ticket-alt mr-2" />}
          text={
            <span>
              <b>{ticketsHeld} </b> Tickets Held
            </span>
          }
          size="sm"
          variant="pink"
          onClick={showPurchaseTicketModal}
        />
      </div>

      <div className="flex flex-row w-full justify-end items-center mt-4">
        <Link
          to="/varess/fear-of-the-unknown"
          className="text-sm font-semibold"
        >
          <i className="fas fa-arrow-left mr-2" />{' '}
          <span className="underline">back to set</span>
        </Link>
      </div>
    </div>
  );
};

export default RaffleHeader;
