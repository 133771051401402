import React from 'react';

import useModal from 'hooks/useModal';

import ExpandedImageModal from './components/ExpandedImageModal';

interface ExpandableImageProps {
  src: string;
  alt: string;
  className?: string;
  style?: object;
}

const ExpandableImage: React.FC<ExpandableImageProps> = ({
  src,
  alt,
  className,
  style,
}) => {
  const [expandCard] = useModal(
    <ExpandedImageModal
      src={src}
      alt={alt}
      className={className}
      style={style}
    />
  );

  return (
    <img
      src={src}
      alt={alt}
      className={`cursor-pointer ${className || ''}`}
      style={style}
      onClick={expandCard}
    />
  );
};

export default ExpandableImage;
