import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Header from 'components/Header';
import Footer from 'components/Footer';
import BackgroundCover from 'components/BackgroundCover';
import Button from 'components/Button';
import Card from 'components/Card';
import PoolCard from 'components/PoolCard';
import CardContent from 'components/CardContent';
import ApyStatsCard from 'components/ApyStatsCard';
import VaressStatsHeader from 'components/VaressStatsHeader';
import NavigationPills from 'components/NavigationPills';
import StakePoolHeader from 'components/StakePoolHeader';

const PinkSpan = styled.span`
  color: ${({ theme }) => theme.color.pink[400]};
`;

const PurpleImage = styled.img`
  filter: invert(15%) sepia(77%) saturate(4154%) hue-rotate(271deg)
    brightness(71%) contrast(112%);
`;

const VaressSeasonalPools: React.FC = () => {
  const history = useHistory();

  return (
    <div className="flex flex-col w-screen min-h-screen items-center">
      <BackgroundCover />

      <Header />

      <NavigationPills />

      <VaressStatsHeader />

      <ApyStatsCard />

      <div className="container mx-auto w-full px-4 sm:px-8 md:px-16 lg:px-32 mb-16">
        <Card flatVariant className="pt-2 px-4 sm:px-8">
          <CardContent>
            <div className="flex flex-col md:flex-row justify-between">
              <div className="flex flex-col w-full xl:w-1/3">
                <div className="text-sm cursive mb-4">
                  Varess Pools - Seasonal HOPE Farm
                </div>

                <div className="text-lg font-semibold mb-4 blackspace-no-wrap">
                  Select a Set
                </div>
              </div>

              <StakePoolHeader backLink="/" backText="change farm" />
            </div>

            <div className="flex flex-col md:flex-row flex-wrap justify-between w-full pt-4">
              <PoolCard
                variant="pink"
                onClick={() => history.push('/varess/fear-of-the-unknown')}
              >
                <img
                  width="180"
                  height="auto"
                  src="/images/cute-cthulhu.png"
                  alt="cute cthulhu"
                  className="mb-2"
                />

                <div className="text-xl font-semibold pb-6">
                  Fear of The Unknown
                </div>

                <div className="w-1/5 border border-l-0 border-r-0 border-t-0 border-b-1 border-black"></div>

                <div className="cursive text-black py-8 px-4 text-center">
                  Stake your tokens to <PinkSpan>Farm HOPE</PinkSpan>
                  <br />
                  and
                  <br />
                  <PinkSpan>Purchase NFTs</PinkSpan> with your HOPE.
                </div>

                <Button
                  to="/varess/fear-of-the-unknown"
                  text="Don't select this set."
                  variant="pink"
                />
              </PoolCard>

              <PoolCard
                variant="purple"
                onClick={() => history.push('/charity')}
              >
                <PurpleImage
                  width="120"
                  height="auto"
                  src="/images/charity.svg"
                  alt="charity"
                  className="mt-2 mb-2"
                />

                <div className="text-xl font-semibold pb-6">Charity NFTs</div>

                <div className="w-1/5 border border-l-0 border-r-0 border-t-0 border-b-1 border-black"></div>

                <div className="cursive text-black py-8 px-4 text-center">
                  Stake your charity NFTs to <PinkSpan>Farm HOPE</PinkSpan>
                </div>

                <Button
                  to="/charity"
                  text="Don't stake NFTs for charity."
                  variant="purple"
                />
              </PoolCard>
            </div>

            <div className="flex flex-col w-full xl:w-1/4">
              <div className="text-lg font-semibold mb-4 blackspace-no-wrap">
                Select a Past Season
              </div>
            </div>

            <div className="flex flex-col md:flex-row flex-wrap justify-between w-full pt-4">
              <PoolCard
                variant="black"
                onClick={() => history.push('/varess/powered-by-anons')}
              >
                <img
                  width="80"
                  height="auto"
                  src="/images/PoweredByAnonsLogo.png"
                  alt="rope_asset"
                  className="mb-2"
                />

                <div className="text-xl font-semibold pb-6">
                  Season 1 - Powered By Anons
                </div>

                <div className="w-1/5 border border-l-0 border-r-0 border-t-0 border-b-1 border-black"></div>

                <div className="cursive text-black py-8 px-4 text-center">
                  Stake your tokens to <PinkSpan>Farm HOPE</PinkSpan>
                  <br />
                  and
                  <br />
                  <PinkSpan>Purchase NFTs</PinkSpan> with your HOPE.
                </div>

                <Button
                  variant="black"
                  to="/varess/powered-by-anons"
                  text="Don't select this season."
                />
              </PoolCard>

              <PoolCard
                variant="pink"
                onClick={() => history.push('/varess/noombase-the-game')}
              >
                <img
                  width="180"
                  height="auto"
                  src="/images/noombase.png"
                  alt="noombase"
                  className="mb-2"
                />

                <div className="text-xl font-semibold pb-6">
                  Season 2.9.9 - Noombase The Game
                </div>

                <div className="w-1/5 border border-l-0 border-r-0 border-t-0 border-b-1 border-black"></div>

                <div className="cursive text-black py-8 px-4 text-center">
                  Stake your tokens to <PinkSpan>Farm HOPE</PinkSpan>
                  <br />
                  and
                  <br />
                  <PinkSpan>Purchase NFTs</PinkSpan> with your HOPE.
                </div>

                <Button
                  to="/varess/noombase-the-game"
                  text="Don't select this season."
                  variant="pink"
                />
              </PoolCard>
            </div>
          </CardContent>

          <Footer />
        </Card>
      </div>
    </div>
  );
};

export default VaressSeasonalPools;
